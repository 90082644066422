import React, { useState } from 'react';
import { Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function WebinarMobile() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    const handleButtonClick = () => {
      window.open("https://walu-media.s3.eu-central-1.amazonaws.com/public/WaluPra%CC%88sentation.pdf", '_blank');
    };

    const [showEmailError, setShowEmailError] = useState(false);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const validateEmail = (email) => {
        const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
        return isValid;
      };
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(validateEmail(emailValue));
    };
    const [emailFocused, setEmailFocused] = useState(false);
    const handleEmailFocus = () => {
        setEmailFocused(true);
      };
    const handleEmailBlur = () => {
        setEmailFocused(false);
    };

    // Create an array of datetime values (replace with your own dates)
    const dateTimeArray = [
        new Date('2023-11-08T14:00:00'),
        new Date('2023-11-09T17:00:00'),
        new Date('2023-11-12T10:00:00'),

        new Date('2023-11-15T14:00:00'),
        new Date('2023-11-16T17:00:00'), 
        new Date('2023-11-19T10:00:00'),

        new Date('2023-11-22T14:00:00'),
        new Date('2023-11-23T17:00:00'), 
        new Date('2023-11-26T10:00:00'),

        new Date('2023-11-29T14:00:00'),
        new Date('2023-11-30T17:00:00'), 
        new Date('2023-12-03T10:00:00'),

        new Date('2023-12-06T14:00:00'),
        new Date('2023-12-07T17:00:00'), 
        new Date('2023-12-10T10:00:00'),

        new Date('2023-12-13T14:00:00'),
        new Date('2023-12-14T17:00:00'), 
        new Date('2023-12-17T10:00:00'),

        new Date('2023-12-20T14:00:00'),
        new Date('2023-12-21T17:00:00'), 

        new Date('2023-12-27T14:00:00'),
        new Date('2023-12-28T17:00:00'), 
      ];
    
      // Filter out past dates
      const currentDate = new Date();
      const futureDates = dateTimeArray.filter((date) => date > currentDate);
    
      // Sort the array by date in ascending order
      futureDates.sort((a, b) => a - b);
    
      // Get the 3 nearest future dates
      const nearestDates = futureDates.slice(0, 3);
    
      // State to track the selected date
      const [selectedDate, setSelectedDate] = useState(null);

      const formatDateTime = (date) => {
        const dateCopy = new Date(date);

        const wochentag = dateCopy.toLocaleString('de-DE', { weekday: 'long' });
        const datum = dateCopy.toLocaleString('de-DE', { day: '2-digit', month: '2-digit' });
        const startTime = dateCopy.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
        
        dateCopy.setHours(dateCopy.getHours() + 1); // Add one hour for the end time
        
        const endTime = dateCopy.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
        
        return `Am ${wochentag}, den ${datum}, von ${startTime} bis ${endTime} Uhr`;
      };
    
      const handleDateClick = (date) => {
        if (!isAngemeldet) {
            setSelectedDate(date);
        }
      };

      const [serverError, setServerError] = useState("");
      const canAnmelden = selectedDate !== null && email.length > 0
      const [isAngemeldet, setIsAngemeldet] = useState(false);
      const [disableEmailField, setDisableEmailField] = useState(false);
      const handleAnmelden = async () => {
        if (!isEmailValid) {
          setShowEmailError(!isEmailValid)
        } else if (canAnmelden) {
          setShowEmailError(false);
          try {
              const response = await fetch("https://aws.mywalu.com/kuusco/api/websitemembers/webinar", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: email,
                  termin: selectedDate,
                }),
              });
        
              if (response.ok) {
                  setDisableEmailField(true);
                  setIsAngemeldet(true);
              } else {
                  setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
              }
          } catch (error) {
              setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
              console.error('An error occurred', error);
          }      
      }
      };

    return (
        <div 
        className="flex flex-col items-center justify-center">
            <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            <div className="text-center pt-2 pl-4 pr-4">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Die "Bring dein Kurs auf Erfolgskurs"<br />
                    Online-Präsentation 🚀</span>
                    </div>
                </span>
            </div>
            <div className="text-center pl-4 pr-4">
                <span className="text-sm lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">
                    Melde dich für die <b>30-minütige Online-Präsentation</b> an und finde heraus, wie du <b>deinen Kurs auf Erfolgskurs</b> bringst.
                    </span>
                    </div>
                </span>
            </div>

            <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
            Was ist die Online-Präsentation?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>


            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>   

    <div className="text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">1. E-Mail Adresse eingeben</div>
                    <div className="relative pl-4 pr-4">
                        <input
                            className={`border-2 border-gray-300 rounded-lg w-full pl-4 pr-4 text-base h-16 focus:outline-none transition duration-300 
                            ${showEmailError ? 'border-red-500' : emailFocused ? 'focus:border-blue-500 border-blue-500' : 'focus:border-blue-500'}`}
                            placeholder=" "
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={handleEmailFocus}
                            onBlur={handleEmailBlur}
                            maxLength={200}
                            disabled={disableEmailField}
                        />
                        <label
                            htmlFor="email"
                            className={`absolute left-6 top-[-10px] bg-white px-2 pb-1 text-${
                            showEmailError ? 'red-500' : emailFocused ? 'blue-500' : 'gray-500'
                            } text-sm transition-all duration-300`}
                        >
                            E-Mail
                        </label>
                        <div
                            className="pt-2 pl-2 gray-500 text-sm"
                        >
                            Wir verwenden deine E-Mail nur, um dir eine Einladung zu der Online-Präsentation zu schicken.
                        </div>
                    </div>
                    <div className="pb-4 font-semibold pl-6 pb-4 pt-8 text-lg">2. Termin auswählen</div>
                    <div className="pt-2 pl-6 pb-8">
                  {nearestDates.map((date, index) => (
        <div key={index}>
          <label className="inline-flex items-center" onClick={() => handleDateClick(date)}>
            <input
              type="radio"
              className="form-radio h-4 w-4 text-blue-600"
              name="date"
              value={date}
              checked={selectedDate && selectedDate.getTime() === date.getTime()}
              readOnly
            />
            <span className="ml-4 text-sm pr-4">
            {formatDateTime(date)}
            </span>
          </label>
        </div>
      ))}
      <div>     
                        </div>
                    </div>
                </div>

                {serverError !== "" ? (
                <div className="text-sm leading-9 font-roboto px-8 text-red-600" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {serverError}
                </div>
                ) : (<div></div>)}

                {showEmailError ? (<div className="text-sm leading-9 font-roboto px-8 text-red-600" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    Die E-Mail ist nicht korrekt.
                    </div>) : (<div></div>)}

                <div className="inline-block py-8 px-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {isAngemeldet ? (
                <div
                    className={`text-base inline-block leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-green-500`}
                    >
                    Du wurdest angemeldet
                </div>
            ) : (
                <div
                    className={`text-base inline-block leading-9 font-roboto px-4 py-1 rounded-xl ${canAnmelden ? 'bg-blue-500 hover:font-semibold' : 'bg-gray-300'} text-white`}
                    onClick={handleAnmelden}
                    style={{ cursor: 'pointer' }}
                    >
                    Jetzt kostenlos anmelden
                </div>
            )}
                </div>


                <div className="pt-4">


<div onClick={handleButtonClick} className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-start', cursor: 'pointer' }}>
    <div
    className={`text-sm leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
    > <FontAwesomeIcon icon={faDownload} className="text-sm pr-2" /> Präsentation herunterladen
    </div>
</div>

    </div>

            </div>
      </p>
    </div>

  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Für wen ist die Online-Präsentation gedacht?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Die <b>Online-Präsentation</b> ist nur für:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Personen, die bereits <b>Kurse anbieten</b> oder gerne in Zukunft <b>anbieten möchten</b>. 🌐</li><br/>
<li>Kursanbieter, die <b>mehr Kursbesucher</b> haben wollen, ohne einen Cent für Werbung auszugeben. 💰</li><br/>
<li>Kursanbieterinnen, die ihre <b>Kurse digitalisieren</b> wollen. 💻</li><br/>
<li>Kursanbieter, die ihren bisherigen Kursmitgliedern <b>etwas Gutes tun</b> wollen. 💖</li><br/>
</ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Was wirst du in der Online-Präsentation lernen?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg"><b>Inhalte</b> der Online-Präsentation sind:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Welche vier Prinzipien helfen mir, <b>mehr Leute mit meinen Kursen</b> zu <b>erreichen</b>? 🌐</li><br/>
<li>Wie kann ich <b>mehr Geld für meinen Kurs verlangen</b>, ohne, dass die Anzahl an Kursbesuchern abnimmt? 💰</li><br/>
<li>Wie bringe ich die jetzige <b>Qualität</b> meines Kurses noch <b>besser zum Vorschein</b>? 🌟</li><br/>
<li>Was ist die <b>Walu App</b> und wie kann diese mir helfen, meine <b>Ziele zu erreichen</b>? 🚀</li><br/>
</ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>


<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 



<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Wie werde ich schon jetzt Walu Kursanbieter?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <ul class="list-disc pl-8 text-base px-2">
                    <li>🌐 Gehe zum "<Link to="/signup"><span className="text-blue-600">Anbieter werden Formular</span></Link>".</li><br/>
                    <li>📝 Fülle die "<b>Allgemeinen Informationen</b>" zu dir als Anbieter aus.</li><br/>
                    <li>💼 Fülle aus, wie du <b>Walu später nutzen</b> wirst. (Paketkosten fallen erst ab dem 01.04.24 an)</li><br/>
                    <li>✅ Bestätige, dass du <b>Walu-Anbieter</b> werden möchtest. (Es fallen einmalig 15€ Anmeldegebühr an)</li><br/>
                    </ul>
                    <div className="pb-4 font-semibold pl-6 pr-6 pb-8 text-lg"><b>Noch unsicher?</b> Finde in der Online-Präsentation heraus, ob Walu das richtige für dich und deinen Kurs ist.</div>
                    <Link to="/signup">
        <div className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            >Jetzt Anbieter werden
            </div>
        </div>
    </Link>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

      </div>
    );
}

export default WebinarMobile;