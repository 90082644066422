function SilberPaket() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was ist das Walu Silber Paket?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/silber.png"
                    alt="Pakete"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Für <b>9,99€ monatlich</b> (<span className="underline">jederzeit</span> kündbar) kannst du:<br/><br/>

                    <ul class="list-decimal pl-8">
                    <li>Persönliches <b>Anbieter Profil</b> erstellen.📝</li><br/>
                    <li><b>Einen</b> Kurs online schalten.🚀</li><br/>
                    <li>Dein Kurs mit <b>Walu Chatgruppe</b>.💬</li><br/>
                    <li>Deinen Kurs in ganz <b>Deutschland</b>.🌐</li><br/>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SilberPaket;