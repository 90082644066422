function WasPlusPaket() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was ist ein Walu Plus Paket?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Das Walu Plus Paket hat <b>noch mehr Vorteile</b>:<br/><br/>

                        <ul class="list-disc pl-8">
                        <li>Dein Paket bekommst du zum <b>halben Preis</b>, <span className="underline">für immer</span>. 💲</li><br/>
                        <li>Du erhältst <b>exklusiven Zugang</b> zu neuen Tools. 🔧</li><br/>
                        <li>Du erhältst den Full Service, eine <b>priorisierte Betreuung</b>. 🚀</li><br/>
                        <li>Kostenpflichtige Tools wirst du zukünftig immer mit einem <b>Preisnachlass</b> erhalten. 💰</li><br/>
                        </ul>    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WasPlusPaket;