import { getMobileOperatingSystem } from "./HomeMobile";

function ShareMobile() {
    const phoneType = getMobileOperatingSystem();

    return (
        <div 
        className="flex flex-col items-center justify-center h-screen"
        style={{
            marginTop: '-15vh', // Move the content up
        }}>
            <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
             <div className="text-center pt-8 pl-4 pr-4">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Diesen Inhalt kannst du dir nur auf der</span>
                    <span className="text-orange-500">Walu-App</span>
                    <span className="mx-2 text-black">ansehen</span>
                    </div>
                </span>
            </div>
            <div className="pt-4">
                <div className="relative">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded text-center text-xm sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl tracking-tight"
                        onClick={() => {
                            if (phoneType === "iOS") {
                                window.location.href = "https://apps.apple.com/de/app/walu/id6474895726";
                            } else {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.walu.frontend";
                            }
                        }}
                    >
                        Walu App herunterladen
                    </button>
                </div>
            </div>

      </div>
    );
}

export default ShareMobile;