import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function Nutzungsvertrag( { isMobile } ) {
    const handleButtonClick = () => {
        window.open("https://walu-media.s3.eu-central-1.amazonaws.com/public/WaluNutzungsvertrag.pdf", '_blank');
      };

    return (
        <div className="p-6">
            {isMobile ? (<div className="flex items-center justify-center p-4">
                <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            </div>) : (<div></div>)}
            <div className={`relative w-full h-full ${isMobile ? 'pr-1 pl-1 pt-4 pb-4'  : 'p-6' } bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                <div className="text-black font-roboto">
                    
                    <div className={`flex ${isMobile ? 'flex-col'  : 'flex-row' } inline-block`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left pl-8 pr-8">
                    Nutzervertrag für Walu Kursanbieter<br /><br />
                    </p>
                    <div className={`inline-block ${isMobile ? 'pl-8 pb-8'  : '' }`}>
                    <button
                    onClick={handleButtonClick} 
                    className={`text-base lg:text-lg xl:text-xl 2xl:text-2xl leading-9 font-roboto ${isMobile ? 'px-4 py-1'  : 'px-8 py-4' } rounded-xl text-white bg-blue-500 hover:font-semibold`}
                    >
                    <FontAwesomeIcon icon={faDownload} className="text-base lg:text-lg xl:text-xl 2xl:text-2xl pr-4" />Exportieren
                    </button>
                    </div>
                   
                    </div>
                   

                    <div className="text-md lg:text-2xl xl:text-3xl 2xl:text-4xl font-roboto font-normal text-black leading-16 text-left pl-8 pr-8">                  
                    <p  ><span className="font-bold"
>§ 1 Vertragsparteien</span></p>

<p  ><span
>Dieser Vertrag wird abgeschlossen zwischen der Laatz & Zlenko GbR, nachfolgend als „Betreiber“ bezeichnet, mit Sitz in der Obergasse 29, 76877 Offenbach und dem zukünftigen Nutzer der Walu App, nachfolgend als „Nutzer“ bezeichnet.
Aus Gründen der besseren Lesbarkeit wird auf die gleichzeitige Verwendung der Sprachformen männlich, weiblich und divers (m/w/d) verzichtet. Sämtliche Personenbezeichnungen gelten gleichermaßen für alle Geschlechter.
</span></p>
<br></br>

<p  ><span className="font-bold"
>§ 2 Vertragsgegenstand</span></p>

<p  ><span
>Der Nutzer verpflichtet sich zur Zahlung einer einmaligen Anmeldegebühr in Höhe von 15,00€, die nicht zurückerstattet werden kann. Die Zahlung wird direkt nach Abschluss dieses Vertrags fällig. Weiterhin ist er nach Zahlung der Anmeldegebühr verpflichtet, die Walu App innerhalb eines Monats beginnend mit dem Tag der Veröffentlichung im App- oder Playstore zu downloaden und sich mit in dem im „Anbieter werden Formular“ ausgewählten Walu Paket als Walu Kursanbieter in der Walu App zu registrieren. Die Zahlungen für das Paket beginnen mit der Registrierung als Walu Kursanbieter in der Walu App. Änderungen des im „Anbieter werden Formular“ ausgewählten Pakets können vom Nutzer im App- oder Playstore vorgenommen werden. Das im Formular angegebene Paket muss, mit dem nach der Registrierung in der Walu App ausgewählten Paket übereinstimmen. Eine Änderung der Paketwahl ist nur nach Absprache mit den Betreibern möglich. Für die Zahlungen der jeweiligen monatlichen Beiträge des Pakets gelten die Nutzungsbedingungen des jeweiligen App- oder Playstores.  </span></p>

<p  ><span
>Der Betreiber verpflichtet sich, dem Nutzer das von diesem ausgewählte Paket zum Zeitpunkt der Appveröffentlichung im App- oder Playstore bereitzustellen.</span></p>

<p  ><span
>Verwendet der Nutzer bei Ausfüllen des „Anbieter werden Formulars“ den vom Betreiber bereitgestellten Bonuscode, bekommt er alle Bonusse innerhalb der ersten drei Monate nach Registrierung in der Walu App zur Verfügung gestellt.  Verwendet er den Bonuscode nicht, hat er keinen Anspruch auf Erhalt der Bonusse.  Der Bonuscode wird einmalig nur in einem dem Betreiber vorbehaltenen Zeitraum zur Verfügung gestellt. Bei Anspruch auf den Bonus und Änderung des Pakets gilt der Bonus weiterhin.</span></p>

<br></br>
<p  ><span className="font-bold"
>§ 3 Geldzurückgarantie</span></p>

<p  ><span
>Die Inanspruchnahme der Geldzurückgarantie ist nur möglich, wenn die Walu App als Walu Anbieter für mindestens vier Monate kostenpflichtig, ab Zeitpunkt der Registrierung, mit dem ausgewählten Paket, genutzt wurde.</span></p>

<p  ><span
>Die Geld-zurück-Garantie kann innerhalb eines Monats, nach Ablauf der viermonatigen Nutzung, in Anspruch genommen werden.</span></p>

<p  ><span
>Erfolgt die Inanspruchnahme der Geldzurückgarantie, dann kann der damit verbundene Walu Account von den Betreibern auf einen kostenfreien Account abgestuft und/oder gelöscht werden. </span></p>

<br></br>
<p  ><span className="font-bold"
>§ 4 Kündigung</span></p>

<p  ><span
>Das Monatspaket kann jederzeit mit sofortiger Wirkung über den jeweiligen App- oder Playstore gekündigt werden. Danach hat der Nutzer womöglich eingeschränkten oder keinen Zugang mehr zu seinem Anbieter-Nutzer-Konto.</span></p>

<br></br>
<p  ><span className="font-bold"
>§ 5 Haftungsbeschränkung</span></p>


<p  ><span
>Die Laatz & Zlenko GbR haftet nur für Vorsatz und grobe Fahrlässigkeit.</span></p>

<br></br>
<p  ><span className="font-bold"
>§ 6 Schlussbestimmungen</span></p>

<p  ><span
>Änderungen und Ergänzungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Textform. Dies gilt auch für die Änderung oder Aufhebung dieser Klausel.</span></p>

<p  ><span
>Mündliche Nebenabreden bestehen nicht.</span></p>

<p  ><span
>Sind oder werden einzelne Bestimmungen dieses Vertrages unwirksam, so wird dadurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Die Vertragspartner werden in diesem Fall die ungültige Bestimmung durch eine andere ersetzen, die dem wirtschaftlichen Zweck der weggefallenen Regelung in zulässiger Weise am nächsten kommt.</span></p>

<p  ><span
>Gerichtsstand für alle Streitigkeiten aus diesem Vertrag ist der Sitz der Laatz & Zlenko GbR.</span></p>
<br></br>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nutzungsvertrag;