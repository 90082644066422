function Impressum( { isMobile } ) {
    return (
        <div className="p-6">
            {isMobile ? (<div className="flex items-center justify-center p-4">
                <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            </div>) : (<div></div>)}
            <div className={`relative w-full h-full ${isMobile ? 'pr-1 pl-1 pt-4 pb-4'  : 'p-6' } bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                <div className="text-black font-roboto">
                    <div className={`text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left ${isMobile ? 'pr-2 pl-2 pt-4 pb-4'  : 'p-6' }`} style={{ wordBreak: 'break-word' }}>
                    Impressum<br /><br />
                    </div>

                    <div className={`text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-roboto font-normal text-black leading-16 text-left ${isMobile ? 'pr-2 pl-2 pt-4 pb-4'  : 'p-6' }`} style={{ wordBreak: 'break-word' }}>
                    <p>Dies ist unser Impressum.</p>
                    <p ><span >&nbsp;</span></p>
                    
<p ><b>Für die Veröffentlichung und den Betrieb des
Internetauftritts ist im Sinne des § 5 TMG verantwortlich</b></p>

<p ><b>&nbsp;</b></p>

<p >Verantwortlich für den Inhalt nach § 55 Abs. 2
Rundfunkstaatsvertrag (<span>RstV</span>)</p>

<p ><span >&nbsp;</span></p>

<p ><span >Laatz und Zlenko GbR</span></p>

<p ><span >&nbsp;</span></p>

<p ><span >Anschrift: Obergasse 29, 76877 Offenbach</span></p>

<p ><span >&nbsp;</span></p>

<p ><span >Telefon:<b>&nbsp;</b>+49 157 36814139</span></p>

<p ><span >&nbsp;</span></p>

<p ><span>E-Mail: info@mywalu.com</span></p>

<p ><span >&nbsp;</span></p>

<p ><span >Internet: </span>www.mywalu.com<span ></span></p>

<p ><span >&nbsp;</span></p>

<p ><span >USt-IdNr.: DE355605043</span></p>

<p ><span >&nbsp;</span></p>

<p ><span >Geschäftsführer:&nbsp;Tom Laatz,<b> </b>Daniel Zlenko</span><span
></span></p>

<p ><span >&nbsp;</span></p>

<p ><b><span >Der Nutzung von im
Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung
von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird
hiermit widersprochen.</span></b></p>

<p ><span>&nbsp;</span></p>

<p ><span >Wir behalten uns
rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen,
etwa durch Spam-Mails, vor. Zur außergerichtlichen Beilegung von
verbraucherrechtlichen Streitigkeiten hat die Europäische Union eine
Online-Plattform („OS-Plattform“) eingerichtet, an die Sie sich wenden können.
Die Plattform finden Sie unter</span>:&nbsp;<a
href="https://webgate.ec.europa.eu/odr/"><span>https://webgate.ec.europa.eu/odr/</span></a>.<span></span></p>

<p ><span>&nbsp;</span></p>

<p ><b><span >Verpflichtung für
Streitbeilegungsverfahren gemäß § 36 VSBG:</span></b></p>

<p ><b><span 
>&nbsp;</span></b></p>

<p ><span >Wir nehmen nicht an
einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil
und sind auch nicht dazu verpflichtet.</span></p>

<p ><span 
>&nbsp;</span></p>

<h1>1. Inhalt des Onlineangebots</h1>

<p ><span >&nbsp;</span></p>

<p ><span >Um die Informationen
auf dieser Website möglichst aktuell, sachlich korrekt und eindeutig zu halten,
werden bei der Erstellung dieser Website alle Anstrengungen unternommen, um
dies zu gewährleisten. Trotz aller Anstrengungen ist es möglich, dass es zu
unbeabsichtigten Fehlangaben kommen kann. Das Angebot der Webseiteninhalte
erfolgt von dem Verantwortlichen unverbindlich und unter Ausschluss jeglicher
Garantien oder Zusicherungen. Dies gilt ebenfalls für sämtliche Webseiten, auf
die mittels (Hyper-)Link verwiesen wird.</span></p>

<p ><span 
>&nbsp;</span></p>

<p ><span >Sofern seitens des
Verantwortlichen kein nachweislich vorsätzliches oder grob fahrlässiges
Verschulden vorliegt, sind Haftungsansprüche gegenüber dem Verantwortlichen,
welche sich auf Schäden materieller oder immaterieller Art beziehen, die durch
die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die
Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden,
ausgeschlossen.</span></p>

<p ><span 
>&nbsp;</span></p>

<p ><span >Alle Angebote sind
freibleibend und unverbindlich. Der Verantwortliche behält es sich ausdrücklich
vor, Teile der Seiten oder alle Seiten vollständig ohne gesonderte Ankündigung
zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder
endgültig einzustellen.</span></p>

<p  ><span


>&nbsp;</span></p>

<h1><span 
>2. Verweise und Links</span></h1>

<p ><span >&nbsp;</span></p>

<p ><span >Bei direkten oder
indirekten Verweisen auf fremde Internetseiten („Links“), welche außerhalb des
Verantwortungsbereichs des Verantwortlichen liegen, besteht eine
Haftungsverpflichtung ausschließlich in dem Fall, wenn der Verantwortliche von
den Inhalten Kenntnis hat und es technisch möglich und zumutbar wäre, die
Nutzung im Falle rechtswidriger Inhalte zu verhindern.</span></p>

<p  ><span


>&nbsp;</span></p>

<p ><span >Der Verantwortliche
erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine
illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der
verlinkten/verknüpften Seiten hat der Verantwortliche keinerlei Einfluss. Es
ist nicht zumutbar, eine permanente inhaltliche Kontrolle der verlinkten Seiten
ohne konkrete Anhaltspunkte einer Rechtsverletzung durchzuführen. Deshalb
distanzieren wir uns hiermit ausdrücklich von allen Inhalten der
verlinkten/verknüpften Seiten, welche nach der Linksetzung verändert wurden.
Diese Feststellung gilt für alle innerhalb des eigenen Internetangebots
gesetzten Links und Verweise sowie für Fremdeinträge in den eingerichteten
Gästebüchern, Diskussionsforen, Anbieterprofilen und Mailinglisten des
Verantwortlichen. Sollten aus der Nutzung oder Nichtnutzung solcherart
dargebotener Informationen Schäden entstehen, haftet allein der Anbieter der
Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die
jeweilige Veröffentlichung lediglich verweist. Dies gilt ebenfalls für
illegale, fehlerhafte oder unvollständige Inhalte. Bei Bekanntwerden von
Rechtsverletzungen werden wir derartige Links entfernen.</span></p>

<p  ><span


>&nbsp;</span></p>

<h1><span 
>3. Urheber- und Kennzeichenrecht</span></h1>

<p ><span >&nbsp;</span></p>

<p ><span >Der Verantwortliche
ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten
Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von dem
Verantwortlichen selbst erstellte Grafiken, Tondokumente, Videosequenzen und
Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und
Texte zurückzugreifen.</span></p>

<p ><span 
>&nbsp;</span></p>

<p ><span >Alle innerhalb des
Internetangebots genannten und ggf. durch Dritte geschützten Marken- und
Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen
Kennzeichenrechts und den Besitzrechten der jeweiligen Eigentümer. Allein
aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen
nicht durch Rechte Dritter geschützt sind.</span></p>

<p ><span 
>&nbsp;</span></p>

<p ><span >Die durch uns
erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
Urheberrecht. Das Urheberrecht für veröffentlichte, von dem Verantwortlichen
selbst erstellte Werke bleibt allein beim Urheber. Ohne ausdrückliche
Zustimmung durch den Verantwortlichen ist eine Vervielfältigung oder Verwendung
solcher Werke, insbesondere Grafiken, Tondokumente, Videosequenzen und Texte,
nicht gestattet. Im Fall einer rechtmäßigen Nutzung des auf dieser Homepage zur
Verfügung gestellten Informations- und Bildmaterials muss diese unter Angabe
der urheberrechtlichen Quelle erfolgen. Wir behalten uns das Recht vor, die
Genehmigung für die Vervielfältigung urheberrechtlich geschützten Materials
jederzeit zurückzuziehen. Dies gilt insbesondere dann, wenn von dem Recht für
die Vervielfältigung entsprechenden Materials nach unserer Ansicht auf eine den
unseren Interessen zuwiderlaufenden Art und Weise Gebrauch gemacht wird oder
Anweisungen zum Schutz des Urheberrechts nicht konsequent eingehalten werden.</span></p>

<p ><span >&nbsp;</span></p>

<p ><span >Wir übernehmen in
Verbindung mit dieser Genehmigung keinerlei Gewährleistung oder Haftung für die
Freiheit von Rechten Dritter.</span></p>

<p  ><span


>&nbsp;</span></p>

<h1><span 
>4. Haftungsbeschränkung und Haftungsausschluss</span></h1>

<p ><span >&nbsp;</span></p>

<p >Diese Website wurde mit größtmöglicher Sorgfalt
zusammengestellt. Gemäß § 7 Abs. 1 TMG sind wir als Diensteanbieter für eigene
Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
Entsprechend §§ 8 bis 10 TMG besteht für uns als Diensteanbieter jedoch keine
Verpflichtung, übermittelte oder gespeicherte fremde Informationen zu
überwachen. Weiterhin sind wir nicht verpflichtet, nach Umständen zu forschen,
die auf eine rechtswidrige Tätigkeit hinweisen. Hiervon unberührt bleiben
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
Kenntniserlangung. Vor dem Zeitpunkt der Kenntnis ist eine diesbezügliche
Haftung für eine konkrete Rechtsverletzung nicht möglich. Die entsprechenden
Inhalte werden nach Bekanntwerden entsprechender Rechtsverletzungen umgehend
entfernt.</p>

<p  ><span


>&nbsp;</span></p>

<p ><span >Sofern seitens des
Verantwortlichen kein nachweislich vorsätzliches oder grob fahrlässiges
Verschulden vorliegt, sind Haftungsansprüche gegenüber dem Verantwortlichen,
welche sich auf Schäden materieller oder immaterieller Art beziehen und durch
die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die
Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden,
ausgeschlossen.</span></p>

<p  ><span


>&nbsp;</span></p>

<p ><span >Alle Angebote sind
freibleibend und unverbindlich. Wir behalten es uns ausdrücklich vor, Teile der
oder alle Seiten ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu
löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen oder an
andere Nutzungsbedingungen zu knüpfen.</span></p>

<p ><span>&nbsp;</span></p>

<h1><span 
>5. Rechtswirksamkeit dieses Haftungsausschlusses</span></h1>

<p ><span >&nbsp;</span></p>

<p ><span >Dieser
Haftungsausschluss ist als Teil des Internetangebots zu betrachten, von dem aus
auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen
dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig
entsprechen sollten, bleiben die übrigen Teile des Dokuments in ihrem Inhalt
und ihrer Gültigkeit davon unberührt.</span><span></span></p>

<p >&nbsp;</p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Impressum;