function PaketeMobile() {
    return (
        <div 
        className="flex flex-col items-center justify-center">
            <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            <div className="text-center pt-2 pl-4 pr-4">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Dein "Walu Paket"</span>
                    </div>
                </span>
            </div>
            <div className="text-center pl-4 pr-4">
                <span className="text-sm lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">
                    Willst du Kurse anbieten, brauchst du ein Walu Paket. Jedes Paket hat seine eigene Vorteile und eins davon passt zu dir.
                    </span>
                    </div>
                </span>
            </div>

            <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
            Was ist ein Walu Paket?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Die Walu Pakete haben unterschiedliche <b>Vorteile</b>:</div>
                
                    <ul class="list-disc pl-8 text-base px-2">
                    <li>Als <b>Walu Kursanbieter</b> nutzt du die App immer mit einem Paket.📱</li><br/>
                    <li>Jeder Anbieter von Kursen braucht <b>nur ein Paket</b>.💼</li><br/>
                    <li>Das Paket wird von dir <b>bei Kontoerstellung</b> in der App abgeschlossen. 📦</li><br/>
                    <li><b>Zahlungen</b> fallen monatlich an.💵</li><br/>
                    <li>Es kann <b>jederzeit</b> gekündigt werden. 🔄</li><br/>
                    </ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>  

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Welche Walu Pakete gibt es?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Zwischen welchen <b>Paketen</b> kann ich als Anbieter auswählen?</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Du möchtest nur <b>einen Kurs</b> anbieten? Nutze das Silber Paket.🥈</li><br/>
<li>Möchtest du <b>mehrere Kurse</b> anbieten? Das Gold Paket ist genau das richtige für dich.🥇</li><br/>
<li>Du bist ein <b>Unternehmen, Verein oder dergleichen</b>? Dann möchten wir dir das Platin Paket anbieten.🏆</li><br/>
</ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>


<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>  

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Was ist das Walu Silber Paket?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Für <b>9,99€ monatlich</b> (<span className="underline">jederzeit</span> kündbar) kannst du:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Persönliches <b>Anbieter Profil</b> erstellen.📝</li><br/>
                    <li><b>Einen</b> Kurs online schalten.🚀</li><br/>
                    <li>Dein Kurs mit <b>Walu Chatgruppe</b>.💬</li><br/>
                    <li>Deinen Kurs in ganz <b>Deutschland</b>.🌐</li><br/>
</ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>


<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>  


<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Was ist das Walu Gold Paket?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Für <b>24,99€ monatlich</b> (<span className="underline">jederzeit</span> kündbar) kannst du:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Persönliches <b>Anbieter Profil</b> erstellen.📝</li><br/>
<li><b>Unbegrenzt Kurse</b> online schalten.🚀</li><br/>
<li>Deine Kurse mit <b>Walu Chatgruppen</b>.💬</li><br/>
<li>Deine Kurse in ganz <b>Deutschland</b>.🌐</li><br/>
</ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>


<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>  


<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Was ist das Walu Platin Paket?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Für <b>99,99€ monatlich</b> (<span className="underline">jederzeit</span> kündbar) kannst du:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Persönliches <b>Anbieter Profil</b> erstellen.📝</li><br/>
<li><b>Unbegrenzt Kurse</b> online schalten.🚀</li><br/>
<li><b>Kursmanager</b> für deine Kurse ernennen.👥</li><br/>
<li><b>Kursanzeige</b> von externen Kursen.🔄</li><br/>
<li>Deine Kurse mit <b>Walu Chatgruppen</b>.💬</li><br/>
<li>Deine Kurse in ganz <b>Deutschland</b>.🌐</li><br/>
</ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>


      </div>
    );
}

export default PaketeMobile;