import { Link  } from 'react-router-dom';

function WelcheBonusseNoch3() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Welchen Vorteil habe ich, wenn ich schon jetzt Walu Anbieter werde?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bonus6.jpg"
                    alt="Bonusse"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Sei einer der <b>ersten 1000 Anbieter</b> und erhalte:<br/><br/>

                    <ul class="list-decimal pl-8">
                    <li>Bonus: 🏃‍♂️ Das "<b>Walu Sprinter Paket</b>". <Link to="/bonus1"><span className="text-blue-600">(mehr Infos)</span></Link></li><br/>
                    <li>Bonus: 💰 "<b>Dreimonatige Geld-zurück-Garantie</b>". <Link to="/bonus2"><span className="text-blue-600">(mehr Infos)</span></Link></li><br/>
                    <li>Bonus: 🌟 Dein Paket als "<b>Plus Paket</b>", mit noch mehr Vorteilen.</li><br/>
                    </ul>

                    <Link to="/contact">
                        <div className="inline-block py-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div
                            className={`text-xl leading-9 font-roboto px-8 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            >Jetzt Bonuscode sichern
                            </div>
                        </div>
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WelcheBonusseNoch3;