import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import FürWenPräsi from './sections/FürWenPräsi';
import LernenPräsi from './sections/LernenPräsi';
import WieKursanbieter from './sections/WieKursanbieter';

function Webinar() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    const handleButtonClick = () => {
        window.open("https://walu-media.s3.eu-central-1.amazonaws.com/public/WaluPra%CC%88sentation.pdf", '_blank');
      };

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [showEmailError, setShowEmailError] = useState(false);
    const validateEmail = (email) => {
        const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
        return isValid;
      };
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(validateEmail(emailValue));
    };
    const [emailFocused, setEmailFocused] = useState(false);
    const handleEmailFocus = () => {
        setEmailFocused(true);
      };
    const handleEmailBlur = () => {
        setEmailFocused(false);
    };

    // Create an array of datetime values (replace with your own dates)
    const dateTimeArray = [
        new Date('2023-11-08T14:00:00'),
        new Date('2023-11-09T17:00:00'),
        new Date('2023-11-12T10:00:00'),

        new Date('2023-11-15T14:00:00'),
        new Date('2023-11-16T17:00:00'), 
        new Date('2023-11-19T10:00:00'),

        new Date('2023-11-22T14:00:00'),
        new Date('2023-11-23T17:00:00'), 
        new Date('2023-11-26T10:00:00'),

        new Date('2023-11-29T14:00:00'),
        new Date('2023-11-30T17:00:00'), 
        new Date('2023-12-03T10:00:00'),

        new Date('2023-12-06T14:00:00'),
        new Date('2023-12-07T17:00:00'), 
        new Date('2023-12-10T10:00:00'),

        new Date('2023-12-13T14:00:00'),
        new Date('2023-12-14T17:00:00'), 
        new Date('2023-12-17T10:00:00'),

        new Date('2023-12-20T14:00:00'),
        new Date('2023-12-21T17:00:00'), 

        new Date('2023-12-27T14:00:00'),
        new Date('2023-12-28T17:00:00'), 
      ];
    
      // Filter out past dates
      const currentDate = new Date();
      const futureDates = dateTimeArray.filter((date) => date > currentDate);
    
      // Sort the array by date in ascending order
      futureDates.sort((a, b) => a - b);
    
      // Get the 3 nearest future dates
      const nearestDates = futureDates.slice(0, 3);
    
      // State to track the selected date
      const [selectedDate, setSelectedDate] = useState(null);

      const formatDateTime = (date) => {
        const dateCopy = new Date(date);

        const wochentag = dateCopy.toLocaleString('de-DE', { weekday: 'long' });
        const datum = dateCopy.toLocaleString('de-DE', { day: '2-digit', month: '2-digit' });
        const startTime = dateCopy.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
        
        dateCopy.setHours(dateCopy.getHours() + 1); // Add one hour for the end time
        
        const endTime = dateCopy.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' });
        
        return `Am ${wochentag}, den ${datum}, von ${startTime} bis ${endTime} Uhr`;
      };
    
      const handleDateClick = (date) => {
        if (!isAngemeldet) {
            setSelectedDate(date);
        }
      };


      const [serverError, setServerError] = useState("");
      const canAnmelden = selectedDate !== null && email.length > 0
      const [isAngemeldet, setIsAngemeldet] = useState(false);
      const [disableEmailField, setDisableEmailField] = useState(false);
      const handleAnmelden = async () => {
        if (!isEmailValid) {
            setShowEmailError(!isEmailValid)
        } else if (canAnmelden) {
            setShowEmailError(false);
            try {
                const response = await fetch("https://aws.mywalu.com/kuusco/api/websitemembers/webinar", {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    email: email,
                    termin: selectedDate,
                  }),
                });
          
                if (response.ok) {
                    setDisableEmailField(true);
                    setIsAngemeldet(true);
                } else {
                    setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
                }
            } catch (error) {
                setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
                console.error('An error occurred', error);
            }      
        }
      };

    return (
        <div className="p-6">
             <div
            className="flex flex-col justify-start"
            style={{
                paddingBottom: '5%',
                paddingLeft: '3%',
                marginTop: '-2vh', // Move the content up
            }}
            >
                <div className="flex-none text-black font-roboto text-left pt-8">
                    <span
                     className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Die "Bring dein Kurs<br></br> auf Erfolgskurs"<br />
                    Online-Präsentation 🚀
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                    <div className="w-full sm:w-[15rem] md:w-[25rem] lg:w-[35rem] xl:w-[45rem] 2xl:w-[55rem]">
                        <span
                            className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Melde dich für die <b>30-minütige Online-Präsentation</b> an und finde heraus, wie du <b>deinen Kurs auf Erfolgskurs</b> bringst.
                        </span>
                    </div>
                </div>
            </div>


        <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
        Was ist die Online-Präsentation?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
                <div className="w-1/2 text-2xl font-roboto text-black text-left pl-8">
                <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                   
                <div className="pb-4 font-semibold pt-4">1. E-Mail Adresse eingeben</div>
                    <div className="relative w-2/3 pl-4 pr-4 pt-4">
                        <input
                            className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none transition duration-300 
                            ${showEmailError ? 'border-red-500' : emailFocused ? 'focus:border-blue-500 border-blue-500' : 'focus:border-blue-500'}`}
                            placeholder=" "
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={handleEmailFocus}
                            onBlur={handleEmailBlur}
                            maxLength={200}
                            disabled={disableEmailField}
                        />
                        <label
                            htmlFor="email"
                            className={`absolute left-6 top-[4px] bg-white px-2 pb-1 text-${
                            showEmailError ? 'red-500' : emailFocused ? 'blue-500' : 'gray-500'
                            } text-base transition-all duration-300`}
                        >
                            E-Mail
                        </label>
                        <div
                            className="pt-2 pl-2 gray-500 text-base"
                        >
                            Wir verwenden deine E-Mail nur, um dir eine Einladung zur Online-Präsentation zu schicken.
                        </div>
                    </div>
                    <div className="pt-4 font-semibold">2. Termin auswählen</div>
                    <div className="pt-2 pl-8 pb-8">
                  {nearestDates.map((date, index) => (
        <div key={index}>
          <label className="inline-flex items-center" onClick={() => handleDateClick(date)}>
            <input
              type="radio"
              className="form-radio h-6 w-6 text-blue-600"
              name="date"
              value={date}
              checked={selectedDate && selectedDate.getTime() === date.getTime()}
              readOnly
            />
            <span className="ml-4 text-2xl">
            {formatDateTime(date)}
            </span>
          </label>
        </div>
      ))}
      <div>
            <div className="pt-8">
            {serverError !== "" ? (
            <div>
                <div className="text-2xl leading-9 pb-4 font-roboto font-normal text-red-600">
                    {serverError}
                </div>
            </div>
            ) : (<div></div>)}

            {isAngemeldet ? (
                <div
                    className={`text-2xl inline-block leading-9 font-roboto px-8 py-2 rounded-xl text-white bg-green-500`}
                    >
                    Du wurdest angemeldet <br></br><br></br>
                    Wir haben dir eine E-Mail mit weiteren Anweisungen geschickt.
                </div>
            ) : (
                <div
                    className={`text-2xl inline-block leading-9 font-roboto px-8 py-2 rounded-xl ${canAnmelden ? 'bg-blue-500 hover:font-semibold' : 'bg-gray-300'} text-white`}
                    onClick={handleAnmelden}
                    style={{ cursor: 'pointer' }}
                    >
                    Jetzt kostenlos anmelden
                </div>
            )}
            </div>

            <div className="pt-4">


        <div onClick={handleButtonClick} className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
            <div
            className={`text-lg leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            > <FontAwesomeIcon icon={faDownload} className="text-sm lg:text-base xl:text-lg 2xl:text-xl pr-2" /> Präsentation herunterladen
            </div>
        </div>

            </div>
           
            
                            
                        </div>
                    </div>
                    </div>
                
                </div>
            </div>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <FürWenPräsi></FürWenPräsi>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<LernenPräsi></LernenPräsi>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<WieKursanbieter></WieKursanbieter>

        </div>
    );
}

export default Webinar;