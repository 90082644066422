import React, { useState, useEffect } from 'react';
import { Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function FormularMobile() {
    const steps = ['1', '2', '3', '4'];
    const [currentStep, setCurrentStep] = useState(0);
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    const [showEmailError, setShowEmailError] = useState(false);
    const nextStep = () => {
        setShowEmailError(!isEmailValid)
        if (!isButtonDisabled && isEmailValid) {
            setCurrentStep(currentStep + 1);
        }
    };

    const previousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling animation
        });
      };
      

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [name, setName] = useState('');
    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const [nameFocused, setNameFocused] = useState(false);
    const handleNameFocus = () => {
        setNameFocused(true);
      };
    const handleNameBlur = () => {
        setNameFocused(false);
    };

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const validateEmail = (email) => {
        const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
        return isValid;
      };
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(validateEmail(emailValue));
    };
    const [emailFocused, setEmailFocused] = useState(false);
    const handleEmailFocus = () => {
        setEmailFocused(true);
      };
    const handleEmailBlur = () => {
        setEmailFocused(false);
    };

    const [phone, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const [phoneFocused, setPhoneFocused] = useState(false);
    const handlePhoneFocus = () => {
        setPhoneFocused(true);
      };
    const handlePhoneBlur = () => {
        setPhoneFocused(false);
    };

    useEffect(() => {
        const isDisabled = !(name.trim() && email.trim());
        setIsButtonDisabled(isDisabled);
    }, [name, email]);

    const [paket, setPaket] = useState('');
    const [isStep2ButtonDisabled, setIsStep2ButtonDisabled] = useState(true);
    
      const handlePaketChange = (value) => {
        setPaket(value);
      };

      const nextStep2 = () => {
        if (!isStep2ButtonDisabled) {
            setCurrentStep(currentStep + 1);
        }
    };

    useEffect(() => {
        if (paket) {
        setIsStep2ButtonDisabled(false);
        } else {
        setIsStep2ButtonDisabled(true);
        }
    }, [paket]);


    const [accept1, setAccept1] = useState(false);
    const [accept2, setAccept2] = useState(false);
    const [accept3, setAccept3] = useState(false);

    const [code, setCode] = useState("");
    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };
    const [codeFocused, setCodeFocused] = useState(false);
    const handleCodeFocus = () => {
        setCodeFocused(true);
      };
    const handleCodeBlur = () => {
        setCodeFocused(false);
    };
    const [codeError, setCodeError] = useState("");
    const [serverError, setServerError] = useState("");
    const [isCodeValid, setIsCodeValid] = useState(false);

    const handleValidateCode = async () => {
        try {
            const response = await fetch("https://aws.mywalu.com/kuusco/api/websitemembers/code", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                code: code.trim(),
              }),
            });
      
            if (response.ok) {
                setCodeError("")
                setIsCodeValid(true)
            } else {
                setCodeError('Dieser Code ist ungültig.');
            }
        } catch (error) {
            setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            console.error('An error occurred', error);
        }      
    };

    const goToLastStep = async () => {
        if (accept1 && accept2 && accept3) {
            setServerError("")
            setCurrentStep(currentStep + 1);
        }        
    };

    // creates a paypal order
    const createOrder = async (_data, _actions) => {
        try {
            const response = await fetch("https://aws.mywalu.com/kuusco/api/websitemembers/paypal/create_order", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                const orderID = await response.text(); 
                return orderID;
            } else {
                setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            }
        } catch (error) {
            setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            console.error('An error occurred', error);
        }
    };

    // check Approval
    const onApprove = async (data, _actions) => {
        try {
            let captureOrderUrl;
            if (code.trim() !== "" && isCodeValid) {
                captureOrderUrl = `https://aws.mywalu.com/kuusco/api/websitemembers/paypal/capture_order/${data.orderID}?code=${code.trim()}`;
            } else {
                captureOrderUrl = `https://aws.mywalu.com/kuusco/api/websitemembers/paypal/capture_order/${data.orderID}`;
            }

            const response = await fetch(captureOrderUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name.trim(),
                    telefonnummer: phone ? phone : null,
                    email: email.trim(),
                    paket: paket,
                    plattform: "I",
                }),
            });
    
            if (response.ok) {
                setCurrentStep(currentStep + 1);
            } else {
                setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            }
        } catch (error) {
            setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            console.error('An error occurred', error);
        }
    };

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

    const initialOptions = {
        clientId: CLIENT_ID,
        currency: "EUR",
        intent: "capture",
    };
    

    // End Paypal

    return (
        <div 
        className="flex flex-col items-center justify-center">
            <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            <div className="text-center pt-2 pl-4 pr-4">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Walu Kursanbieter werden</span>
                    </div>
                </span>
            </div>
            <div className="text-center pl-4 pr-4">
                <span className="text-sm lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">
                    Um bereits jetzt Walu Anbieter zu werden fülle das Formular aus. Wir von Walu freuen uns, mit dir zusammenarbeiten zu dürfen!
                    </span>
                    </div>
                </span>
            </div>

            <div className="px-4 pt-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>

    <div className="p-6">
      <div className="flex items-center w-full text-sm font-medium text-center text-gray-500 sm:text-base">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className={`rounded-full w-8 h-8 flex items-center justify-center ${index <= currentStep ? 'bg-blue-600 text-white' : 'bg-gray-200'} p-2`}>
                {step}
            </div>
            {index < steps.length - 1 && <div className="w-12"></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
   

    <div className="text-black font-roboto">
      <div className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        {(() => {
          switch (currentStep) {
            case 0:
                return (
                    <div>
                        <div className="flex-col items-center justify-center">
                            <div className="relative pl-4 pr-4">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                    ${nameFocused ? 'border-blue-500' : ''}`}
                                    placeholder=" "
                                    value={name}
                                    onChange={handleNameChange}
                                    onFocus={handleNameFocus}
                                    onBlur={handleNameBlur}
                                    maxLength={500}
                                />
                                <label
                                    htmlFor="name"
                                    className={`absolute left-6 top-[-10px] bg-white px-2 pb-1 text-${
                                    nameFocused ? 'blue-500' : 'gray-500'
                                    } text-sm transition-all duration-300`}
                                >
                                    Vor- und Nachname
                                </label>
                                <div
                                    className="pt-2 pl-2 gray-500 text-sm"
                                    style={{ height: '50px' }}
                                >
                                </div>
                            </div>

                            <div className="relative pl-4 pr-4">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full pl-4 pr-4 text-base h-16 focus:outline-none transition duration-300 
                                    ${showEmailError ? 'border-red-500' : emailFocused ? 'focus:border-blue-500 border-blue-500' : 'focus:border-blue-500'}`}
                                    placeholder=" "
                                    value={email}
                                    onChange={handleEmailChange}
                                    onFocus={handleEmailFocus}
                                    onBlur={handleEmailBlur}
                                    maxLength={200}
                                />
                                <label
                                    htmlFor="email"
                                    className={`absolute left-6 top-[-10px] bg-white px-2 pb-1 text-${
                                    showEmailError ? 'red-500' : emailFocused ? 'blue-500' : 'gray-500'
                                    } text-sm transition-all duration-300`}
                                >
                                    E-Mail
                                </label>
                                <div
                                    className="pt-2 pl-2 gray-500 text-sm"
                                    style={{ height: '100px' }}
                                >
                                   
                                </div>
                            </div>

                            <div className="relative pl-4 pr-4">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                    ${phoneFocused ? 'border-blue-500' : ''}`}
                                    placeholder=" "
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    onFocus={handlePhoneFocus}
                                    onBlur={handlePhoneBlur}
                                    maxLength={30}
                                />
                                <label
                                    htmlFor="phone"
                                    className={`absolute left-6 top-[-10px] bg-white px-2 pb-1 text-${
                                    phoneFocused ? 'blue-500' : 'gray-500'
                                    } text-sm transition-all duration-300`}
                                >
                                    Handynummer (optional)
                                </label>
                                <div
                                    className="pt-2 pl-2 gray-500 text-sm"
                                    style={{ height: '100px' }}
                                >
                                    Wird nur verwendet um dir zu sagen, wenn die App für dich zur Verfügung steht.
                                </div>
                            </div>
                        </div>

                        <div className="inline-block py-8 px-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {showEmailError ? (<div className="text-sm leading-9 font-roboto px-2 text-red-600">
                            Die E-Mail ist nicht korrekt.
                            </div>) : (<div></div>)}
                            <div
                            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white ${
                                isButtonDisabled ? 'bg-gray-300' : 'bg-blue-500 hover:font-semibold'
                              }`}
                            onClick={nextStep}
                            style={{ cursor: 'pointer' }}
                            disabled={isButtonDisabled}
                            >Weiter
                            </div>
                        </div>
                    </div>
                   
                );
            case 1:
                return (
                    <div>
                        
                    <div className="text-xl pt-8 leading-9 font-roboto font-normal text-black pl-2 pr-2">
                       Welches Walu Paket wirst du zum 01.04.24 kostenpflichtig abschließen?
                    </div>

                    <div className="pt-4 pl-4">
                        <div>
                            <label className="inline-flex items-center">
                            <input type="radio" className="form-radio h-4 w-4 text-blue-600" name="paket" value="S" checked={paket === 'S'} onChange={() => handlePaketChange('S')}/>
                            <span className="ml-4 text-base"><b>Silber Paket</b> (9,99€ monatlich, jederzeit kündbar) <br/>
                            = einen Kurs online schalten <Link to="/pakete" target="_blank"><span className="text-blue-600">und vieles mehr <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm" /></span></Link>.
                            </span>
                            </label>
                        </div>
                        <div className="pt-4">
                            <label className="inline-flex items-center">
                            <input type="radio" className="form-radio h-4 w-4 text-blue-600" name="paket" value="G" checked={paket === 'G'} onChange={() => handlePaketChange('G')}/>
                            <span className="ml-4 text-base"><b>Gold Paket</b> (24,99€ monatlich, jederzeit kündbar) <br/>
                            = unbegrenzt Kurse online schalten <Link to="/pakete" target="_blank"><span className="text-blue-600">und vieles mehr <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm" /></span></Link>.
                            </span>
                            </label>
                        </div>
                        <div className="pt-4">
                            <label className="inline-flex items-center">
                            <input type="radio" className="form-radio h-4 w-4 text-blue-600" name="paket" value="P" checked={paket === 'P'} onChange={() => handlePaketChange('P')}/>
                            <span className="ml-4 text-base"><b>Platin Paket</b> (99,99€ monatlich, jederzeit kündbar) <br/>
                            = unbegrenzt Kurse online schalten, Kursmanager ernennen, <Link to="/pakete" target="_blank"><span className="text-blue-600">und vieles mehr <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm" /></span></Link>.
                            </span>
                            </label>
                        </div>
                    </div>

                    <div className="inline-block pt-12 px-8 pb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="flex items-center">
                            <div
                            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            onClick={previousStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Zurück
                            </div>
                        </div>
                        <div>
                            <div
                            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white ${isStep2ButtonDisabled ? 'bg-gray-300' : 'bg-blue-500 hover:font-semibold'}`}
                            onClick={nextStep2}
                            style={{ cursor: 'pointer' }}
                            >
                            Weiter
                            </div>
                        </div>
                    </div>

                    </div>
                );
            case 2:
                return (
                    <div>
                        
                    <div className="text-xl leading-9 pl-2 pr-2 font-roboto font-normal text-black">
                       1. Um Walu Anbieter zu werden musst du einmalig 15€ Anmeldegebühren zahlen. Die monatlichen kosten 
                        für dein Paket werden erst zum 01.04.24 fällig, nachdem du dich in der Walu App registriert hast.
                    </div>

                    <div className="pt-4 pl-4">
                        <label className="inline-flex items-center">
                        <input type="radio" className="form-checkbox h-6 w-6 text-blue-600" name="paket" value="P" checked={accept1} onChange={() => setAccept1(!accept1)}/>
                        <span className="ml-4 text-base">Ich bin damit einverstanden, einmalig 15€ Anmeldegebühren zu zahlen.</span>
                        </label>
                    </div>

                    <div className="text-xl pt-8 pl-2 pr-2 leading-9 font-roboto font-normal text-black">
                       2. Um Walu Anbieter zu werden musst du den Vertragsbedingungen zustimmen.
                    </div>

                    <div className="pt-4 pl-4">
                    <label className="inline-flex items-center">
                        <input type="radio" className="form-checkbox h-6 w-6 text-blue-600" name="swag" value="S" checked={accept2} onChange={() => setAccept2(!accept2)}/>
                        <span className="ml-4 text-base">Ja, ich habe die <Link to="/agb" target="_blank"><span className="text-blue-600">Walu AGB <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm" /></span></Link> gelesen und bin damit einverstanden.</span>
                        </label>

                    </div>

                    <div className="pt-4 pl-4">
                    <label className="inline-flex items-center">
                        <input type="radio" className="form-checkbox h-6 w-6 text-blue-600" name="xd" value="X" checked={accept3} onChange={() => setAccept3(!accept3)}/>
                        <span className="ml-4 text-base">Ja, ich habe den <Link to="/nutzungsvertrag" target="_blank"><span className="text-blue-600">Nutzervertrag für Walu Kursanbieter <FontAwesomeIcon icon={faUpRightFromSquare} className="text-sm" /></span></Link> gelesen und bin damit einverstanden.</span>
                        </label>
                    </div>

                    <div className="text-xl pt-8 pb-4 pl-2 pr-2 leading-9 font-roboto font-normal text-black">
                       3. Falls du einen Walu Bonuscode hast, kannst du ihn hier einlösen.
                    </div>

                    <div className="relative pl-4 pr-4 pt-4">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                    ${codeFocused ? 'border-blue-500' : ''}`}
                                    placeholder=" "
                                    value={code}
                                    onChange={handleCodeChange}
                                    onFocus={handleCodeFocus}
                                    onBlur={handleCodeBlur}
                                    disabled={isCodeValid}
                                />
                                <label
                                    htmlFor="code"
                                    className={`absolute left-6 top-[4px] bg-white px-2 pb-1 text-${
                                    codeFocused ? 'blue-500' : 'gray-500'
                                    } text-sm transition-all duration-300`}
                                >
                                    Bonuscode
                                </label>
                        </div>

                    <div className="pt-4 pl-4 inline-block">
                        {isCodeValid ? (
                            <div
                                className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-green-500`}
                                >
                                Eingelöst
                            </div>
                        ) : (
                            <div
                                className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                                onClick={handleValidateCode}
                                style={{ cursor: 'pointer' }}
                                >
                                Einlösen
                            </div>
                        )}
                        
                    </div>
                    {codeError !== "" ? (
                    <div>
                        <div className="text-base leading-9 pl-4 pt-2 font-roboto font-normal text-red-600">
                            {codeError}
                        </div>
                    </div>
                    ) : (<div></div>)}
                    {serverError !== "" ? (
                    <div>
                        <div className="text-base leading-9 pl-4 pt-2 font-roboto font-normal text-red-600">
                            {serverError}
                        </div>
                    </div>
                    ) : (<div></div>)}

                    <div className="inline-block pt-12 px-8 pb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="flex items-center">
                            <div
                            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            onClick={previousStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Zurück
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div
                            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white ${(accept1 && accept2 && accept3) ? 'bg-blue-500 hover:font-semibold' : 'bg-gray-300'}`}
                            onClick={goToLastStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Weiter
                            </div>
                        </div>
                    </div>

                    </div>                    
                );
            case 3:
                return (
                    <div>

                    <div className="text-xl pt-8 pl-2 pr-2 leading-9 font-roboto font-normal text-black pb-8">
                       Wähle aus, wie du die Anmeldegebühren bezahlen möchtest.
                    </div>

                    <div className="pt-2 pl-2 pr-2">
                        <PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons
                                style={{ layout: "vertical" }}
                                onApprove={onApprove}
                                createOrder={createOrder}
                            />
                        </PayPalScriptProvider>
                    </div>

                    {serverError !== "" ? (
                    <div>
                        <div className="text-base leading-9 pl-4 pt-2 font-roboto font-normal text-red-600">
                            {serverError}
                        </div>
                    </div>
                    ) : (<div></div>)}


                    <div className="inline-block pt-12 px-8 pb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="flex items-center">
                            <div
                            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            onClick={previousStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Zurück
                            </div>
                        </div>
                    </div>
                    </div>
                );
            case 4:
                return (
                    <div className="flex items-center">
                    <div className="w-12 h-12 flex items-center justify-center bg-green-500 rounded-full text-white p-4">
                      <svg
                        className="w-8 h-8"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div className="text-black leading-9 font-roboto font-normal text-base font-semibold p-4">
                    Vielen Dank für deine Zahlung und dein Vertrauen in uns. Wir von Walu werden alles dafür geben, dass du deine Ziele als Walu Kursanbieter erreichst!<br></br>
                    Wir haben dir eine E-Mail mit weiteren Anweisungen geschickt.
                    </div>
                  </div>
                );
            default:
              return (<div>Test</div>);
          }
        })()}
      </div>
    </div>

  </div>
</div>
{currentStep === 0 ? (<div>
        <div className="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
            Wieso soll ich meine Kurse über die Walu App anbieten?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Du als <b>Kursanbieter</b> auf Walu hast folgende Vorteile:</div>
                
                    <ul className="list-disc pl-8 text-base px-2">
                    <li>📱 Neue <b>Kursinteressierte</b> können dich ganz einfach über die Walu App finden und kontaktieren.</li><br/>
                        <li>🕒 Alle <b>Informationen</b> zu deinem Kurs, wie z.B. Kurszeiten, eine eigene Kurs-Chatgruppe uvm., werden digitalisiert.</li><br/>
                        <li>📸 Deine <b>Kursmitglieder</b> können coole Fotos und Videos in deinen Kurs posten.</li><br/>
                    </ul>
                    <div className="pb-4 font-semibold pl-6 pr-6 pb-8 text-lg"><b>Noch unsicher?</b> Finde in der Online-Präsentation heraus, ob Walu das richtige für dich und deinen Kurs ist.</div>
                    <Link to="/presentation">
        <div className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            >Für Präsentation anmelden
            </div>
        </div>
    </Link>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>
   
    <div className="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

   
    <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
    Wie werde ich Walu Kursanbieter?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Du als <b>Kursanbieter</b> auf Walu hast folgende Vorteile:</div>
                
                    <ul className="list-disc pl-8 text-base px-2">
                    <li>Scrolle nach oben oder rufe <button onClick={scrollToTop}><span className="text-blue-600">den Link</span></button> auf. 🌐</li><br/>
    <li>Fülle die "<b>Allgemeinen Informationen</b>" zu dir als Anbieter aus. 📝</li><br/>
    <li>Fülle aus, wie du <b>Walu später nutzen</b> wirst. (Paketkosten fallen erst ab dem 01.04.24 an) 💼</li><br/>
    <li>Bestätige, dass du <b>Walu-Anbieter</b> werden möchtest. (Es fallen einmalig 15€ Anmeldegebühren an) ✅</li><br/>
    </ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

    </div>) : (<div></div>)}
    

    {currentStep === 0 || currentStep === 4 ? (<div>
        <div className="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>   


        <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Was passiert, nachdem ich Anbieter geworden bin?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Du hast das Formular ausgefüllt? So geht es weiter:</div>
                
                    <ul className="list-decimal pl-8 text-base px-2">
                    <li><b>Anfang April</b>: Wir melden uns bei dir. 📆</li><br/>
                    <li><b>Mitte April</b>: Du kannst dir die Walu App runterladen und deine Kurse online stellen.📲</li><br/>
                    <li><b>Ende April</b>: Lade deine bisherigen Kursmitglieder zu deinem Walu Kurs ein.🤝</li><br/>
                    <li><b>Anfang Mai</b>: Unsere Reise beginnt 🚀. Du bist nun offiziell Walu Kursanbieter.🎉</li><br/>
                    </ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

    </div>) : (<div></div>)}

      </div>
    );
}

export default FormularMobile;