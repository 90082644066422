function WiesoPaket() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was ist ein Walu Paket?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Die Walu Pakete haben unterschiedliche <b>Vorteile</b>:<br/><br/>

                    <ul class="list-disc pl-8">
                    <li>Als <b>Walu Kursanbieter</b> nutzt du die App immer mit einem Paket.📱</li><br/>
                    <li>Jeder Anbieter von Kursen braucht <b>nur ein Paket</b>.💼</li><br/>
                    <li>Das Paket wird von dir <b>bei Kontoerstellung</b> in der App abgeschlossen. 📦</li><br/>
                    <li><b>Zahlungen</b> fallen monatlich an.💵</li><br/>
                    <li>Es kann <b>jederzeit</b> gekündigt werden. 🔄</li><br/>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WiesoPaket;