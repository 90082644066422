function WasGeldZurück() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was ist die Geld-zurück-Garantie?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/geld.jpg"
                    alt="Bonusse"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    <b>Geld zurück</b>? Aber wie?<br/><br/>

                    <ul class="list-decimal pl-8">
                    <li>Die Walu App hat dir <b>nach 4 Monaten</b> nicht gefallen? 😕</li><br/>
                    <li>Sag dem Walu Team über E-Mail, Telefon oder Walu-Chat, dass dir die App <b>nicht gefällt</b>. 💬</li><br/>
                    <li>Du bekommst dein Geld der letzten 4 Monate <b>komplett erstattet</b>. 💰</li><br/>
                    </ul>
                    <b>Anmeldegebühren</b> sind einmalig und werden <b>nicht</b> erstattet! 🚫
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WasGeldZurück;