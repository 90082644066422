import { Link  } from 'react-router-dom';

function Bonus1Mobile() {
  const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    return (
        <div 
        className="flex flex-col items-center justify-center">
            <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            <div className="text-center pt-2 pl-4 pr-4">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Bonus 1: Das "Sprinter Paket"</span>
                    </div>
                </span>
            </div>
            <div className="text-center pl-4 pr-4">
                <span className="text-sm lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">
                    Erhalte exklusive Tutorials, Anleitungen und Checklisten, um deinen Kurs <b>noch erfolgreicher zu machen</b>.
                    </span>
                    </div>
                </span>
            </div>



            <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
            Was ist im Walu Sprinter Paket enthalten?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Das <b>Sprinter Paket</b> besteht aus:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Einer Checkliste, die dir hilft, den <b>besten Preis</b> für deinen Kurs festzusetzen. 📋</li><br/>
<li>Einem Tutorial, das dir sagt, wie du die <b>Qualität</b> deines Kurses mehr in die Öffentlichkeit bringst. 🌐</li><br/>
<li>Einem Guide, wie du deinen Kurs <b>besser bewirbst</b>. 📣</li><br/>
<li>Einem Leitfaden, der dir dabei hilft, die <b>Mundpropaganda</b> deines Kurses anzukurbeln. 🗣️</li><br/>
</ul>

                </div>
            </div>
      </p>
    </div>
  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Welchen Vorteil habe ich, wenn ich schon jetzt Walu Anbieter werde?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Sei einer der <b>ersten 1000 Anbieter</b> und erhalte:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Bonus: 🏃‍♂️ Das "<b>Walu Sprinter Paket</b>". </li><br/>
                    <li>Bonus: 💰 "<b>Dreimonatige Geld-zurück-Garantie</b>". <Link to="/bonus2"><span className="text-blue-600">(mehr Infos)</span></Link></li><br/>
                    <li>Bonus: 🌟 Dein Paket als "<b>Plus Paket</b>", mit noch mehr Vorteilen. <Link to="/bonus3"><span className="text-blue-600">(mehr Infos)</span></Link></li><br/>
                    </ul>
                    <Link to="/contact">
        <div className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            >Jetzt Bonuscode sichern
            </div>
        </div>
    </Link>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Wie werde ich schon jetzt Walu Kursanbieter und erhalte gleichzeitig alle Bonusse?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <ul class="list-disc pl-8 text-base px-2">
                    <li>🌐 Gehe zum "<Link to="/signup"><span className="text-blue-600">Anbieter werden Formular</span></Link>".</li><br/>
                    <li>📝 Fülle die "<b>Allgemeinen Informationen</b>" zu dir als Anbieter aus.</li><br/>
                    <li>💼 Fülle aus, wie du <b>Walu später nutzen</b> wirst. (Paketkosten fallen erst ab dem 01.04.24 an)</li><br/>
                    <li>✅ Bestätige, dass du <b>Walu-Anbieter</b> werden möchtest. (Es fallen einmalig 15€ Anmeldegebühr an)</li><br/>
                    </ul>
                   
                    <Link to="/signup">
        <div className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            >Jetzt Anbieter werden
            </div>
        </div>
    </Link>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>


      </div>
    );
}

export default Bonus1Mobile;