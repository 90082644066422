import WasSprinterPaket from './sections/WasSprinterPaket';
import WelcheBonusseNoch1 from './sections/WelcheBonusseNoch1';
import WieKursanbieterMitBonusse from './sections/WieKursanbieterMitBonusse';

function Bonus1() {
    return (
        <div className="p-6">
            <div className="flex justify-start">
            <div
            className="flex flex-col justify-start"
            style={{
                paddingLeft: '3%',
            }}
            >
                <div className="flex-none text-black font-roboto text-left pt-8">
                    <span
                     className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Bonus 1: <br></br>Das "Sprinter Paket"
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                    <div className="w-full sm:w-[15rem] md:w-[25rem] lg:w-[35rem] xl:w-[45rem] 2xl:w-[55rem]">
                        <span
                            className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Erhalte exklusive Tutorials, Anleitungen und Checklisten, um deinen Kurs <b>noch erfolgreicher zu machen</b>.
                        </span>
                    </div>
                </div>
            </div>
            <div style={{
                position: 'absolute',
                left: '65%',
                top: '30%',
                transform: 'translateX(-50%)',
            }}>
                <div className="text-white font-bold text-center sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl border-4 border-white rounded-3xl transform rotate-6 inline-block pt-2 pb-2 pl-6 pr-6">
                <span className="line-through"> Einmalig 20€</span><br></br>GRATIS DAZU
                </div>
            </div>
            </div>
           

            <div className="pt-12"></div>

           <WasSprinterPaket></WasSprinterPaket>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WelcheBonusseNoch1></WelcheBonusseNoch1>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WieKursanbieterMitBonusse></WieKursanbieterMitBonusse>


        </div>
    );
}

export default Bonus1;