function PlatinPlusPaket() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Welche Vorteile hat das Platin Plus Paket?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/platinplus.png"
                    alt="Bonusse"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Das Platin Paket, jedoch <b>mit Plus</b>? Du erhältst:<br/><br/>

                    <ul class="list-decimal pl-8">
                    <li>Dein Paket zum <b>halben Preis</b>, <span className="underline">für immer</span>. 💲
                        (49,99€, anstatt 99,99€, monatlich)</li><br/>
                        <li>Immer noch <b>jederzeit kündbar</b>. 🔄</li><br/>
                    <li><b>Exklusiver Zugang</b> zu neuen Tools. 🔧</li><br/>
                    <li>Priorisierte <b>Betreuung</b> 🚀</li><br/>
                    <li>Zukünftige <b>Preisnachlässe</b> 💰</li><br/>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlatinPlusPaket;