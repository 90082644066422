import React, { useState, useEffect } from 'react';
import { Link  } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WiesoKursanbieter from "./sections/WiesoKursanbieter"
import WieKursanbieterOnPage from "./sections/WieKursanbieterOnPage"
import WasNachdemAnbieter from "./sections/WasNachdemAnbieter"

function Formular() {
    const steps = ['Allgemeine Informationen', 'Wie wirst du Walu nutzen?', 'Checkout', 'Zahlung'];
    const [currentStep, setCurrentStep] = useState(0);
      
    const [showEmailError, setShowEmailError] = useState(false);
    const nextStep = () => {
        setShowEmailError(!isEmailValid)
        if (!isButtonDisabled && isEmailValid) {
            setCurrentStep(currentStep + 1);
        }
    };

    const previousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [name, setName] = useState('');
    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const [nameFocused, setNameFocused] = useState(false);
    const handleNameFocus = () => {
        setNameFocused(true);
      };
    const handleNameBlur = () => {
        setNameFocused(false);
    };

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const validateEmail = (email) => {
        const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
        return isValid;
      };
    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(validateEmail(emailValue));
    };
    const [emailFocused, setEmailFocused] = useState(false);
    const handleEmailFocus = () => {
        setEmailFocused(true);
      };
    const handleEmailBlur = () => {
        setEmailFocused(false);
    };

    const [phone, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const [phoneFocused, setPhoneFocused] = useState(false);
    const handlePhoneFocus = () => {
        setPhoneFocused(true);
      };
    const handlePhoneBlur = () => {
        setPhoneFocused(false);
    };

    useEffect(() => {
        const isDisabled = !(name.trim() && email.trim());
        setIsButtonDisabled(isDisabled);
    }, [name, email]);

    const [paket, setPaket] = useState('');
    const [isStep2ButtonDisabled, setIsStep2ButtonDisabled] = useState(true);
    
      const handlePaketChange = (value) => {
        setPaket(value);
      };

      const nextStep2 = () => {
        if (!isStep2ButtonDisabled) {
            setCurrentStep(currentStep + 1);
        }
    };

    useEffect(() => {
        if (paket) {
        setIsStep2ButtonDisabled(false);
        } else {
        setIsStep2ButtonDisabled(true);
        }
    }, [paket]);


    const [accept1, setAccept1] = useState(false);
    const [accept2, setAccept2] = useState(false);
    const [accept3, setAccept3] = useState(false);

    const isAllChecked = accept1 && accept2 && accept3;

    const handleAccept1Change = (isChecked) => {
        setAccept1(isChecked);
      };
    
    const handleAccept2Change = (isChecked) => {
        setAccept2(isChecked);
    };

    const handleAccept3Change = (isChecked) => {
        setAccept3(isChecked);
    };

    const [code, setCode] = useState("");
    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };
    const [codeFocused, setCodeFocused] = useState(false);
    const handleCodeFocus = () => {
        setCodeFocused(true);
      };
    const handleCodeBlur = () => {
        setCodeFocused(false);
    };
    const [codeError, setCodeError] = useState("");
    const [serverError, setServerError] = useState("");
    const [isCodeValid, setIsCodeValid] = useState(false);

    const handleValidateCode = async () => {
        try {
            const response = await fetch("https://aws.mywalu.com/kuusco/api/websitemembers/code", {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                code: code.trim(),
              }),
            });
      
            if (response.ok) {
                setCodeError("")
                setIsCodeValid(true)
            } else {
                setCodeError('Dieser Code ist ungültig.');
            }
        } catch (error) {
            setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            console.error('An error occurred', error);
        }      
    };

    const goToLastStep = async () => {
        if (isAllChecked) {
            setServerError("")
            setCurrentStep(currentStep + 1);
        }        
    };

    // creates a paypal order
    const createOrder = async (_data, _actions) => {
        try {
            const response = await fetch("https://aws.mywalu.com/kuusco/api/websitemembers/paypal/create_order", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.ok) {
                const orderID = await response.text(); 
                return orderID;
            } else {
                setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            }
        } catch (error) {
            setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            console.error('An error occurred', error);
        }
    };

    // check Approval
    const onApprove = async (data, _actions) => {
        try {
            let captureOrderUrl;
            if (code.trim() !== "" && isCodeValid) {
                captureOrderUrl = `https://aws.mywalu.com/kuusco/api/websitemembers/paypal/capture_order/${data.orderID}?code=${code.trim()}`;
            } else {
                captureOrderUrl = `https://aws.mywalu.com/kuusco/api/websitemembers/paypal/capture_order/${data.orderID}`;
            }

            const response = await fetch(captureOrderUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name.trim(),
                    telefonnummer: phone ? phone : null,
                    email: email.trim(),
                    paket: paket,
                    plattform: "A",
                }),
            });
    
            if (response.ok) {
                setCurrentStep(currentStep + 1);
            } else {
                setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            }
        } catch (error) {
            setServerError('Ups, etwas ist schief gelaufen. Versuche es später erneut.');
            console.error('An error occurred', error);
        }
    };

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

    const initialOptions = {
        clientId: CLIENT_ID,
        currency: "EUR",
        intent: "capture",
    };
    

    // End Paypal

    return (
        <div className="p-6">
            <div
            className="flex flex-col justify-start"
            style={{
                paddingBottom: '5%',
                paddingLeft: '3%',
                marginTop: '-3vh', // Move the content up
            }}
            >
                <div className="flex-none text-black font-roboto text-left pt-8">
                    <span
                     className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Walu Kursanbieter <br />
                    werden
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                <div className="w-full sm:w-[10rem] md:w-[20rem] lg:w-[30rem] xl:w-[40rem] 2xl:w-[50rem]">
                        <span
                             className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Um bereits jetzt Walu Anbieter zu werden fülle das Formular aus. Wir von Walu freuen uns, mit dir zusammenarbeiten zu dürfen!
                        </span>
                    </div>
                </div>
            </div>

<div className="p-6 px-24 pb-8" style={{marginTop: '-8vh'}}>
  <div className="relative h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>

  <div className="py-6 pl-12">
        <ol className="flex items-center text-center text-gray-500">
        {steps.map((step, index) => (
            <div key={index} className={`flex items-center${index === steps.length - 1 ? '' : ' after:content-[""] after:h-1 after:border-b after:border-gray-400 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700'} ${index <= currentStep ? 'text-blue-600 dark:text-blue-500' : ''}`}>
            <span className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-roboto leading-16 text-left" style={{ whiteSpace: 'nowrap' }}>
            {index === currentStep ? (<span>{index + 1}. {step}</span>) : (<span className={`inline-flex text-white items-center justify-center h-14 w-14 ${index <= currentStep ? 'bg-blue-500' : 'bg-gray-500'} rounded-full`}>{index + 1}.</span>)}
            </span>
            
            </div>
        ))}
        </ol>
    </div>
   

    <div className="text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-8">
        {(() => {
          switch (currentStep) {
            case 0:
                return (
                    <div>
                        <div className="flex flex-row items-center justify-evenly">
                            <div className="relative w-1/3 pl-4 pr-4">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                    ${nameFocused ? 'border-blue-500' : ''}`}
                                    placeholder=" "
                                    value={name}
                                    onChange={handleNameChange}
                                    onFocus={handleNameFocus}
                                    onBlur={handleNameBlur}
                                    maxLength={500}
                                />
                                <label
                                    htmlFor="name"
                                    className={`absolute left-6 top-[4px] bg-white px-2 pb-1 text-${
                                    nameFocused ? 'blue-500' : 'gray-500'
                                    } text-base transition-all duration-300`}
                                >
                                    Vor- und Nachname
                                </label>
                                <div
                                    className="pt-2 pl-2 gray-500 text-sm"
                                    style={{ height: '100px' }}
                                >
                                </div>
                            </div>

                            <div className="relative w-1/3 pl-4 pr-4">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none transition duration-300 
                                    ${showEmailError ? 'border-red-500' : emailFocused ? 'focus:border-blue-500 border-blue-500' : 'focus:border-blue-500'}`}
                                    placeholder=" "
                                    value={email}
                                    onChange={handleEmailChange}
                                    onFocus={handleEmailFocus}
                                    onBlur={handleEmailBlur}
                                    maxLength={200}
                                />
                                <label
                                    htmlFor="email"
                                    className={`absolute left-6 top-[4px] bg-white px-2 pb-1 text-${
                                    showEmailError ? 'red-500' : emailFocused ? 'blue-500' : 'gray-500'
                                    } text-base transition-all duration-300`}
                                >
                                    E-Mail
                                </label>
                                <div
                                    className="pt-2 pl-2 gray-500 text-base"
                                    style={{ height: '100px' }}
                                >
                                  
                                </div>
                            </div>

                            <div className="relative w-1/3 pl-4 pr-4">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                    ${phoneFocused ? 'border-blue-500' : ''}`}
                                    placeholder=" "
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    onFocus={handlePhoneFocus}
                                    onBlur={handlePhoneBlur}
                                    maxLength={30}
                                />
                                <label
                                    htmlFor="phone"
                                    className={`absolute left-6 top-[4px] bg-white px-2 pb-1 text-${
                                    phoneFocused ? 'blue-500' : 'gray-500'
                                    } text-base transition-all duration-300`}
                                >
                                    Handynummer (optional)
                                </label>
                                <div
                                    className="pt-2 pl-2 gray-500 text-base"
                                    style={{ height: '100px' }}
                                >
                                    Wird nur verwendet um dir zu sagen, wenn die App für dich zur Verfügung steht.
                                </div>
                            </div>
                        </div>

                        <div className="inline-block py-8 px-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {showEmailError ? (<div className="text-xl leading-9 font-roboto px-8 text-red-600">
                            Die E-Mail ist nicht korrekt.
                            </div>) : (<div></div>)}
                            <div
                            className={`text-2xl leading-9 font-roboto px-8 py-1 rounded-xl text-white ${
                                isButtonDisabled ? 'bg-gray-300' : 'bg-blue-500 hover:font-semibold'
                              }`}
                            onClick={nextStep}
                            style={{ cursor: 'pointer' }}
                            disabled={isButtonDisabled}
                            >Weiter
                            </div>
                        </div>
                    </div>
                   
                );
            case 1:
                return (
                    <div>

                    <div className="text-4xl pt-8 leading-9 font-roboto font-normal text-black">
                       Welches Walu Paket wirst du zum 01.04.24 kostenpflichtig abschließen?
                    </div>

                    <div className="pt-2 pl-8">
                        <div>
                            <label className="inline-flex items-center">
                            <input type="radio" className="form-radio h-6 w-6 text-blue-600" name="paket" value="S" checked={paket === 'S'} onChange={() => handlePaketChange('S')}/>
                            <span className="ml-4 text-2xl"><b>Silber Paket</b> (9,99€ monatlich, jederzeit kündbar) <br/>
                            = einen Kurs online schalten <Link to="/pakete" target="_blank"><span className="text-blue-600">und vieles mehr <FontAwesomeIcon icon={faUpRightFromSquare} className="text-lg pb-1" /></span></Link>.
                            </span>
                            </label>
                        </div>
                        <div>
                            <label className="inline-flex items-center">
                            <input type="radio" className="form-radio h-6 w-6 text-blue-600" name="paket" value="G" checked={paket === 'G'} onChange={() => handlePaketChange('G')}/>
                            <span className="ml-4 text-2xl"><b>Gold Paket</b> (24,99€ monatlich, jederzeit kündbar) <br/>
                            = unbegrenzt Kurse online schalten <Link to="/pakete" target="_blank"><span className="text-blue-600">und vieles mehr <FontAwesomeIcon icon={faUpRightFromSquare} className="text-lg pb-1" /></span></Link>.
                            </span>
                            </label>
                        </div>
                        <div>
                            <label className="inline-flex items-center">
                            <input type="radio" className="form-radio h-6 w-6 text-blue-600" name="paket" value="P" checked={paket === 'P'} onChange={() => handlePaketChange('P')}/>
                            <span className="ml-4 text-2xl"><b>Platin Paket</b> (99,99€ monatlich, jederzeit kündbar) <br/>
                            = unbegrenzt Kurse online schalten, Kursmanager ernennen, <Link to="/pakete" target="_blank"><span className="text-blue-600">und vieles mehr <FontAwesomeIcon icon={faUpRightFromSquare} className="text-lg pb-1" /></span></Link>.
                            </span>
                            </label>
                        </div>
                    </div>

                    <div className="inline-block pt-12 px-8" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="flex items-center">
                            <div
                            className={`text-2xl leading-9 font-roboto px-6 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            onClick={previousStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Zurück
                            </div>
                        </div>
                        <div>
                            <div
                            className={`text-2xl leading-9 font-roboto px-8 py-1 rounded-xl text-white ${isStep2ButtonDisabled ? 'bg-gray-300' : 'bg-blue-500 hover:font-semibold'}`}
                            onClick={nextStep2}
                            style={{ cursor: 'pointer' }}
                            >
                            Weiter
                            </div>
                        </div>
                    </div>

                    </div>
                );
            case 2:
                return (
                    <div>
                        
                    <div className="text-4xl leading-9 font-roboto font-normal text-black">
                       1. Um Walu Anbieter zu werden musst du einmalig 15€ Anmeldegebühren zahlen. Die monatlichen kosten 
                        für dein Paket werden erst zum 01.04.24 fällig, nachdem du dich in der Walu App registriert hast.
                    </div>

                    <div className="pt-2 pl-8">
                        <div>
                            <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-6 w-6 text-blue-600" name="signup" value="accept1" checked={accept1} onChange={(e) => handleAccept1Change(e.target.checked)}/>
                            <span className="ml-4 text-2xl">Ich bin damit einverstanden, einmalig 15€ Anmeldegebühren zu zahlen.</span>
                            </label>
                        </div>
                    </div>

                    <div className="text-4xl pt-8 leading-9 font-roboto font-normal text-black">
                       2. Um Walu Anbieter zu werden musst du den Vertragsbedingungen zustimmen.
                    </div>

                    <div className="pt-2 pl-8">
                        <div>
                            <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-6 w-6 text-blue-600" name="vertrag" value="accept2" checked={accept2} onChange={(e) => handleAccept2Change(e.target.checked)}/>
                            <span className="ml-4 text-2xl">
                            Ja, ich habe die <Link to="/agb" target="_blank"><span className="text-blue-600">Walu AGB <FontAwesomeIcon icon={faUpRightFromSquare} className="text-lg pb-1" /></span></Link> gelesen und bin damit einverstanden.
                            </span>
                            </label>
                        </div>
                        <div>
                            <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-6 w-6 text-blue-600" name="vertrag" value="accept3" checked={accept3} onChange={(e) => handleAccept3Change(e.target.checked)}/>
                            <span className="ml-4 text-2xl">
                            Ja, ich habe den <Link to="/nutzungsvertrag" target="_blank"><span className="text-blue-600">Nutzervertrag für Walu Kursanbieter <FontAwesomeIcon icon={faUpRightFromSquare} className="text-lg pb-1" /></span></Link> gelesen und bin damit einverstanden.
                            </span>
                            </label>
                        </div>
                    </div>

                    <div className="text-4xl pt-8 pb-4 leading-9 font-roboto font-normal text-black">
                       3. Falls du einen Walu Bonuscode hast, kannst du ihn hier einlösen.
                    </div>

                    <div className="pt-2 pl-8 flex items-baseline">
                        <div className="relative w-1/3 pr-8">
                                <input
                                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                    ${codeFocused ? 'border-blue-500' : ''}`}
                                    placeholder=" "
                                    value={code}
                                    onChange={handleCodeChange}
                                    onFocus={handleCodeFocus}
                                    onBlur={handleCodeBlur}
                                    disabled={isCodeValid}
                                />
                                <label
                                    htmlFor="code"
                                    className={`absolute left-4 top-[4px] bg-white px-2 pb-1 text-${
                                    codeFocused ? 'blue-500' : 'gray-500'
                                    } text-base transition-all duration-300`}
                                >
                                    Bonuscode
                                </label>
                        </div>
                        <div>
                            {isCodeValid ? (
                                <div
                                    className={`text-2xl leading-9 font-roboto px-8 py-2 rounded-xl text-white bg-green-500`}
                                    >
                                    Eingelöst
                                </div>
                            ) : (
                                <div
                                    className={`text-2xl leading-9 font-roboto px-8 py-2 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                                    onClick={handleValidateCode}
                                    style={{ cursor: 'pointer' }}
                                    >
                                    Einlösen
                                </div>
                            )}
                            
                        </div>
                        {codeError !== "" ? (
                        <div>
                            <div className="text-xl leading-9 pl-8 font-roboto font-normal text-red-600">
                                {codeError}
                            </div>
                        </div>
                        ) : (<div></div>)}
                        
                    </div>

                    <div className="inline-block pt-12 px-8" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="flex items-center">
                            <div
                            className={`text-2xl leading-9 font-roboto px-6 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            onClick={previousStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Zurück
                            </div>
                        </div>
                        <div className="flex items-center">
                            {serverError !== "" ? (
                            <div>
                                <div className="text-xl leading-9 pr-8 font-roboto font-normal text-red-600">
                                    {serverError}
                                </div>
                            </div>
                            ) : (<div></div>)}
                            <div
                            className={`text-2xl leading-9 font-roboto px-8 py-1 rounded-xl text-white ${isAllChecked ? 'bg-blue-500 hover:font-semibold' : 'bg-gray-300'}`}
                            onClick={goToLastStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Weiter
                            </div>
                        </div>
                    </div>

                    </div>
                );
            case 3:
                return (
                    <div>

                    <div className="text-4xl pt-8 leading-9 font-roboto font-normal text-black pb-8">
                       Wähle aus, wie du die Anmeldegebühren bezahlen möchtest.
                    </div>

                    <div className="pt-2 pl-8">
                        <PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons
                                style={{ layout: "vertical" }}
                                onApprove={onApprove}
                                createOrder={createOrder}
                            />
                        </PayPalScriptProvider>
                    </div>

                    <div className="inline-block pt-12 px-8" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="flex items-center">
                            <div
                            className={`text-2xl leading-9 font-roboto px-6 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            onClick={previousStep}
                            style={{ cursor: 'pointer' }}
                            >
                            Zurück
                            </div>
                        </div>
                        <div className="flex items-center">
                            {serverError !== "" ? (
                            <div>
                                <div className="text-2xl leading-9 pr-8 font-roboto font-normal text-red-600">
                                    {serverError}
                                </div>
                            </div>
                            ) : (<div></div>)}
                        </div>
                    </div>
                    </div>
                );
            case 4:
                return (
                    <div className="flex items-center">
                    <div className="w-14 h-14 flex items-center justify-center bg-green-500 rounded-full text-white">
                      <svg
                        className="w-14 h-14"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div className="text-black leading-9 font-roboto font-normal text-3xl font-semibold px-16">
                    Vielen Dank für deine Zahlung und dein Vertrauen in uns. Wir von Walu werden alles dafür geben, dass du deine Ziele als Walu Kursanbieter erreichst! <br></br>
                    Wir haben dir eine E-Mail mit weiteren Anweisungen geschickt.
                    </div>
                  </div>
                );
            default:
              return <p></p>;
          }
        })()}
      </p>
    </div>

  </div>
</div>
    {currentStep === 0 ? (<div>
        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WiesoKursanbieter></WiesoKursanbieter>
   
    <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WieKursanbieterOnPage></WieKursanbieterOnPage>

    </div>) : (<div></div>)}
    

    {currentStep === 0 || currentStep === 4 ? (<div>
        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>   

    <WasNachdemAnbieter></WasNachdemAnbieter>

    </div>) : (<div></div>)}
   
      
        </div>
    );
}

export default Formular;