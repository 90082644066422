import React, { useState } from 'react'

function DeleteAccount() {

    const [benutzername, setBenutzername] = useState('');
    const [passwort, setPasswort] = useState('');
    const [isBenutzernameFocused, setIsBenutzernameFocused] = useState(false);
    const [isPasswortFocused, setIsPasswortFocused] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [error, setError] = useState('');

    const handleDeleteAccount = async () => {
        try {
            const response = await fetch('https://aws.mywalu.com/kuusco/api/users/me/website', {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                username: benutzername,
                passwort: passwort,
              }),
            });
      
            if (response.ok) {
                setError('');
                setIsDeleted(true);
            } else {
                setError('Die angegebenen Daten sind fehlerhaft.');
            }
        } catch (error) {
            setError('Ups, etwas ist schief gelaufen.');
        }
    };

    const handleBenutzernameChange = (e) => {
        setBenutzername(e.target.value);
    };

    const handleFocus = () => {
        setIsBenutzernameFocused(true);
      };
    
    const handleBlur = () => {
        setIsBenutzernameFocused(false);
    };

    const handlePasswordChange = (e) => {
        setPasswort(e.target.value);
    };

    const handlePasswordFocus = () => {
        setIsPasswortFocused(true);
      };
    
    const handlePasswordBlur = () => {
        setIsPasswortFocused(false);
    };

    return (
        <div className="flex flex-row flex-grow justify-between" style= {{  marginTop: '-10vh' }}>
            <div
            className="flex flex-col"
            style={{
                padding: '5%',
                paddingLeft: '3%',
            }}
            >
                <div className="text-black font-roboto text-left pt-8">
                    <span
                     className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Walu-Account löschen
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                <div className="w-full sm:w-[15rem] md:w-[25rem] lg:w-[35rem] xl:w-[45rem] 2xl:w-[50rem]">
                        <span
                            className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            
                            Bei der Löschung deines Accounts werden deine Profilinformationen sowie von dir erstellte Kurse und Beiträge gelöscht.
                            <br></br>
                            <br></br>
                            Gib deinen Walu-Benutzernamen und Passwort ein, um deinen Account zu löschen.
                        </span>
                    </div>
                </div>
                <div className={`relative mt-8 w-4/5`}>
                            <input
                                className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                ${isBenutzernameFocused ? 'border-blue-500' : ''}`}
                                placeholder=" "
                                value={benutzername}
                                onChange={handleBenutzernameChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                            <label
                                htmlFor="password"
                                className={`absolute left-4 top-[-12px] bg-white px-2 pb-1 text-${
                                isBenutzernameFocused ? 'blue-500' : 'gray-500'
                                } text-md transition-all duration-300`}
                            >
                                Benutzername / E-Mail
                            </label>
                        </div>
                        <div className={`relative mt-8 w-4/5 mb-2`}>
                            <input
                                type="password"
                                className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                ${isPasswortFocused ? 'border-blue-500' : ''}`}
                                placeholder=" "
                                value={passwort}
                                onChange={handlePasswordChange}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                            />
                            <label
                                htmlFor="password"
                                className={`absolute left-4 top-[-12px] bg-white px-2 pb-1 text-${
                                isPasswortFocused ? 'blue-500' : 'gray-500'
                                } text-md transition-all duration-300`}
                            >
                                Passwort
                            </label>
                        </div>

                        <p>Achtung, dies kann nicht rückgängig gemacht werden!</p>
                        {error && (
                            <p className="text-red-500 mb-2 text-left">{error.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}</p>
                        )}
                        
                        {isDeleted ? (<div className="text-xl text-green-500 pt-4 pl-4">Account erfolgreich gelöscht!</div>) : (<div className="inline-block py-2 px-4 pt-10">
                        <button
                        className="border border-gray-400 rounded-xl px-4 py-2 text-gray-700 hover:border-blue-500 hover:text-blue-500 hover:bg-gray-100 transition duration-300"
                        onClick={handleDeleteAccount}
                        >
                        Account löschen
                        </button>
                        </div>)}
            </div>

            </div>
    );
}

export default DeleteAccount;