import { Link  } from 'react-router-dom';

function WiesoKursanbieter() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Wieso soll ich meine Kurse über die Walu App anbieten?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Du als <b>Kursanbieter</b> auf Walu hast folgende Vorteile:<br/><br/>
                    <ul class="list-disc pl-8">
                        <li>📱 Neue <b>Kursinteressierte</b> können dich ganz einfach über die Walu App finden und kontaktieren.</li><br/>
                        <li>🕒 Alle <b>Informationen</b> zu deinem Kurs, wie z.B. Kurszeiten, eine eigene Kurs-Chatgruppe uvm., werden digitalisiert.</li><br/>
                        <li>📸 Deine <b>Kursmitglieder</b> können coole Fotos und Videos in deinen Kurs posten.</li><br/>
                        </ul>
                        <b>Noch unsicher?</b> Finde in der Online-Präsentation heraus, ob Walu das richtige für dich und deinen Kurs ist.
                        
                        <Link to="/presentation">
                            <div className="inline-block py-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div
                                className={`text-xl leading-9 font-roboto px-8 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                                >Für Präsentation anmelden
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WiesoKursanbieter;