import { ButtonsContainer, AppStoreButton, GooglePlayButton } from "react-mobile-app-button";

function KursSuchen() {
    const imageFilmURL = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"
    const androidUrl = "https://play.google.com/store/apps/details?id=com.walu.frontend";
    const iOSUrl = "https://apps.apple.com/de/app/walu/id6474895726";

    return (
        <div className="p-6">
            <div className="flex flex-row flex-grow justify-between" style= {{  marginTop: '-10vh' }}>
            <div
            className="flex flex-col"
            style={{
                padding: '5%',
                paddingLeft: '3%',
            }}
            >
                <div className="text-black font-roboto text-left pt-8">
                    <span
                     className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Deutschlandweit <br></br> 
                    Kurse finden
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                <div className="w-full sm:w-[15rem] md:w-[25rem] lg:w-[35rem] xl:w-[45rem] 2xl:w-[55rem]">
                        <span
                            className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Durch Walu hast du <b>deine Kurse</b> und somit <b>deine Leidenschaft</b> in einer App. Mache es potenziellen <b>neuen Kursbesuchern einfacher</b>, dich und deine Kurse 
                            zu finden, <b>nutze Walu</b>.
                            <br />
                            <br />
                            Lade dir die App herunter.
                        </span>
                    </div>
                </div>
                <div className="relative pt-4">
                    <div className="absolute">
                    <ButtonsContainer>
                            <GooglePlayButton
                            title="JETZT BEI"
                            url={androidUrl}
                            theme="dark"
                            className="custom-style"
                            height={80}
                            width={200}
                            />
                            <AppStoreButton
                            title="Laden im"
                            url={iOSUrl}
                            theme="dark"
                            className="custom-style"
                            height={80}
                            width={200}
                            />
                        </ButtonsContainer>
                    </div>
                    </div>
            </div>
            <div className="w-1/2 aspect-w-16 aspect-h-8 px-8 pt-12">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={imageFilmURL}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>

            </div>
           
           <div className="pt-32"></div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24 pb-12">
            Durch die Kurssuche über die Walu App erreichst du mit deiner Leidenschaft noch mehr Leute.
            </div>

            <div className="px-24">
      <div className="flex flex-row">
        {/* Image 1 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/topleft.png"
            alt="Walu Search"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Durch die Walu App können sich <b>Kurssuchende</b> ein <b>gutes Bild</b> von deinem Kurs machen. 📱</p>
        </div>

        {/* Image 2 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/topcenter.png"
            alt="Walu Categories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Durch die <b>Umgebungssuche</b> werden deine Kurse Leuten <b>in direkter Nähe</b> angezeigt. 👥</p>
        </div>

        {/* Image 3 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/topright.png"
            alt="Walu Subcategories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Kurssuchende können sich über die <b>verschiedenen Kategorien</b> inspirieren lassen und so auch <b>auf deinen Kurs stoßen</b>. 🧑‍🎨</p>
        </div>
      </div>
    </div>

    <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

    <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24 pb-12 pt-8">
    Walu dient lediglich als Plattform, allein du hast die volle Kontrolle über deinen Kurs.
            </div>

            <div className="px-24">
      <div className="flex flex-row">
        {/* Image 1 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bottomleft.png"
            alt="Walu Search"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Du entscheidest welche <b>Fotos und Videos</b>, von deinen Kursmitglieder, in <b>deinem Kurs</b> sichtbar sind. 📸</p>
        </div>

        {/* Image 2 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bottomcenter.png"
            alt="Walu Categories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Du entscheidest welche <b>Informationen</b> du über <b>deinen Kurs</b> veröffentlichst. ✅</p>
        </div>

        {/* Image 3 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bottomright.png"
            alt="Walu Subcategories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Du entscheidest <b>wann, wo und wie</b> dein Kurs stattfindet. 📍</p>
        </div>
      </div>
    </div>
                
        </div>
    );
}

export default KursSuchen;