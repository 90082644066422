import React, { useState } from 'react'

function DeleteAccountMobile() {

    const [benutzername, setBenutzername] = useState('');
    const [passwort, setPasswort] = useState('');
    const [isBenutzernameFocused, setIsBenutzernameFocused] = useState(false);
    const [isPasswortFocused, setIsPasswortFocused] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [error, setError] = useState('');

    const handleDeleteAccount = async () => {
        try {
            const response = await fetch('https://aws.mywalu.com/kuusco/api/users/me/website', {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                username: benutzername,
                passwort: passwort,
              }),
            });
      
            if (response.ok) {
                setError('');
                setIsDeleted(true);
            } else {
                setError('Die angegebenen Daten sind fehlerhaft.');
            }
        } catch (error) {
            setError('Ups, etwas ist schief gelaufen.');
        }
    };

    const handleBenutzernameChange = (e) => {
        setBenutzername(e.target.value);
    };

    const handleFocus = () => {
        setIsBenutzernameFocused(true);
      };
    
    const handleBlur = () => {
        setIsBenutzernameFocused(false);
    };

    const handlePasswordChange = (e) => {
        setPasswort(e.target.value);
    };

    const handlePasswordFocus = () => {
        setIsPasswortFocused(true);
      };
    
    const handlePasswordBlur = () => {
        setIsPasswortFocused(false);
    };

    return (
        <div className="flex flex-col items-center justify-center"> 
         <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            <div className="text-center pt-2 pl-4 pr-4">
                <span className="text-2xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Walu-Account löschen</span>
                    </div>
                </span>
            </div>
            <div className="text-center pl-4 pr-4 pb-4">
                <span className="text-lg leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">
                    Bei der Löschung deines Accounts werden deine Profilinformationen sowie von dir erstellte Kurse und Beiträge gelöscht. <br></br> <br></br>
                    Gib deinen Walu-Benutzernamen und Passwort ein, um deinen Account zu löschen.
                    </span>
                    </div>
                </span>
            </div>

            <div className={`relative w-full pl-8 pr-8`}>
            <input
                className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 bg-white
                ${isBenutzernameFocused ? 'border-blue-500' : ''}`}
                placeholder=" "
                value={benutzername}
                onChange={handleBenutzernameChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <label
                htmlFor="password"
                className={`absolute bg-white left-12 top-[-12px] px-2 pb-1 text-${
                isBenutzernameFocused ? 'blue-500' : 'gray-500'
                } text-md transition-all duration-300`}
            >
                Benutzername / E-Mail
            </label>
            </div>
            <div className={`relative w-full mt-8 mb-2 pl-8 pr-8`}>
                <input
                    type="password"
                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 bg-white
                    ${isPasswortFocused ? 'border-blue-500' : ''}`}
                    placeholder=" "
                    value={passwort}
                    onChange={handlePasswordChange}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                />
                <label
                    htmlFor="password"
                    className={`absolute bg-white left-12 top-[-12px] px-2 pb-1 text-${
                    isPasswortFocused ? 'blue-500' : 'gray-500'
                    } text-md transition-all duration-300`}
                >
                    Passwort
                </label>
            </div>
            <p className="px-8 text-sm">Achtung, dies kann nicht rückgängig gemacht werden!</p>
            {error && (
                <p className="text-red-500 pt-4 text-left px-8">{error}</p>
            )}

            {isDeleted ? (<div className="text-xl text-green-500 pt-4 pl-4">Account erfolgreich gelöscht!</div>) : (<div className="inline-block py-2 px-4 pt-10">
            <button
            className="border border-blue-500 rounded-xl px-4 py-2 text-blue-500"
            onClick={handleDeleteAccount}
            >
            Account löschen
            </button>
            </div>)}

            <div className="pb-12"></div>
        </div>
    );
}

export default DeleteAccountMobile;