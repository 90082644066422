import { Link  } from 'react-router-dom';

function HomeMobile() {
    const phoneType = getMobileOperatingSystem();
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling animation
        });
      };

    return (
        <div 
        className="flex flex-col items-center justify-center">
            <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            <div className="text-center pt-8 pl-4 pr-4">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="text-orange-300">Teile</span>
                    <span className="mx-2 text-black"> deine Leidenschaft mit anderen oder </span>
                    <span className="text-orange-500">finde</span>
                    <span className="mx-2 text-black"> noch heute deinen Kurs!</span>
                    </div>
                </span>
            </div>
            <div className="pt-4">
                <div className="relative">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded text-center text-xm sm:text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl tracking-tight"
                        onClick={() => {
                            if (phoneType === "iOS") {
                                window.location.href = "https://apps.apple.com/de/app/walu/id6474895726";
                            } else {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.walu.frontend";
                            }
                        }}
                    >
                        Walu App herunterladen
                    </button>
                </div>
            </div>

            <div class="border-t-2 border-gray-300 mt-32 mb-8 w-3/4 mx-auto"></div> 
            
            <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
            Wieso soll ich meine Kurse über die Walu App anbieten?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Du als <b>Kursanbieter</b> auf Walu hast folgende Vorteile:</div>
                
                    <ul class="list-disc pl-8 text-base px-2">
                    <li>📱 Neue <b>Kursinteressierte</b> können dich ganz einfach über die Walu App finden und kontaktieren.</li><br/>
                        <li>🕒 Alle <b>Informationen</b> zu deinem Kurs, wie z.B. Kurszeiten, eine eigene Kurs-Chatgruppe uvm., werden digitalisiert.</li><br/>
                        <li>📸 Deine <b>Kursmitglieder</b> können coole Fotos und Videos in deinen Kurs posten.</li><br/>
                    </ul>
                    <div className="pb-4 font-semibold pl-6 pr-6 pb-8 text-lg"><b>Noch unsicher?</b> Finde in der Online-Präsentation heraus, ob Walu das richtige für dich und deinen Kurs ist.</div>
                    <Link to="/presentation">
        <div className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            >Für Präsentation anmelden
            </div>
        </div>
    </Link>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Welchen Vorteil habe ich, wenn ich schon jetzt Walu Anbieter werde?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Sei einer der <b>ersten 1000 Anbieter</b> und erhalte:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>Bonus: 🏃‍♂️ Das "<b>Walu Sprinter Paket</b>". <Link to="/bonus1"><span className="text-blue-600">(mehr Infos)</span></Link></li><br/>
                    <li>Bonus: 💰 "<b>Dreimonatige Geld-zurück-Garantie</b>". <Link to="/bonus2"><span className="text-blue-600">(mehr Infos)</span></Link></li><br/>
                    <li>Bonus: 🌟 Dein Paket als "<b>Plus Paket</b>", mit noch mehr Vorteilen. <Link to="/bonus3"><span className="text-blue-600">(mehr Infos)</span></Link></li><br/>
                    </ul>
                    <Link to="/contact">
        <div className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            >Jetzt Bonuscode sichern
            </div>
        </div>
    </Link>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Wie werde ich schon jetzt Walu Kursanbieter?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <ul class="list-disc pl-8 text-base px-2">
                    <li>🌐 Gehe zum "<Link to="/signup"><span className="text-blue-600">Anbieter werden Formular</span></Link>".</li><br/>
                    <li>📝 Fülle die "<b>Allgemeinen Informationen</b>" zu dir als Anbieter aus.</li><br/>
                    <li>💼 Fülle aus, wie du <b>Walu später nutzen</b> wirst. (Paketkosten fallen erst ab dem 01.04.24 an)</li><br/>
                    <li>✅ Bestätige, dass du <b>Walu-Anbieter</b> werden möchtest. (Es fallen einmalig 15€ Anmeldegebühr an)</li><br/>
                    </ul>
                    
                    <Link to="/signup">
        <div className="inline-block py-2 px-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div
            className={`text-base leading-9 font-roboto px-4 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
            >Jetzt Anbieter werden
            </div>
        </div>
    </Link>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 


<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Was passiert, nachdem ich Anbieter geworden bin?
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                    <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Du hast das Formular ausgefüllt? So geht es weiter:</div>
                
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li><b>Anfang April</b>: Wir melden uns bei dir. 📆</li><br/>
                    <li><b>Mitte April</b>: Du kannst dir die Walu App runterladen und deine Kurse online stellen.📲</li><br/>
                    <li><b>Ende April</b>: Lade deine bisherigen Kursmitglieder zu deinem Walu Kurs ein.🤝</li><br/>
                    <li><b>Anfang Mai</b>: Unsere Reise beginnt 🚀. Du bist nun offiziell Walu Kursanbieter.🎉</li><br/>
                    </ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>
<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 


<div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
Was ist die Walu App eigentlich?
            </div>

            <div className="w-full px-4 pb-8">
                <div className="aspect-w-16 aspect-h-8">
                <iframe
                    className="w-full h-full rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
            </div>

            <div className="px-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>  

    <div className="pt-2 text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="flex-col items-center justify-center">
                <div className="pb-4 font-semibold pl-6 pb-8 text-lg">Die <b>Walu App</b> hilft dir dabei:</div>
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li>🤝 Deine <b>Leidenschaft</b> mit anderen in Form von Kursen zu teilen.</li><br/>
                        <li>📍 Spannende <b>Kurse</b> in deiner Umgebung zu finden.</li><br/>
                        <li>🌟 Neue Kursbesucher durch ein attraktives <b>Kursprofil</b> anzulocken.</li><br/>
                        <li>👥 Neue Leute in coolen Kursen kennenzulernen, zu chatten und <b>Fotos & Videos</b> zu posten.</li><br/>
                    </ul>
                </div>
            </div>
      </p>
    </div>
  </div>
</div>

    <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>

    <div className="items-center w-full font-semibold text-center text-black text-2xl py-4 px-2">
    Du hast Fragen? Wir haben die Antworten.
            </div> 

    <div className="px-4 pt-4 pb-8">
  <div className="relative w-full h-full bg-white shadow-lg rounded-lg border border-gray-500" style={{ wordBreak: 'break-word' }}>
    <div className="text-black font-roboto">
      <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left p-2">
        <div>
                <div className="pt-2 flex-col items-center justify-center">
                    <ul class="list-decimal pl-8 text-base px-2">
                    <li><button className="text-left" onClick={scrollToTop}>📅 <b>Wann</b> steht mir die Walu App zur Verfügung? </button></li><br/>
                    <li><Link to="/pakete">Ist es <b>kostenpflichtig</b> Walu zu nutzen?</Link></li><br/>
<li><Link to="/signup">📝 Wo finde ich das "<b>Anbieter werden</b>" Formular?</Link></li><br/>
<li><Link to="/presentation">Welche <b>Vorteile</b> hat Walu noch?</Link></li><br/>
<li><Link to="/bonus">🎁 Welche <b>Bonusse</b> gibt es zur Zeit?</Link></li><br/>
<li><Link to="/bonus">Wieso soll ich schon vor App-Release <b>Anbieter werden?</b></Link></li><br/>
<li><Link to="/pakete">📦 Welche <b>Walu-Pakete</b> gibt es?</Link></li><br/>
<li><Link to="/bonus3">Was sind die Walu <b>Plus-Pakete</b>?</Link></li><br/>
<li><Link to="/bonus2">💸 Wie funktioniert die <b>Geld-zurück-Garantie</b>?</Link></li><br/>
<li><Link to="/bonus1">Was ist das <b>Sprinter-Paket?</b></Link></li><br/>
                    </ul>
                </div>
            </div>
      </p>
    </div>

  </div>
</div>

    

      </div>
    );
}

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export default HomeMobile;