import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import WasIstWalu from "./sections/WasIstWalu";
import WiesoKursanbieter from "./sections/WiesoKursanbieter";
import WelcheBonusse from "./sections/WelcheBonusse";
import WieKursanbieter from "./sections/WieKursanbieter";
import WasNachdemAnbieter from "./sections/WasNachdemAnbieter";
import WiesoPaket from './sections/WiesoPaket';
import WelchePakete from './sections/WelchePakete';

function Kontakt() {
    return (
        <div className="p-6">
            <div
            className="flex flex-col justify-center items-start"
            style={{
                paddingBottom: '5%',
                paddingLeft: '3%',
                marginTop: '-2vh', // Move the content up
            }}
            >
                <div className="text-black font-roboto text-left pt-8">
                    <span
                    className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Kontaktiere uns
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-32">
                    <div className="w-full sm:w-[10rem] md:w-[20rem] lg:w-[30rem] xl:w-[40rem] 2xl:w-[50rem]">
                        <span
                            className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Falls Du Fragen hast oder Hilfe brauchst, dann melde dich bei uns.
                        </span>
                    </div>
                </div>


                <div className="relative p-6 bg-white shadow-lg rounded-lg border border-gray-500">
                    {/* Circle in the top-left corner */}
                    <div className="absolute w-24 h-24 bg-white rounded-full top-0 left-16 transform -translate-x-1/2 -translate-y-1/2 border-2 border-gray-400">
                        <img
                        src="https://walu-media.s3.eu-central-1.amazonaws.com/public/logoorange.png"
                        alt="Logo Icon"
                        className="w-full h-full object-cover rounded-full"
                        />
                    </div>

                     {/* Text to the right of the circle and above the card */}
                    <div className="absolute top-[-46px] left-28 text-black font-roboto sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-9">
                        Walu Support Team
                    </div>
                    
                    {/* Card content sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl*/}
                    <div className="text-black font-roboto">
                        <div className="flex items-center pb-8 pt-8 pr-16 pl-16">
                            {/* FontAwesome icon */}
                            <FontAwesomeIcon icon={faUserGroup} className="text-black sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mr-8" />
                            {/* Text */}
                            <p className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl leading-9">Schreibe uns auf unseren Social Medias</p>
                        </div>

                        <div className="flex items-center pb-8 pr-16 pl-16">
                            {/* FontAwesome icon */}
                            <FontAwesomeIcon icon={faEnvelope} className="text-black sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mr-8" />
                            {/* Text */}
                            <p className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl leading-9">support@mywalu.com</p>
                        </div>

                        <div className="flex items-center pb-8 pr-16 pl-16">
                            {/* FontAwesome icon */}
                            <FontAwesomeIcon icon={faPhone} className="text-black sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mr-8" />
                            {/* Text */}
                            <p className="sm:text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl leading-9">+49 15736814139</p>
                        </div>
                    </div>
                </div>

           

            </div>

        
        <WasIstWalu></WasIstWalu>


<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<WiesoKursanbieter></WiesoKursanbieter>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<WiesoPaket></WiesoPaket>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<WelchePakete></WelchePakete>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<WieKursanbieter></WieKursanbieter>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<WasNachdemAnbieter></WasNachdemAnbieter>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

<WelcheBonusse></WelcheBonusse>
        </div>
    );
}

export default Kontakt;