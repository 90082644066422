function FürWenPräsi() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Für wen ist die Online-Präsentation gedacht?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/webinar1.jpg"
                    alt="Online-Präsentation"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Die <b>Online-Präsentation</b> ist nur für:<br/><br/>

                    <ul class="list-decimal pl-8">
<li>Personen, die bereits <b>Kurse anbieten</b> oder gerne in Zukunft <b>anbieten möchten</b>. 🌐</li><br/>
<li>Kursanbieter, die <b>mehr Kursbesucher</b> haben wollen, ohne einen Cent für Werbung auszugeben. 💰</li><br/>
<li>Kursanbieterinnen, die ihre <b>Kurse digitalisieren</b> wollen. 💻</li><br/>
<li>Kursanbieter, die ihren bisherigen Kursmitgliedern <b>etwas Gutes tun</b> wollen. 💖</li><br/>
</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FürWenPräsi;