import GoldPaket from "./sections/GoldPaket";
import PlatinPaket from "./sections/PlatinPaket";
import SilberPaket from "./sections/SilberPaket";
import WelchePakete from "./sections/WelchePakete";
import WiesoPaket from "./sections/WiesoPaket";

function Pakete() {
    return (
        <div className="p-6">
            <div className="flex justify-start pb-12">
            <div
            className="flex flex-col justify-start"
            style={{
                paddingLeft: '3%',
            }}
            >
                <div className="flex-none text-black font-roboto text-left pt-8">
                    <span
                     className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Dein "Walu Paket"
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                    <div className="w-full sm:w-[10rem] md:w-[20rem] lg:w-[30rem] xl:w-[40rem] 2xl:w-[48rem]">
                        <span
                            className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Willst du Kurse anbieten, brauchst du ein Walu Paket. Jedes Paket hat seine eigenen Vorteile und eins davon passt zu dir.
                        </span>
                    </div>
                </div>
            </div>
            </div>
           


        <WiesoPaket></WiesoPaket>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WelchePakete></WelchePakete>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <SilberPaket></SilberPaket>
        
        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <GoldPaket></GoldPaket>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <PlatinPaket></PlatinPaket>

        </div>
    );
}

export default Pakete;