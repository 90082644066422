import { ButtonsContainer, AppStoreButton, GooglePlayButton } from "react-mobile-app-button";
import { Link  } from 'react-router-dom';
import WasIstWalu from "./sections/WasIstWalu";
import WiesoKursanbieter from "./sections/WiesoKursanbieter";
import WelcheBonusse from "./sections/WelcheBonusse";
import WieKursanbieter from "./sections/WieKursanbieter";
import WasNachdemAnbieter from "./sections/WasNachdemAnbieter";

function Home() {
    const imageFilmURL = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"
    const androidUrl = "https://play.google.com/store/apps/details?id=com.walu.frontend";
    const iOSUrl = "https://apps.apple.com/de/app/walu/id6474895726";

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling animation
        });
      };

    return (
        <div>
            <div className="p-6">
                <div className="flex flex-row flex-grow justify-between"
                style= {{  marginTop: '-2vh' }}
                >
                <div
                className="flex flex-col justify-center"
                style={{
                    paddingBottom: '5%',
                    paddingLeft: '3%',
                   
                }}
                >
                    <div className="text-black font-roboto text-left pt-8">
                        <span
                        className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                        style={{ color: '#1A1C19' }}
                        >
                        Deine Kurse<br />
                        Deine Anbieter<br />
                        Deutschlandweit
                        </span>
                    </div>
                    <div className="text-black font-roboto text-left pt-8 pb-4">
                        <div className="w-full">
                            <span
                                className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                                style={{ color: '#1A1C19' }}
                            >
                                Leite weiterhin deine Kurse, bloß anders,<br></br>
                                veröffentliche deine Kurse online in der Walu App!<br></br>
                                Mehr <b>digital</b>. Mehr <b>Kursbesucher</b>. Mehr <b>Walu</b>.
                                <br />
                                <br />
                                Lade dir die App herunter.
                            </span>
                        </div>
                    </div>
                    <div className="relative pt-4 pb-8">
                    <div className="absolute">
                        <ButtonsContainer>
                            <GooglePlayButton
                            title="JETZT BEI"
                            url={androidUrl}
                            theme="dark"
                            className="custom-style"
                            height={80}
                            width={200}
                            />
                            <AppStoreButton
                            title="Laden im"
                            url={iOSUrl}
                            theme="dark"
                            className="custom-style"
                            height={80}
                            width={200}
                            />
                        </ButtonsContainer>
                    </div>                   
                    </div>
                </div>
       
                <div className="w-1/2 aspect-w-16 aspect-h-8 px-16">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={imageFilmURL}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>
              
                </div>
                
            </div>
            
        <div className="pt-20"></div>

        <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24 pb-12">
            Durch die Kurssuche über die Walu App erreichst du mit deiner Leidenschaft noch mehr Leute.
            </div>

            <div className="px-24">
      <div className="flex flex-row">
        {/* Image 1 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/topleft.png"
            alt="Walu Search"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Durch die Walu App können sich <b>Kurssuchende</b> ein <b>gutes Bild</b> von deinem Kurs machen. 📱</p>
        </div>

        {/* Image 2 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/topcenter.png"
            alt="Walu Categories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Durch die <b>Umgebungssuche</b> werden deine Kurse Leuten <b>in direkter Nähe</b> angezeigt. 👥</p>
        </div>

        {/* Image 3 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/topright.png"
            alt="Walu Subcategories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Kurssuchende können sich über die <b>verschiedenen Kategorien</b> inspirieren lassen und so auch <b>auf deinen Kurs stoßen</b>. 🧑‍🎨</p>
        </div>
      </div>
    </div>

    <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

    <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24 pb-12 pt-8">
    Walu dient lediglich als Plattform, allein du hast die volle Kontrolle über deinen Kurs.
            </div>

            <div className="px-24">
      <div className="flex flex-row">
        {/* Image 1 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bottomleft.png"
            alt="Walu Search"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Du entscheidest welche <b>Fotos und Videos</b>, von deinen Kursmitglieder, in <b>deinem Kurs</b> sichtbar sind. 📸</p>
        </div>

        {/* Image 2 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bottomcenter.png"
            alt="Walu Categories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Du entscheidest welche <b>Informationen</b> du über <b>deinen Kurs</b> veröffentlichst. ✅</p>
        </div>

        {/* Image 3 */}
        <div className="w-1/3 px-4 mb-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bottomright.png"
            alt="Walu Subcategories"
            className="rounded-md w-full h-auto"
          />
          <p className="mt-2 text-center text-2xl">Du entscheidest <b>wann, wo und wie</b> dein Kurs stattfindet. 📍</p>
        </div>
      </div>
    </div>

    <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div>
    
    <WiesoKursanbieter></WiesoKursanbieter>

<div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

    <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WelcheBonusse></WelcheBonusse>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WieKursanbieter></WieKursanbieter>
        
        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WasNachdemAnbieter></WasNachdemAnbieter>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

        <WasIstWalu></WasIstWalu>

        <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 
   
    <div>
<div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
Du hast Fragen? Wir haben die Antworten.
</div>

<div className="pb-8 px-24 pr-32">
<div className={`relative w-full h-full p-8 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
<div className="flex px-8">

<div class="w-1/2 text-2xl font-roboto text-black text-left pl-8">
<ul class="list-disc pl-8">
<li><button onClick={scrollToTop}>📅 <b>Wann</b> steht mir die Walu App zur Verfügung? </button></li><br/>
<li><Link to="/pakete">Ist es <b>kostenpflichtig</b> Walu zu nutzen?</Link></li><br/>
<li><Link to="/signup">📝 Wo finde ich das "<b>Anbieter werden</b>" Formular?</Link></li><br/>
<li><Link to="/presentation">Welche <b>Vorteile</b> hat Walu noch?</Link></li><br/>
<li><Link to="/bonus">🎁 Welche <b>Bonusse</b> gibt es zur Zeit?</Link></li><br/>
</ul>
</div>

<div class="w-1/2 text-2xl font-roboto text-black text-left pl-8">
<ul class="list-disc pl-8">
<li><Link to="/bonus">Wieso soll ich schon vor App-Release <b>Anbieter werden?</b></Link></li><br/>
<li><Link to="/pakete">📦 Welche <b>Walu-Pakete</b> gibt es?</Link></li><br/>
<li><Link to="/bonus3">Was sind die Walu <b>Plus-Pakete</b>?</Link></li><br/>
<li><Link to="/bonus2">💸 Wie funktioniert die <b>Geld-zurück-Garantie</b>?</Link></li><br/>
<li><Link to="/bonus1">Was ist das <b>Sprinter-Paket?</b></Link></li><br/>
</ul>
</div>

</div>
</div>

</div>

    </div>

      
        </div>
        
    );
}

export default Home;