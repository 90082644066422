import WasGeldZurück from './sections/WasGeldZurück';
import WelcheBonusseNoch2 from './sections/WelcheBonusseNoch2';
import WieKursanbieterMitBonusse from './sections/WieKursanbieterMitBonusse';

function Bonus3() {
    return (
        <div className="p-6">
            <div className="flex justify-start">
            <div
            className="flex flex-col justify-start"
            style={{
                paddingLeft: '3%',
            }}
            >
                <div className="flex-none text-black font-roboto text-left pt-8">
                    <span
                    className="sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Bonus 2: <br></br>Geld-zurück-Garantie
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                    <div className="w-full sm:w-[10rem] md:w-[20rem] lg:w-[30rem] xl:w-[40rem] 2xl:w-[50rem]">
                        <span
                            className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Falls dir die Walu App nach <b>drei Monaten</b> nicht gefällt, bekommst du dein <b>Geld zurück</b>.
                        </span>
                    </div>
                </div>
            </div>
            <div style={{
                position: 'absolute',
                left: '65%',
                top: '30%',
                transform: 'translateX(-50%)',
            }}>
                <div className="text-white font-bold text-center sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl border-4 border-white rounded-3xl transform rotate-6 inline-block pt-2 pb-2 pl-6 pr-6">
                JEDEN EURO ZURÜCK!
                </div>
            </div>
            </div>

            <div className="pt-8"></div>

            <WasGeldZurück></WasGeldZurück>

            <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

            <WelcheBonusseNoch2></WelcheBonusseNoch2>

            <div class="border-t-2 border-gray-300 my-8 w-3/4 mx-auto"></div> 

            <WieKursanbieterMitBonusse></WieKursanbieterMitBonusse>

            
        </div>
    );
}

export default Bonus3;