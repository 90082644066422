import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

function NewPasswordMobile() {   
    const { token } = useParams();

    const [password, setPassword] = useState('');
    const [passwordWiederholung, setPasswordWiederholung] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isWdhFocused, setIsWdhFocused] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [error, setError] = useState('');

    const handleResetPassword = async () => {
        if (password !== passwordWiederholung) {
            setError('Die angegebenen Passwörter stimmen nicht überein.')
        } else if (!isValidPassword(password)) {
            setError('Das Passwort muss aus mindestens 5 Zeichen bestehen, wobei Großbuchstaben, Kleinbuchstaben und mindestens eine Ziffer oder ein Symbol enthalten sein muss.');
        } else {
            try {
                const response = await fetch('https://aws.mywalu.com/auth/api/auth/reset', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    token: token,
                    neuesPasswort: password,
                  }),
                });
          
                if (response.ok) {
                    setIsReset(true);
                } else {
                    setError('Ups, etwas ist schief gelaufen.');
                }
            } catch (error) {
                setError('Ups, etwas ist schief gelaufen.');
                console.error('An error occurred', error);
            }
        }
    };

    const isValidPassword = (password) => {
        const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!/:\-@[-`{-~]).{5,150}$/;
        return regexPattern.test(password);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleFocus = () => {
        setIsFocused(true);
      };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const handlePasswordWdhChange = (e) => {
        setPasswordWiederholung(e.target.value);
    };

    const handleWdhFocus = () => {
        setIsWdhFocused(true);
      };
    
    const handleWdhBlur = () => {
        setIsWdhFocused(false);
    };

    return (
        <div className="min-h-screen bg-white">
        {/* Container to make the borders fill the full width */}
        <div className="border-gray-400 pt-8">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <div className="text-center pt-8 pl-4 pr-4 pb-8">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                      <span className="mx-2 text-black">{ isReset ? "Passwort geändert" : "Passwort zurücksetzen" }</span>
                    </div>
                </span>
          </div>

          

          { isReset ? (
            <div className="text-center pt-8 pl-4 pr-4 pb-16">
              <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                  <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Das Passwort wurde erfolgreich geändert. Sie können diesen Tab nun schließen.</span>
                  </div>
              </span>
            </div>
          ) : (
            <div>
              <div className={`relative w-full pl-8 pr-8`}>
                <input
                    type="password"
                    className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 bg-white
                    ${isFocused ? 'border-blue-500' : ''}`}
                    placeholder=" "
                    value={password}
                    onChange={handlePasswordChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                <label
                    htmlFor="password"
                    className={`absolute bg-white left-12 top-[-12px] px-2 pb-1 text-${
                    isFocused ? 'blue-500' : 'gray-500'
                    } text-md transition-all duration-300`}
                >
                    Neues Passwort
                </label>
              </div>
              <div className={`relative w-full mt-8 mb-2 pl-8 pr-8`}>
                  <input
                      type="password"
                      className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 bg-white
                      ${isWdhFocused ? 'border-blue-500' : ''}`}
                      placeholder=" "
                      value={passwordWiederholung}
                      onChange={handlePasswordWdhChange}
                      onFocus={handleWdhFocus}
                      onBlur={handleWdhBlur}
                  />
                  <label
                      htmlFor="password"
                      className={`absolute bg-white left-12 top-[-12px] px-2 pb-1 text-${
                      isWdhFocused ? 'blue-500' : 'gray-500'
                      } text-md transition-all duration-300`}
                  >
                      Passwort wiederholen
                  </label>
              </div>

              {error && (
                  <p className="text-red-500 mb-2 text-left pl-8 pr-8">{error}</p>
              )}

              <div className="inline-block px-10 pt-10">
              <button
              className=" border border-blue-500 rounded-xl px-4 py-2 text-blue-500"
              onClick={handleResetPassword}
              >
              Passwort zurücksetzen
              </button>

              </div>
             
            </div>
          )}
        </div>
      </div>
    );
}

export default NewPasswordMobile;