import { Link  } from 'react-router-dom';

function WiePlusPaket() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"
    
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Wie kann ich mir mein Plus Paket sichern?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
            <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>

       

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Die <b>Plus Pakete</b> sind nur für:<br/><br/>

                    <ul class="list-decimal pl-8">
                    <li>🕒 Anbieter, die <b>vor dem 01. April 2024</b> das "<Link to="/signup"><span className="text-blue-600">Anbieter werden Formular</span></Link>" ausfüllen und..</li><br/>
                    <li>🛍️ im "Anbieter werden Formular" ihren persönlichen <b>Bonuscode</b> angeben.</li><br/>
                    </ul>

                    <Link to="/contact">
                        <div className="inline-block py-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div
                            className={`text-xl leading-9 font-roboto px-8 py-1 rounded-xl text-white bg-blue-500 hover:font-semibold`}
                            >Jetzt Bonuscode sichern
                            </div>
                        </div>
                    </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WiePlusPaket;