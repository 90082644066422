function About( { isMobile } ) {
    return (
        <div className="p-6">
            {isMobile ? (<div className="flex items-center justify-center p-4">
                <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            </div>) : (<div></div>)}
            <div className="relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500">
                <div className="text-black font-roboto">
                    <div className={`text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left ${isMobile ? 'pr-1 pl-1 pt-4 pb-4'  : 'p-6' }`} style={{ wordBreak: 'break-word' }}>
                    Über uns<br /><br />
                    </div>

                    <div className={`text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-roboto font-normal text-black leading-16 text-left ${isMobile ? 'pr-1 pl-1 pt-4 pb-4'  : 'p-6' }`} style={{ wordBreak: 'break-word' }}>
                    Wir sind Walu!<br /><br />
                    <br />
                    Ein junges Start-up aus Deutschland, das darauf fokussiert ist, Menschen miteinander zu verbinden, sodass wir gemeinsam wachsen können.
                    <br />
                    Wie unser Team dazu kam, diese Webseite zu gründen?
                    <br />
                    Schon früh haben wir erkannt, dass es ein überaus großes Angebot an Personen gibt, die etwas gut können und dies an andere weitergeben wollen. So finden sich auf Google z. B. zahlreiche Vereine, Yogastudios, Künstler, Nachhilfen und viele weitere, wir nennen Sie, Anbieter/innen. Konkret kann man diese Angebote als Sport-, Bildungs- und Freizeitaktivitäten beschreiben, oftmals sind diese verpackt in Kursen und Events.
                    <br />
                    Das Problem ist jedoch, dass alles kreuz und quer im Internet zerstreut ist.
                    <br />
                    So ist es schwer, diverse Anbieter in dem gewünschten Angebotsbereich zu finden und sich ein genaues Bild von diesen zu machen. Jedoch dann noch herauszufinden, ob diese Kurse und Events anbieten, wann, wo und wie diese stattfinden? Dies ist oftmals ein langwieriger und komplizierter Prozess. Und hier kommt Walu ins Spiel!
                    <br /><br />
                    WALU = „Value Sharing“, teile deinen Wert mit anderen.<br /><br />
                    <br />
                    Konkret heißt das für dich:
                    <br />
                    Wir geben dir die Gelegenheit, deine Leidenschaft auf einer neuen, innovativen Plattform, mit Leuten zu teilen, die bereit sind von dir zu lernen. Dadurch hast du die Chance, unsere Gesellschaft voranzubringen, deine Werte weiterzugeben und vor allem den Vorteil, durch die eigens angebotenen Kurse, sowohl etwas soziales zu tun, als auch Geld zu verdienen.
                    <br />
                    Wir glauben an das Beste im Menschen und unser Team ist sich sicher, dass jeder in sich selbst einen Wert trägt, eine Leidenschaft, ein Können. Dafür haben wir „Walu – Share your Value“ gegründet, wir wollen, dass Menschen sich gegenseitig helfen, motivieren und ganz einfach voneinander lernen können.
                    <br />
                    Walu setzt sich zum Ziel, jedem dabei zu helfen sich in zahlreichen Gebieten weiterzubilden und schafft die Möglichkeit, die persönliche Gesundheit aber auch die der eigenen Familie und Kinder lebenslänglich zu verbessern. Durch unzählige Kursangebote steht einem erfülltem Leben nichts mehr im Wege.
                    <br />
                    Nur du musst dich noch entscheiden. Bist du bereit dich auf neue, spannende und lebensverändernde Erfahrungen einzulassen? Bist du bereit deine Leidenschaft mit anderen zu teilen, um Ihnen zu helfen Ihr Leben zu verbessern?
                    <br /><br />
                    Dann werde auch Du ein Teil von Walu!
                    <br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;