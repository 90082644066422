import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

function NewPassword() {
    const { token } = useParams();

    const [password, setPassword] = useState('');
    const [passwordWiederholung, setPasswordWiederholung] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isWdhFocused, setIsWdhFocused] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [error, setError] = useState('');

    const handleResetPassword = async () => {
        if (password !== passwordWiederholung) {
            setError('Die angegebenen Passwörter stimmen nicht überein.')
        } else if (!isValidPassword(password)) {
            setError('Das Passwort muss aus mindestens 5 Zeichen bestehen, \nwobei Großbuchstaben, Kleinbuchstaben und mindestens eine Ziffer \noder ein Symbol enthalten sein muss.');
        } else {
            try {
                const response = await fetch('https://aws.mywalu.com/auth/api/auth/reset', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    token: token,
                    neuesPasswort: password,
                  }),
                });
          
                if (response.ok) {
                    setIsReset(true);
                } else {
                    setError('Ups, etwas ist schief gelaufen.');
                }
            } catch (error) {
                setError('Ups, etwas ist schief gelaufen.');
                console.error('An error occurred', error);
            }
        }
    };

    const isValidPassword = (password) => {
        const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!/:\-@[-`{-~]).{5,150}$/;
        return regexPattern.test(password);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleFocus = () => {
        setIsFocused(true);
      };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const handlePasswordWdhChange = (e) => {
        setPasswordWiederholung(e.target.value);
    };

    const handleWdhFocus = () => {
        setIsWdhFocused(true);
      };
    
    const handleWdhBlur = () => {
        setIsWdhFocused(false);
    };

    return (
        <div className="flex items-center justify-center h-screen">
                { isReset ? (
                    <div
                    className="flex flex-col justify-center items-start"
                    style={{
                        padding: '5%',
                        maxWidth: '80vw', // Set a maximum width for the content
                        marginLeft: '-56vw', // Adjust left margin
                        marginTop: '-50vh', // Move the content up
                    }}
                    >
                        <div className="text-black font-roboto text-left pt-8 pb-8">
                            <span
                            className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text:left"
                            style={{ color: '#1A1C19' }}
                            >
                            Passwort wurde geändert
                            </span>
                        </div>
                        <div className="text-black font-roboto text-left pt-8 pb-8">
                            <span
                            className="text-md sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text:left"
                            style={{ color: '#1A1C19' }}
                            >
                            Sie können diesen Tab nun schließen.
                            </span>
                        </div>
                    </div>
                ) : (
                    <div
                    className="flex flex-col justify-center items-start"
                    style={{
                        padding: '5%',
                        maxWidth: '80vw', // Set a maximum width for the content
                        marginLeft: '-56vw', // Adjust left margin
                        marginTop: '-50vh', // Move the content up
                    }}
                    >
                        <div className="text-black font-roboto text-left pt-8 pb-8">
                            <span
                            className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text:left"
                            style={{ color: '#1A1C19' }}
                            >
                            Passwort zurücksetzen
                            </span>
                        </div>
                        <div className={`relative mt-8 w-4/5`}>
                            <input
                                type="password"
                                className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                ${isFocused ? 'border-blue-500' : ''}`}
                                placeholder=" "
                                value={password}
                                onChange={handlePasswordChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                            <label
                                htmlFor="password"
                                className={`absolute left-4 top-[-12px] bg-white px-2 pb-1 text-${
                                isFocused ? 'blue-500' : 'gray-500'
                                } text-md transition-all duration-300`}
                            >
                                Neues Passwort
                            </label>
                        </div>
                        <div className={`relative mt-8 w-4/5 mb-2`}>
                            <input
                                type="password"
                                className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                                ${isWdhFocused ? 'border-blue-500' : ''}`}
                                placeholder=" "
                                value={passwordWiederholung}
                                onChange={handlePasswordWdhChange}
                                onFocus={handleWdhFocus}
                                onBlur={handleWdhBlur}
                            />
                            <label
                                htmlFor="password"
                                className={`absolute left-4 top-[-12px] bg-white px-2 pb-1 text-${
                                isWdhFocused ? 'blue-500' : 'gray-500'
                                } text-md transition-all duration-300`}
                            >
                                Passwort wiederholen
                            </label>
                        </div>

                        {error && (
                            <p className="text-red-500 mb-2 text-left">{error.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}</p>
                        )}

                        <button
                        className="mt-10 border border-gray-400 rounded-xl px-4 py-2 text-gray-700 hover:border-blue-500 hover:text-blue-500 hover:bg-gray-100 transition duration-300"
                        onClick={handleResetPassword}
                        >
                        Passwort zurücksetzen
                        </button>
                    </div>
                )}
        </div>
    );
}

export default NewPassword;