function WasIstWalu() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"

    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was ist die Walu App eigentlich?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Die <b>Walu App</b> hilft dir dabei:<br/><br/>

                        <ul class="list-decimal pl-8">
                        <li>🤝 Deine <b>Leidenschaft</b> mit anderen in Form von Kursen zu teilen.</li><br/>
                        <li>📍 Spannende <b>Kurse</b> in deiner Umgebung zu finden.</li><br/>
                        <li>🌟 Neue Kursbesucher durch ein attraktives <b>Kursprofil</b> anzulocken.</li><br/>
                        <li>👥 Neue Leute in coolen Kursen kennenzulernen, zu chatten und <b>Fotos & Videos</b> zu posten.</li><br/>
                        </ul>    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WasIstWalu;