import React, { useState } from 'react'

function OrderAdmin() {
    const [password, setPassword] = useState('');
    const [benutzer, setPasswordWiederholung] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isBenutzerFocused, setIsWdhFocused] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [error, setError] = useState('');
    const [orderArray, setOrderArray] = useState([]);

    const handleAdminOrderLogin = async () => {
        try {
            const response = await fetch('https://aws.mywalu.com/kuusco/api/websitemembers/orders', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                username: benutzer,
                passwort: password,
              }),
            });
      
            if (response.ok) {
                const data = await response.json();
                setOrderArray(data);
                console.log(data);
                setShowOrders(true);
            } else {
                setError('Ups, etwas ist schief gelaufen.');
            }
        } catch (error) {
            setError('Ups, etwas ist schief gelaufen.');
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleFocus = () => {
        setIsFocused(true);
      };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleBenutzerChange = (e) => {
        setPasswordWiederholung(e.target.value);
    };

    const handleBenutzerFocus = () => {
        setIsWdhFocused(true);
      };
    
    const handleBenutzerBlur = () => {
        setIsWdhFocused(false);
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('de-DE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
    }

    return (
        <div className="flex items-center justify-center h-screen">
            <div
            className="flex flex-col justify-center items-start"
            style={{
                padding: '5%',
                maxWidth: '80vw', // Set a maximum width for the content
                marginLeft: '-56vw', // Adjust left margin
                marginTop: '-50vh', // Move the content up
            }}
            >
                {showOrders ? 
                (<div>

                <div className="pl-64">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            <th className="border">Name</th>
                            <th className="border">Telefonnummer</th>
                            <th className="border">Email</th>
                            <th className="border">Paket</th>
                            <th className="border">Plattform</th>
                            <th className="border">Hat Code</th>
                            <th className="border">Status</th>
                            <th className="border">Zahlungsbetrag</th>
                            <th className="border">Gezahlt am</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orderArray.map((row, index) => (
                            <tr key={index}>
                            <td className="border">{row.name}</td>
                            <td className="border">{row.telefonnummer ? row.telefonnummer : "-"}</td>
                            <td className="border">{row.email}</td>
                            <td className="border">{row.paket === "S" ? "Silber" : row.paket === "G" ? "Gold" : "Platin"}</td>
                            <td className="border">{row.plattform === "A" ? "Android" : "IOS"}</td>
                            <td className="border">{row.hasCode ? 'Yes' : 'No'}</td>
                            <td className="border">{row.status}</td>
                            <td className="border">{row.value}</td>
                            <td className="border">{formatDate(row.erzeugt)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>

                </div>) : 
                (<div>
                <div className="text-black font-roboto text-left pt-8 pb-8">
                    <span
                    className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl text:left"
                    style={{ color: '#1A1C19' }}
                    >
                    Admin Login
                    </span>
                </div>
                <div className={`relative mt-8 w-4/5 mb-2`}>
                    <input
                        className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                        ${isBenutzerFocused ? 'border-blue-500' : ''}`}
                        placeholder=" "
                        value={benutzer}
                        onChange={handleBenutzerChange}
                        onFocus={handleBenutzerFocus}
                        onBlur={handleBenutzerBlur}
                    />
                    <label
                        htmlFor="password"
                        className={`absolute left-4 top-[-12px] bg-white px-2 pb-1 text-${
                        isBenutzerFocused ? 'blue-500' : 'gray-500'
                        } text-md transition-all duration-300`}
                    >
                        Benutzer
                    </label>
                </div>
                <div className={`relative mt-8 w-4/5`}>
                    <input
                        type="password"
                        className={`border-2 border-gray-300 rounded-lg w-full p-3 pl-4 pr-4 text-base h-16 focus:outline-none focus:border-blue-500 transition duration-300 
                        ${isFocused ? 'border-blue-500' : ''}`}
                        placeholder=" "
                        value={password}
                        onChange={handlePasswordChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    <label
                        htmlFor="password"
                        className={`absolute left-4 top-[-12px] bg-white px-2 pb-1 text-${
                        isFocused ? 'blue-500' : 'gray-500'
                        } text-md transition-all duration-300`}
                    >
                        Passwort
                    </label>
                </div>
                
                {error && (
                    <p className="text-red-500 mb-2 text-left">{error.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}</p>
                )}

                <button
                className="mt-10 border border-gray-400 rounded-xl px-4 py-2 text-gray-700 hover:border-blue-500 hover:text-blue-500 hover:bg-gray-100 transition duration-300"
                onClick={handleAdminOrderLogin}
                >
                Anmelden
                </button>
                </div>)}
               
            </div>
        </div>
    );
}

export default OrderAdmin;