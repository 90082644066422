import { Link, useLocation  } from 'react-router-dom';

function TopNavBar() {
    const location = useLocation();

    const isSelected = (path) => location.pathname === path;
    return (
    <div className="p-5 flex justify-end">
      <div className="flex gap-8 items-center">
        <Link to="/">
          <div
          className={`text-xl leading-9 font-roboto ${isSelected('/') ? 'text-white font-semibold' : 'text-white hover:text-gray-400'}`}
          >Home</div>
        </Link>
        <Link to="/search">
          <div
          className={`text-xl leading-9 font-roboto ${isSelected('/search') ? 'text-white font-semibold' : 'text-white hover:text-gray-400'}`}
          >Kurs suchen</div>
        </Link>
        <Link to="/presentation">
          <div
          className={`text-xl leading-9 font-roboto ${isSelected('/presentation') ? 'text-white font-semibold' : 'text-white hover:text-gray-400'}`}
          >Online-Präsentation</div>
        </Link>
        <Link to="/contact">
          <div
          className={`text-xl leading-9 font-roboto ${isSelected('/contact') ? 'text-white font-semibold' : 'text-white hover:text-gray-400'}`}
          >Kontakt</div>
        </Link>
        <Link to="/signup">
          <div
          className={`text-xl leading-9 font-roboto px-2 py-1 rounded-xl text-white bg-blue-500  ${isSelected('/signup') ? 'font-semibold' : 'hover:text-gray-400'}`}
          >Anbieter werden</div>
        </Link>
      </div>
    </div>
    );
}


export default TopNavBar;