function WasNachdemAnbieter() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was passiert, nachdem ich Anbieter geworden bin?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/ballon.jpg"
                    alt="Ballon"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Du hast das Formular ausgefüllt? So geht es weiter:<br/><br/>

                    <ul class="list-decimal pl-8">
                    <li><b>Anfang April</b>: Wir melden uns bei dir. 📆</li><br/>
                    <li><b>Mitte April</b>: Du kannst dir die Walu App runterladen und deine Kurse online stellen.📲</li><br/>
                    <li><b>Ende April</b>: Lade deine bisherigen Kursmitglieder zu deinem Walu Kurs ein.🤝</li><br/>
                    <li><b>Anfang Mai</b>: Unsere Reise beginnt 🚀. Du bist nun offiziell Walu Kursanbieter.🎉</li><br/>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WasNachdemAnbieter;