import { ButtonsContainer, AppStoreButton, GooglePlayButton } from "react-mobile-app-button";

function Share() {
    const homeScreenshots = "https://walu-media.s3.eu-central-1.amazonaws.com/public/websitehome.png";
    const androidUrl = "https://play.google.com/store/apps/details?id=com.walu.frontend";
    const iOSUrl = "https://apps.apple.com/de/app/walu/id6474895726";

    return (
        <div className="flex items-center justify-center h-screen">
            <div
            className="flex flex-col justify-center"
            style={{
                padding: '5%',
                maxWidth: '60vw', // Set a maximum width for the content
                marginLeft: '-4vw', // Adjust left margin
                marginTop: '-70vh', // Move the content up
            }}
            >
                <div className="text-black font-roboto text-left pt-8">
                    <span
                    className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl"
                    style={{ color: '#1A1C19' }}
                    >
                    Diesen Inhalt kannst du dir<br />
                    nur auf der Walu-App ansehen.
                    </span>
                </div>
                <div className="text-black font-roboto text-left pt-8 pb-4">
                    <div className="w-full sm:w-[10rem] md:w-[20rem] lg:w-[30rem] xl:w-[40rem] 2xl:w-[50rem]">
                        <span
                            className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl"
                            style={{ color: '#1A1C19' }}
                        >
                            Lade dir die App herunter.
                        </span>
                    </div>
                </div>
                <div className="relative pt-4">
                    <div className="absolute">
                    <ButtonsContainer>
                            <GooglePlayButton
                            title="JETZT BEI"
                            url={androidUrl}
                            theme="dark"
                            className="custom-style"
                            height={80}
                            width={200}
                            />
                            <AppStoreButton
                            title="Laden im"
                            url={iOSUrl}
                            theme="dark"
                            className="custom-style"
                            height={80}
                            width={200}
                            />
                        </ButtonsContainer>
                    </div>
                    </div>
            </div>
            <div className="h-full" style={{ marginLeft: '6vw', marginBottom: '6vw' }}>
                <img
                src={homeScreenshots}
                alt="Example App"
                className="h-auto"
                style={{ width: '40vw' }}
                />  
            </div>
        </div>
    );
}

export default Share;