import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faUserGroup } from '@fortawesome/free-solid-svg-icons'

function KontaktMobile() {
    return (
        <div className="min-h-screen">
        {/* Container to make the borders fill the full width */}
        <div className="border-gray-400 pt-4">
          <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <div className="text-center pt-4 pb-8 pl-4 pr-4">
                <span className="text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl leading-7 font-roboto font-normal px-4">
                    <div className="flex flex-wrap justify-center items-center">
                    <span className="mx-2 text-black">Kontaktiere uns jederzeit!</span>
                    </div>
                </span>
            </div>
        </div>

        <div className="border-t border-gray-400 pt-6 pb-6 pl-0 flex items-center">
          {/* FontAwesome icon */}
          <FontAwesomeIcon icon={faUserGroup} className="pl-8 text-black text-2xl mr-8" />
          {/* Text */}
          <p className="text-xl leading-9">Über unsere Social Medias</p>
        </div>

        <div className="border-t border-gray-400 pt-6 pb-6 pl-0 flex items-center">
          {/* FontAwesome icon */}
          <FontAwesomeIcon icon={faEnvelope} className="pl-8 text-black text-2xl mr-8" />
          {/* Text */}
          <p className="text-xl leading-9">support@mywalu.com</p>
        </div>

        <div className="border-t border-b border-gray-400 pt-6 pb-6 pl-0 flex items-center">
          {/* FontAwesome icon */}
          <FontAwesomeIcon icon={faPhone} className="pl-8 text-black text-2xl mr-8" />
          {/* Text */}
          <p className="text-xl leading-9">+49 15736814139</p>
        </div>
      </div>
    );
}

export default KontaktMobile;