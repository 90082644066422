function Datenschutz( { isMobile } ) {
    return (
        <div className="p-6">
            {isMobile ? (<div className="flex items-center justify-center p-4">
                <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            </div>) : (<div></div>)}
            <div className="relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500">
                <div className="text-black font-roboto">
                    <div className={`text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left ${isMobile ? 'pr-1 pl-1 pt-4 pb-4'  : 'p-6' }`} style={{ wordBreak: 'break-word' }}>
                        Datenschutzerklärung<br /><br />
                        Privacy Policy<br /><br />
                    </div>

                    <div className={`text-md lg:text-2xl xl:text-3xl 2xl:text-4xl font-roboto font-normal text-black leading-16 text-left ${isMobile ? 'pr-1 pl-1 pt-4 pb-4'  : 'p-6' }`} style={{ wordBreak: 'break-word' }}>
                    <p>Der Schutz deiner Daten ist uns wichtig.</p>
                    <p ><span >&nbsp;</span></p>
                    <p>Mit der folgenden Datenschutzerklärung möchten wir Sie
                    darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch
                    kurz als „Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang
                    verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten
                    Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer
                    Leistungen als auch insbesondere auf unseren Webseiten, in mobilen
                    Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile
                    (nachfolgend zusammenfassend bezeichnet als „Onlineangebot“).</p>

                    <p  >&nbsp;</p>

<p class>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>

<p  >&nbsp;</p>

<p  >Stand: 08. März 2024</p>

<p  >&nbsp;</p>

<p  >Im Folgenden teilen wir die Rechtsgrundlagen der
Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die personenbezogenen
Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu den Regelungen
der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und gelten können.</p>

<p  >Einwilligung (Art. 6 Abs. <span  >1</span> S. 1 <span
>lit</span>. a DSGVO) – Die betroffene Person hat ihre Einwilligung
in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen
spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.</p>

<p  >&nbsp;</p>

<p  >Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. b. DSGVO) – Die
Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die
betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich, die auf Anfrage der betroffenen Person erfolgen.</p>

<p  ><span>&nbsp;</span></p>

<p  >Rechtliche Verpflichtung (Art. 6 Abs. <span  >1</span>
S. 1 <span >lit</span>. c. DSGVO) – Die Verarbeitung ist zur
Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche
unterliegt.</p>

<p  >&nbsp;</p>

<p  >Schutz lebenswichtiger Interessen (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. d. DSGVO) – Die
Verarbeitung ist erforderlich, um lebenswichtige Interessen der betroffenen
Person oder einer anderen natürlichen Person zu schützen.</p>

<p  >&nbsp;</p>

<p  >Berechtigte Interessen (Art. 6 Abs. <span  >1</span>
S. 1 <span >lit</span>. f. DSGVO) – Die Verarbeitung ist zur
Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten
erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten
der betroffenen Person, die den Schutz personenbezogener Daten erfordern,
überwiegen.</p>

<p  >&nbsp;</p>

<p  >Art. 9 Abs. <span  >1</span> S. 1 <span
>lit</span>. b DSGVO (Bewerbungsverfahren als vorvertragliches bzw.
vertragliches Verhältnis) (Soweit im Rahmen des Bewerbungsverfahrens besondere
Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B.
Gesundheitsdaten, wie Schwerbehinderteneigenschaft oder ethnische Herkunft) bei
Bewerbern angefragt werden, damit der Verantwortliche oder die betroffene
Person die ihm bzw. ihr aus dem Arbeitsrecht und dem Recht der sozialen
Sicherheit und des Sozialschutzes erwachsenden Rechte ausüben und seinen bzw.
ihren diesbezüglichen Pflichten nachkommen kann, erfolgt deren Verarbeitung
nach Art. 9 Abs. 2 <span >lit</span>. b. DSGVO, im Fall des
Schutzes lebenswichtiger Interessen der Bewerber oder anderer Personen gem.
Art. 9 Abs. 2 <span >lit</span>. c. DSGVO oder für Zwecke der
Gesundheitsvorsorge oder der Arbeitsmedizin, für die Beurteilung der
Arbeitsfähigkeit des Beschäftigten, für die medizinische Diagnostik, die
Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für die
Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich gem.
Art. 9 Abs. 2 <span >lit</span>. h. DSGVO. Im Fall einer auf
freiwilliger Einwilligung beruhenden Mitteilung von besonderen Kategorien von
Daten, erfolgt deren Verarbeitung auf Grundlage von Art. 9 Abs. 2 <span
>lit</span>. a. DSGVO.)</p>

<p  >&nbsp;</p>

<p  >Nationale Datenschutzregelungen in Deutschland: Zusätzlich
zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale
Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das
Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung,
zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie
automatisierten Entscheidungsfindung im Einzelfall einschließlich <span
>Profiling</span>. Des Weiteren regelt es die Datenverarbeitung für
Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick
auf die Begründung, Durchführung oder Beendigung von
Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner
können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
gelangen.</p>

<p  >&nbsp;</p>

<p  >Verantwortlich</p>

<p  >&nbsp;</p>

<p  >Walu</p>

<p  >Laatz und <span >Zlenko</span> GbR</p>

<p  >Obergasse 29</p>

<p  >76877 Offenbach</p>

<p  >E-Mail-Adresse: <a href="mailto:support@mywalu.com">support@mywalu.com</a></p>

<p  >Mobil: +49 15566145761</p>

<p  ><span ><span>Impressum</span></span><span  >:
</span><a href="https://www.mywalu.de/imprint"><span 
>https://www.mywalu.de/imprint</span></a><span
 > </span></p>

<p  ><span  >&nbsp;</span></p>

<p  >Ansprechpartner</p>

<p  >&nbsp;</p>

<p  >Für alle Fragen bezüglich des Datenschutzes und unserer
Datenschutzerklärung steht Ihnen unser Datenschutzbeauftragter, Tom Laatz,
gerne zur Verfügung. Sie können ihn per E-Mail unter support@mywalu.com
erreichen. Wir sind bestrebt, Ihre Anfragen zeitnah und umfassend zu
beantworten. Bitte verwenden Sie bei allen datenschutzbezogenen Anliegen diese
E-Mail-Adresse, um sicherzustellen, dass Ihre Anfrage angemessen und
vertraulich behandelt wird.</p>

<p  >&nbsp;</p>

<p  >Sicherheitsmaßnahmen</p>

<p  >&nbsp;</p>

<p  >Du kannst deinen Account löschen (Account Deletion) unter
folgendem Link: https://www.mywalu.com/deleteaccount</p>

<p  >&nbsp;</p>

<p  >Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
Berücksichtigung des Stands der Technik, der Implementierungskosten und der
Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung
der Rechte und Freiheiten natürlicher Personen geeignete technische und
organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
gewährleisten.</p>

<p  >Zu den Maßnahmen gehören insbesondere die Sicherung der
Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des
physischen und elektronischen Zugangs zu den Daten als auch des sie
betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von
Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend
dem Prinzip des Datenschutzes, durch Technikgestaltung und durch
datenschutzfreundliche Voreinstellungen.</p>

<p  ><span>&nbsp;</span></p>

<p  >Kürzung der IP-Adresse: Sofern es uns möglich ist oder eine
Speicherung der IP-Adresse nicht erforderlich ist, kürzen wir oder lassen Ihre
IP-Adresse kürzen. Im Fall der Kürzung der IP-Adresse, auch als „IP-<span
>Masking</span>“ bezeichnet, wird das letzte Oktett, d.h., die
letzten beiden Zahlen einer IP-Adresse, gelöscht (die IP-Adresse ist in diesem
Kontext eine einem Internetanschluss durch den Online-Zugangs-Provider
individuell zugeordnete Kennung). Mit der Kürzung der IP-Adresse soll die
Identifizierung einer Person anhand ihrer IP-Adresse verhindert oder wesentlich
erschwert werden.</p>

<p  >&nbsp;</p>

<p  >SSL-Verschlüsselung (https): Um Ihre via unser
Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem
Präfix https:// in der Adresszeile Ihres Browsers.</p>

<p  >&nbsp;</p>

<p  >Übermittlung und Offenbarung von personenbezogenen Daten</p>

<p  >&nbsp;</p>

<p  >Im Rahmen unserer Verarbeitung von personenbezogenen Daten
kommt es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen
gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.B.
Zahlungsinstitute im Rahmen von Zahlungsvorgängen, mit IT-Aufgaben beauftragte
Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite
eingebunden werden, gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben
und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem
Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.</p>

<p  >&nbsp;</p>

<p  >Datenübermittlung innerhalb der Organisation: Wir können
personenbezogene Daten an andere Stellen innerhalb unserer Organisation
übermitteln oder ihnen den Zugriff auf diese Daten gewähren. Sofern diese
Weitergabe zu administrativen Zwecken erfolgt, beruht die Weitergabe der Daten
auf unseren berechtigten unternehmerischen und betriebswirtschaftlichen
Interessen oder erfolgt, sofern sie Erfüllung unserer vertragsbezogenen
Verpflichtungen erforderlich ist oder wenn eine Einwilligung der Betroffenen
oder eine gesetzliche Erlaubnis vorliegt.</p>

<p  >&nbsp;</p>

<p  >Datenverarbeitung in Drittländern</p>

<p  ><span>&nbsp;</span></p>

<p  >Sofern wir Daten in einem Drittland (d.h., außerhalb der
Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten
oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder
der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder
Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
Vorgaben.</p>

<p  >&nbsp;</p>

<p  >Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich
oder gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die
Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, zu denen die
unter dem „Privacy-Shield“ zertifizierten US-Verarbeiter gehören, oder auf
Grundlage besonderer Garantien, wie z.B. vertraglicher Verpflichtung durch
sogenannte Standardschutzklauseln der EU-Kommission, des Vorliegens von
Zertifizierungen oder verbindlicher interner Datenschutzvorschriften, verarbeiten
(Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission:
https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
).</p>

<p  >&nbsp;</p>

<p  >Einsatz von Cookies</p>

<p  >&nbsp;</p>

<p  >Cookies sind Textdateien, die Daten von besuchten Websites
oder Domains enthalten und von einem Browser auf dem Computer des Benutzers
gespeichert werden. Ein Cookie dient in erster Linie dazu, die Informationen
über einen Benutzer während oder nach seinem Besuch innerhalb eines
Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die
Spracheinstellungen auf einer Webseite, der <span >Loginstatus</span>,
ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören. Zu dem
Begriff der Cookies zählen wir ferner andere Technologien, die die gleichen
Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand
pseudonymer Onlinekennzeichnungen gespeichert werden, auch als „Nutzer-IDs“
bezeichnet)</p>

<p  ><span>&nbsp;</span></p>

<p  >Die folgenden Cookie-Typen und Funktionen werden
unterschieden:</p>

<p  >&nbsp;</p>

<p  >Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
Online-Angebot verlassen und seinen Browser geschlossen hat.</p>

<p  >&nbsp;</p>

<p  >Permanente Cookies: Permanente Cookies bleiben auch nach dem
Schließen des Browsers gespeichert. So kann beispielsweise der Login-Status
gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer
eine Website erneut besucht. Ebenso können die Interessen von Nutzern, die zur
Reichweitenmessung oder zu Marketingzwecken verwendet werden, in einem solchen
Cookie gespeichert werden.</p>

<p  >&nbsp;</p>

<p  >First-Party-Cookies: First-Party-Cookies werden von uns
selbst gesetzt.</p>

<p  >&nbsp;</p>

<p  >Third-Party-Cookies (auch: Drittanbieter-Cookies):
Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog. Dritten)
verwendet, um Benutzerinformationen zu verarbeiten.</p>

<p  >&nbsp;</p>

<p  >Notwendige (auch: <span  >essentielle</span> oder
unbedingt erforderliche) Cookies: Cookies können zum einen für den Betrieb
einer Webseite unbedingt erforderlich sein (z.B. um Logins oder andere
Nutzereingaben zu speichern oder aus Gründen der Sicherheit).</p>

<p  >&nbsp;</p>

<p  >Statistik-, Marketing- und Personalisierungs-Cookies: Ferner
werden Cookies im Regelfall auch im Rahmen der Reichweitenmessung eingesetzt
sowie dann, wenn die Interessen eines Nutzers oder sein Verhalten (z.B.
Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen
Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu,
den Nutzern z.B. Inhalte anzuzeigen, die ihren <span  >potentiellen</span>
Interessen entsprechen. Dieses Verfahren wird auch als „Tracking“, d.h.,
Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet<span
 >. .</span> Soweit wir Cookies oder „Tracking“-Technologien
einsetzen, informieren wir Sie gesondert in unserer Datenschutzerklärung oder
im Rahmen der Einholung einer Einwilligung.</p>

<p  ><span>&nbsp;</span></p>

<p  >Hinweise zu Rechtsgrundlagen: Auf welcher Rechtsgrundlage
wir Ihre personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon
ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie in die
Nutzung von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer
Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies
verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an
einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen
Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies erforderlich ist,
um unsere vertraglichen Verpflichtungen zu erfüllen.</p>

<p  >&nbsp;</p>

<p  >Allgemeine Hinweise zum Widerruf und Widerspruch (<span
>Opt</span>-Out): Abhängig davon, ob die Verarbeitung auf Grundlage
einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die
Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung
Ihrer Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als „<span
>Opt</span>-Out“ bezeichnet). Sie können Ihren Widerspruch zunächst
mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung
von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres
Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz
von Cookies zu Zwecken des Onlinemarketings kann auch mittels einer Vielzahl
von Diensten, vor allem im Fall des Trackings, über die Webseiten
http://optout.aboutads.info und http://www.youronlinechoices.com/ erklärt
werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der Angaben
zu den eingesetzten Dienstleistern und Cookies erhalten.</p>

<p  >&nbsp;</p>

<p  >Verarbeitung von Cookie-Daten auf Grundlage einer
Einwilligung: Bevor wir Daten im Rahmen der Nutzung von Cookies verarbeiten
oder verarbeiten lassen, bitten wir die Nutzer um eine jederzeit widerrufbare
Einwilligung. Bevor die Einwilligung nicht ausgesprochen wurde, werden
allenfalls Cookies eingesetzt, die für den Betrieb unseres Onlineangebotes
erforderlich sind. Deren Einsatz erfolgt auf der Grundlage unseres Interesses
und des Interesses der Nutzer an der erwarteten Funktionsfähigkeit unseres
Onlineangebotes.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte
Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
(z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Kommerzielle und geschäftliche Leistungen</p>

<p  ><span>&nbsp;</span></p>

<p  >Wir verarbeiten Daten unserer Vertrags- und
Geschäftspartner, z.B. Kunden und Interessenten (zusammenfassend bezeichnet als
„Vertragspartner“) im Rahmen von vertraglichen und vergleichbaren
Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im Rahmen der
Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um Anfragen
zu beantworten.</p>

<p  >&nbsp;</p>

<p  >Diese Daten verarbeiten wir zur Erfüllung unserer
vertraglichen Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit
diesen Angaben einhergehenden Verwaltungsaufgaben sowie der unternehmerischen
Organisation. Die Daten der Vertragspartner geben wir im Rahmen des geltenden
Rechts nur insoweit an Dritte weiter, als dies zu den vorgenannten Zwecken oder
zur Erfüllung gesetzlicher Pflichten erforderlich ist oder mit Einwilligung der
Vertragspartner erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und
sonstige Hilfsdienste sowie Subunternehmer, Banken, Steuer- und Rechtsberater,
Zahlungsdienstleister oder Steuerbehörden). Über weitere Verarbeitungsformen,
z.B. zu Zwecken des Marketings, werden die Vertragspartner im Rahmen dieser
Datenschutzerklärung informiert.</p>

<p  ><span>&nbsp;</span></p>

<p  >Welche Daten für die vorgenannten Zwecke erforderlich sind,
teilen wir den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.B. in
Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole
(z.B. Sternchen o.ä.), oder persönlich mit.</p>

<p  >&nbsp;</p>

<p  >Wir löschen die Daten nach Ablauf gesetzlicher
Gewährleistungs- und vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf
von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert
werden, z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt
werden müssen (z.B. für Steuerzwecke im Regelfall 10 Jahre). Daten, die uns im
Rahmen eines Auftrags durch den Vertragspartner offengelegt wurden, löschen wir
entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.</p>

<p  >&nbsp;</p>

<p  >Soweit wir zur Erbringung unserer Leistungen Drittanbieter
oder Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern und den
Anbietern die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen
Drittanbieter oder Plattformen.</p>

<p  ><span>&nbsp;</span></p>

<p  >Kundenkonto: Vertragspartner können innerhalb unseres
Onlineangebotes ein Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz
„Kundenkonto“). Falls die Registrierung eines Kundenkontos erforderlich ist,
werden Vertragspartner hierauf ebenso hingewiesen wie auf die für die
Registrierung erforderlichen Angaben. Die Kundenkonten sind nicht öffentlich
und können von Suchmaschinen nicht indexiert werden. Im Rahmen der
Registrierung sowie anschließender Anmeldungen und Nutzungen des Kundenkontos
speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die
Registrierung nachweisen und etwaigem Missbrauch des Kundenkontos vorbeugen zu
können.</p>

<p  >&nbsp;</p>

<p  >Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das
Kundenkonto betreffenden Daten gelöscht, vorbehaltlich, deren Aufbewahrung ist
aus gesetzlichen Gründen erforderlich. Es obliegt den Kunden, ihre Daten bei
erfolgter Kündigung des Kundenkontos zu sichern.</p>

<p  ><span>&nbsp;</span></p>

<p  >Wirtschaftliche Analysen und Marktforschung: Aus
betriebswirtschaftlichen Gründen und um Markttendenzen, Wünsche der
Vertragspartner und Nutzer erkennen zu können, analysieren wir die uns
vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc., wobei in
die Gruppe der betroffenen Personen Vertragspartner, Interessenten, Kunden,
Besucher und Nutzer unseres Onlineangebotes fallen können.</p>

<p  >&nbsp;</p>

<p  >Die Analysen erfolgen zum Zweck betriebswirtschaftlicher
Auswertungen, des Marketings und der Marktforschung (z.B. zur Bestimmung von
Kundengruppen mit unterschiedlichen Eigenschaften). Dabei können wir, sofern
vorhanden, die Profile von registrierten Nutzern <span  >samt ihrer
Angaben</span>, z.B. zu in Anspruch genommenen Leistungen, berücksichtigen. Die
Analysen dienen <span  >alleine</span> uns und werden nicht extern
offenbart, sofern es sich nicht um anonyme Analysen mit zusammengefassten, also
anonymisierten Werten handelt. Ferner nehmen wir Rücksicht auf die Privatsphäre
der Nutzer und verarbeiten die Daten zu den Analysezwecken möglichst pseudonym
und, sofern machbar, anonym (z.B. als zusammengefasste Daten).</p>

<p  >&nbsp;</p>

<p  >Shop und E-Commerce: Wir verarbeiten die Daten unserer
Kunden, um ihnen die Auswahl, den Erwerb, bzw. die Bestellung der gewählten
Produkte, Waren sowie verbundener Leistungen, als auch deren Bezahlung und
Zustellung, bzw. Ausführung zu ermöglichen.</p>

<p  >&nbsp;</p>

<p  >Die erforderlichen Angaben sind als solche im Rahmen des
Bestell- bzw. vergleichbaren Erwerbsvorgangs gekennzeichnet und umfassen die
zur Auslieferung, bzw. Zurverfügungstellung und Abrechnung benötigten Angaben
sowie Kontaktinformationen, um etwaige Rücksprache halten zu können.</p>

<p  >&nbsp;</p>

<p  >Veranstaltungen und Events: Wir verarbeiten die Daten der
Teilnehmer der von uns angebotenen oder ausgerichteten Veranstaltungen, Events
und ähnlichen Aktivitäten (nachfolgend einheitlich als „Teilnehmer“ und
„Veranstaltungen“ bezeichnet), um ihnen die Teilnahme an den Veranstaltungen
und Inanspruchnahme der mit der Teilnahme verbundenen Leistungen oder Aktionen
zu ermöglichen.</p>

<p  >&nbsp;</p>

<p  >Sofern wir in diesem Rahmen gesundheitsbezogene Daten,
religiöse, politische oder sonstige besondere Kategorien von Daten verarbeiten,
dann erfolgt diese im Rahmen der Offenkundigkeit (z.B. bei thematisch
ausgerichteten Veranstaltungen oder dient der Gesundheitsvorsorge, Sicherheit
oder erfolgt mit Einwilligung der Betroffenen).</p>

<p  >&nbsp;</p>

<p  >Die erforderlichen Angaben sind als solche im Rahmen des
Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben sowie
Kontaktinformationen, um etwaige Rücksprachen halten zu können. Soweit wir
Zugang zu Informationen der Endkunden, Mitarbeitern oder anderer Personen
erhalten, verarbeiten wir diese im Einklang mit den gesetzlichen und
vertraglichen Vorgaben.</p>

<p  >&nbsp;</p>

<p  >Weitere Informationen zu kommerziellen Dienstleistungen: Wir
verarbeiten die Daten unserer Kunden sowie Auftraggeber (nachfolgend
einheitlich als „Kunden“ bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die
Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten
als auch deren Bezahlung und Zustellung bzw. Ausführung oder Erbringung zu
ermöglichen.</p>

<p  >&nbsp;</p>

<p  >Die erforderlichen Angaben sind als solche im Rahmen des
Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben sowie
Kontaktinformationen, um etwaige Rücksprachen halten zu können.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie),
Kontaktdaten (z.B. E-Mail, Telefonnummern), Vertragsdaten (z.B.
Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte
Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
(z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Interessenten, Geschäfts- und
Vertragspartner, Kunden.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Vertragliche Leistungen und
Service, Kontaktanfragen und Kommunikation, Büro- und Organisationsverfahren,
Verwaltung und Beantwortung von Anfragen, Sicherheitsmaßnahmen,
Besuchsaktionsauswertung, Interessenbasiertes und verhaltensbezogenes
Marketing, <span >Profiling</span> (Erstellen von Nutzerprofilen).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Vertragserfüllung und vorvertragliche
Anfragen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
b. DSGVO), Rechtliche Verpflichtung (Art. 6 Abs. <span  >1</span> S.
1 <span >lit</span>. c. DSGVO), Berechtigte Interessen (Art. 6 Abs.
<span  >1</span> S. 1 <span >lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Zahlungsdienstleister</p>

<p  >&nbsp;</p>

<p  >Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen,
aufgrund gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
Interessen bieten wir den betroffenen Personen effiziente und sichere
Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten
weitere Zahlungsdienstleister ein (zusammenfassend „Zahlungsdienstleister“).</p>

<p  >&nbsp;</p>

<p  >Zu den durch die Zahlungsdienstleister verarbeiteten Daten
gehören Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie z.B.
Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie
die Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind
erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten werden
jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen
gespeichert. D.h., wir erhalten keine konto- oder kreditkartenbezogenen Informationen,
sondern lediglich Informationen mit Bestätigung oder <span >Negativbeauskunftung</span>
der Zahlung. Unter Umständen werden die Daten seitens der Zahlungsdienstleister
an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die
Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die
Datenschutzhinweise der Zahlungsdienstleister.</p>

<p  >&nbsp;</p>

<p  >Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen
und die Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche
innerhalb der jeweiligen Webseiten bzw. Transaktionsapplikationen abrufbar
sind. Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und
Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie),
Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie),
Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kunden, Interessenten.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Vertragliche Leistungen und
Service, Kontaktanfragen und Kommunikation, <span >Affiliate</span>-Nachverfolgung.</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Vertragserfüllung und vorvertragliche
Anfragen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
b. DSGVO), Berechtigte Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span
>lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Eingesetzte Dienste und Diensteanbieter:</p>

<p  >&nbsp;</p>

<p  >Klarna / Sofortüberweisung: Zahlungsdienstleistungen;
Dienstanbieter: Klarna Bank AB (<span >publ</span>), <span
>Sveavägen</span> 46, 111 34 Stockholm, Schweden; Website:
https://www.klarna.com/de; Datenschutzerklärung: <a
href="https://www.klarna.com/de/datenschutz"><span>https://www.klarna.com/de/datenschutz</span></a>.</p>

<p  ><span>&nbsp;</span></p>

<p  >Mastercard: Zahlungsdienstleistungen; Dienstanbieter:
Mastercard Europe SA, <span >Chaussée</span> de <span >Tervuren</span>
198A, B-1410 Waterloo, Belgien; Website: https://www.mastercard.de/de-de.html;
Datenschutzerklärung: <a href="https://www.mastercard.de/de-de/datenschutz.html"><span
>https://www.mastercard.de/de-de/datenschutz.html</span></a>.</p>

<p  >&nbsp;</p>

<p  >PayPal: Zahlungsdienstleistungen und -Lösungen (z.B. PayPal,
PayPal Plus, <span >Braintree</span>); Dienstanbieter: PayPal
(Europe) <span >S.à</span> <span >r.l</span>. et <span
>Cie</span>, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg;
Website: https://www.paypal.com/de; Datenschutzerklärung: <a
href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"><span
>https://www.paypal.com/de/webapps/mpp/ua/privacy-full</span></a>.</p>

<p  >&nbsp;</p>

<p  >Visa: Zahlungsdienstleistungen; Dienstanbieter: Visa Europe
Services Inc., Zweigniederlassung London, 1 Sheldon Square, London W2 6TT, GB;
Website: https://www.visa.de; Datenschutzerklärung: <a
href="https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html"><span
>https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html</span></a>.</p>

<p  >&nbsp;</p>

<p  >Registrierung und Anmeldung</p>

<p  >&nbsp;</p>

<p  >Nutzer und Anbieter können ein Nutzerkonto anlegen. Im
Rahmen der Registrierung werden den Nutzern die erforderlichen Pflichtangaben
mitgeteilt und zu Zwecken der Bereitstellung des Nutzerkontos auf Grundlage
vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten gehören
insbesondere die Login-Informationen (Name, Passwort sowie eine
E-Mail-Adresse). Die im Rahmen der Registrierung eingegebenen Daten werden für
die Zwecke der Nutzung des Nutzerkontos und dessen Zwecks verwendet.</p>

<p  >&nbsp;</p>

<p  >Die Nutzer können über Vorgänge, die für deren Nutzerkonto
relevant sind, wie z.B. technische Änderungen, per E-Mail informiert werden.
Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf
das Nutzerkonto, vorbehaltlich einer gesetzlichen Aufbewahrungspflicht,
gelöscht. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem
Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der
Vertragsdauer gespeicherte Daten des Nutzers unwiederbringlich zu löschen.</p>

<p  >&nbsp;</p>

<p  >Im Rahmen der Inanspruchnahme unserer Registrierungs- und
Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung
erfolgt auf Grundlage unserer berechtigten Interessen als auch jener der Nutzer
an einem Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn, sie
ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu besteht
eine gesetzliche Verpflichtung.</p>

<p  >&nbsp;</p>

<p  >Online-Forum: Die Teilnahme am Forum setzt eine
Registrierung voraus, bei der, vorbehaltlich anderer Angaben im
Registrierungsformular, ein oder Ihr Name, ein Kennwort und die E-Mail-Adresse,
an welche die Zugangsdaten versendet werden, angegeben werden müssen. Aus
Gründen der Sicherheit sollte das Kennwort dem Stand der Technik entsprechen,
also kompliziert sein (hierauf werden Nutzer notfalls im Rahmen der
Registrierung hingewiesen) und nicht an anderer Stelle verwendet werden. Die
Beiträge im Forum sind für die Öffentlichkeit sichtbar, es sei denn, deren
Sichtbarkeit ist auf bestimmte Mitglieder oder Mitgliedergruppen beschränkt.
Die Beiträge der Verfasser werden mit deren Namen, sofern registriert oder
angegeben, dem Zeitpunkt und dem Eintragsinhalt gespeichert. Bei Anmeldungen
und beim Verfassen von Einträgen werden ferner die IP-Adressen der Nutzer
gespeichert, falls die Einträge einen unzulässigen Inhalt aufweisen sollten und
die IP-Adressen der Rechtsverfolgung dienen könnten. Der Verantwortliche behält
sich vor, die Anmeldungen und Einträge auf Grundlage einer sachgerechten
Abwägung zu löschen.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
Texteingaben, Fotografien, Videos), Meta-/Kommunikationsdaten (z.B.
Geräte-Informationen, IP-Adressen), Nutzungsdaten (z.B. besuchte Webseiten,
Interesse an Inhalten, Zugriffszeiten).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Vertragliche Leistungen und
Service, Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO),
Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. <span  >1</span>
S. 1 <span >lit</span>. b. DSGVO), Berechtigte Interessen (Art. 6
Abs. <span  >1</span> S. 1 <span >lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Blogs und Publikationsmedien</p>

<p  >&nbsp;</p>

<p  >Wir nutzen Blogs oder vergleichbare Mittel der
Onlinekommunikation und Publikation (nachfolgend „Publikationsmedium“). Die
Daten der Leser werden für die Zwecke des Publikationsmediums nur insoweit
verarbeitet, als es für dessen Darstellung und die Kommunikation zwischen
Autoren und Lesern oder aus Gründen der Sicherheit erforderlich ist. Im Übrigen
verweisen wir auf die Informationen zur Verarbeitung der Besucher unseres
Publikationsmediums im Rahmen dieser Datenschutzhinweise.</p>

<p  >&nbsp;</p>

<p  >Kommentare und Beiträge: Wenn Nutzer Kommentare oder
sonstige Beiträge hinterlassen, können ihre IP-Adressen auf Grundlage unserer
berechtigten Interessen gespeichert werden. Das erfolgt zu unserer Sicherheit,
falls jemand in Kommentaren und Beiträgen widerrechtliche Inhalte hinterlässt
(Beleidigungen, verbotene politische Propaganda etc.). In diesem Fall können
wir selbst für den Kommentar oder Beitrag belangt werden und sind daher an der
Identität des Verfassers interessiert.</p>

<p  >&nbsp;</p>

<p  >Des Weiteren behalten wir uns vor, auf Grundlage unserer
berechtigten Interessen die Angaben der Nutzer zwecks Spamerkennung zu
verarbeiten.</p>

<p  >&nbsp;</p>

<p  >Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall
von Umfragen die IP-Adressen der Nutzer für deren Dauer zu speichern und
Cookies zu verwenden, um Mehrfachabstimmungen zu vermeiden.</p>

<p  >&nbsp;</p>

<p  >Die im Rahmen der Kommentare und Beiträge mitgeteilten
Informationen zur Person, etwaige Kontakt- sowie Webseiteninformationen als
auch die inhaltlichen Angaben werden von uns bis zum Widerspruch der Nutzer
dauerhaft gespeichert.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten,
Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Vertragliche Leistungen und
Service, Feedback (z.B. Sammeln von Feedback via Online-Formular),
Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Vertragserfüllung und vorvertragliche
Anfragen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
b. DSGVO), Berechtigte Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span
>lit</span>. f. DSGVO), Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Schutz
lebenswichtiger Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span
>lit</span>. d. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Kontaktaufnahme</p>

<p  >&nbsp;</p>

<p  >Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular,
E-Mail, Telefon oder via soziale Medien) werden die Angaben der anfragenden
Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen und
etwaiger angefragter Maßnahmen erforderlich ist.</p>

<p  >&nbsp;</p>

<p  >Die Beantwortung der Kontaktanfragen im Rahmen von
vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen
und im Übrigen auf Grundlage der berechtigten Interessen an der Beantwortung
der Anfragen.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
Texteingaben, Fotografien, Videos).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kommunikationspartner.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation.</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Vertragserfüllung und vorvertragliche
Anfragen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
b. DSGVO), Berechtigte Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span
>lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Kommunikation via Messenger</p>

<p  >&nbsp;</p>

<p  >Wir setzen zu Zwecken der Kommunikation Messenger-Dienste
ein und bitten daher darum, die nachfolgenden Hinweise zur Funktionsfähigkeit
der Messenger, zur Verschlüsselung, zur Nutzung der Metadaten der Kommunikation
und zu Ihren Widerspruchsmöglichkeiten zu beachten.</p>

<p  >&nbsp;</p>

<p  >Sie können uns auch auf alternativen Wegen, z.B. via Telefon
oder E-Mail, kontaktieren. Bitte nutzen Sie die Ihnen mitgeteilten
Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes angegebenen
Kontaktmöglichkeiten.</p>

<p  >&nbsp;</p>

<p  >Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten
(d.h., der Inhalt Ihrer Nachricht und Anhänge) weisen wir darauf hin, dass die
Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte Bilder)
von Ende zu Ende verschlüsselt werden. Das bedeutet, dass der Inhalt der
Nachrichten nicht einsehbar ist, nicht einmal durch die Messenger-Anbieter
selbst. Sie sollten immer eine aktuelle Version der Messenger mit aktivierter
Verschlüsselung nutzen, damit die Verschlüsselung der Nachrichteninhalte sichergestellt
ist.</p>

<p  >&nbsp;</p>

<p  >Wir weisen unsere Kommunikationspartner jedoch zusätzlich
darauf hin, dass die Anbieter der Messenger zwar nicht den Inhalt einsehen,
aber in Erfahrung bringen können, dass und wann Kommunikationspartner mit uns
kommunizieren sowie technische Informationen zum verwendeten Gerät der
Kommunikationspartner und je nach Einstellungen ihres Gerätes auch
Standortinformationen (sogenannte Metadaten) verarbeitet werden.</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Sofern wir
Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um eine
Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung ihrer Daten
deren Einwilligung. Im Übrigen, falls wir nicht um eine Einwilligung bitten und
sie z.B. von sich aus Kontakt mit uns aufnehmen, nutzen wir Messenger im
Verhältnis zu unseren Vertragspartnern sowie im Rahmen der Vertragsanbahnung
als eine vertragliche Maßnahme und im Fall anderer Interessenten und
Kommunikationspartner auf Grundlage unserer berechtigten Interessen an einer
schnellen und effizienten Kommunikation und Erfüllung der Bedürfnisse unser
Kommunikationspartner an der Kommunikation via Messengern. Ferner weisen wir
Sie darauf hin, dass wir die uns mitgeteilten Kontaktdaten ohne Ihre
Einwilligung nicht erstmalig an die Messenger übermitteln.</p>

<p  ><span>&nbsp;</span></p>

<p  >Widerruf, Widerspruch und Löschung: Sie können jederzeit
eine erteilte Einwilligung widerrufen und der Kommunikation mit uns via
Messenger jederzeit widersprechen. Im Fall der Kommunikation via Messenger
löschen wir die Nachrichten entsprechend unseren generellen Löschrichtlinien
(d.h. z.B., wie oben beschrieben, nach Ende vertraglicher Beziehungen, im
Kontext von Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen
können, etwaige Auskünfte der Kommunikationspartner beantwortet zu haben, wenn
kein Rückbezug auf eine vorhergehende Konversation zu erwarten ist und der
Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

<p  >&nbsp;</p>

<p  >Vorbehalt des Verweises auf andere Kommunikationswege: Zum
Abschluss möchten wir darauf hinweisen, dass wir uns aus Gründen Ihrer
Sicherheit vorbehalten, Anfragen über Messenger nicht zu beantworten. Das ist
der Fall, wenn z.B. Vertragsinterna besonderer Geheimhaltung bedürfen oder eine
Antwort über den Messenger den formellen Ansprüchen nicht genügt. In solchen
Fällen verweisen wir Sie auf adäquatere Kommunikationswege.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Kontaktdaten (z.B. E-Mail,
Telefonnummern), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kommunikationspartner.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation,
Direktmarketing (z.B. per E-Mail oder postalisch).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Chatbots und Chatfunktionen</p>

<p  >&nbsp;</p>

<p  >Wir bieten als Kommunikationsmöglichkeit einen so genannten
„Chatbot“ an. Bei einem Chatbot handelt es sich um eine Software, die Fragen
der Nutzer beantwortet oder sie über Nachrichten informiert. Wenn Sie sich mit
unserem Chatbot unterhalten, können wir Ihre personenbezogenen Daten
verarbeiten.</p>

<p  >&nbsp;</p>

<p  >Falls Sie mit dem Chatbot innerhalb einer Online-Plattform
kommunizieren, wird zusätzlich Ihre Identifikationsnummer innerhalb der
jeweiligen Plattform gespeichert. Wir können zudem Informationen darüber
erheben, welche Nutzer wann mit unserem Chatbot interagieren. Ferner speichern
wir den Inhalt Ihrer Konversationen mit dem Chatbot und protokollieren
Registrierungs- und Einwilligungsvorgänge, um diese nach gesetzlichen Vorgaben
nachweisen zu können.</p>

<p  >&nbsp;</p>

<p  >Wir weisen Sie, darauf hin, dass der jeweilige
Plattformanbieter in Erfahrung bringen kann, dass und wann Nutzer mit unserem
Chatbot kommunizieren sowie technische Informationen zum verwendeten Gerät der
Nutzer und je nach Einstellungen ihres Gerätes auch Standortinformationen
(sogenannte Metadaten) zu Zwecken der Optimierung der jeweiligen Dienste und
Zwecken der Sicherheit erheben kann. Ebenfalls könnten die Metadaten der
Kommunikation via Chatbot (d.h. z.B., die Information, wer mit wem kommuniziert
hat) durch die jeweiligen Plattformanbieter nach Maßgabe ihrer Bestimmungen,
auf die wir zwecks weiterer Information verweisen, für Zwecke des Marketings
oder zur Anzeige auf Nutzer zugeschnittener Werbung verwendet werden.</p>

<p  ><span>&nbsp;</span></p>

<p  >Sofern Nutzer sich gegenüber dem Chatbot bereiterklären,
Informationen mit regelmäßigen Nachrichten zu aktivieren, steht ihnen jederzeit
die Möglichkeit zur Verfügung, die Informationen für die Zukunft abzubestellen.
Der Chatbot weist Nutzer darauf hin, wie und mit welchen Begriffen sie die
Nachrichten abbestellen können. Mit dem Abbestellen der <span >Chatbotnachrichten</span>
werden Daten der Nutzer aus dem Verzeichnis der Nachrichtenempfänger gelöscht.</p>

<p  >&nbsp;</p>

<p  >Die vorgenannten Angaben nutzen wir, um unseren Chatbot zu
betreiben, z.B., um Nutzer persönlich anzusprechen, um ihre Anfragen gegenüber
dem Chatbot zu beantworten, etwaige angeforderte Inhalte zu übermitteln und
auch, um unseren Chatbot zu verbessern (z.B., um ihm Antworten auf häufig
gestellte Fragen „beizubringen“ oder unbeantwortete Anfragen zu erkennen).</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Wir setzen den Chatbot auf
Grundlage einer Einwilligung ein, wenn wir zuvor eine Erlaubnis der Nutzer in
die Verarbeitung ihrer Daten durch den Chatbot eingeholt haben (dies gilt für
die Fälle, in denen Nutzer um eine Einwilligung gebeten werden, z.B., damit der
Chatbot ihnen regelmäßig Nachrichten zusendet). Sofern wir den Chatbot
einsetzen, um Anfragen der Nutzer zu unseren Leistungen oder unserem
Unternehmen zu beantworten, erfolgt dies zur vertraglichen und vorvertraglichen
Kommunikation. Im Übrigen setzen wir den Chatbot auf Grundlage unserer
berechtigten Interessen an einer Optimierung des Chatbots, dessen
Betriebswirtschaftlichkeit sowie einer Steigerung der positiven Nutzererfahrung
ein.</p>

<p  >&nbsp;</p>

<p  >Widerruf, Widerspruch und Löschung: Sie können jederzeit
eine erteilte Einwilligung widerrufen oder der Verarbeitung Ihrer Daten im
Rahmen unserer <span >Chatbotnutzung</span> widersprechen.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Kontaktdaten (z.B. E-Mail,
Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kommunikationspartner.</p>

<p  ><span>&nbsp;</span></p>

<p  >Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation,
Direktmarketing (z.B. per E-Mail oder postalisch).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Umfragen und Befragungen&nbsp;</p>

<p  >&nbsp;</p>

<p  >Die von uns durchgeführten Umfragen und Befragungen
(nachfolgend „Befragungen“) werden anonym ausgewertet. Eine Verarbeitung
personenbezogener Daten erfolgt nur insoweit, als dies zu Bereitstellung und
technischen Durchführung der Umfragen erforderlich ist (z.B. Verarbeitung der
IP-Adresse, um die Umfrage im Browser des Nutzers darzustellen oder mithilfe
eines temporären Cookies (Session-Cookie) eine Wiederaufnahme der Umfrage zu
ermöglichen) oder Nutzer eingewilligt haben.</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Sofern wir die Teilnehmer um
eine Einwilligung in die Verarbeitung <span >iherer</span> Daten
bitten, ist diese Rechtsgrundlage der Verarbeitung, ansonsten erfolgt die
Verarbeitung der Daten der Teilnehmer auf Grundlage unserer berechtigten
Interessen an der Durchführung einer objektiven Umfrage.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Kontaktdaten (z.B. E-Mail,
Telefonnummern), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos),
Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kommunikationspartner.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation,
Direktmarketing (z.B. per E-Mail oder postalisch).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Bereitstellung des Onlineangebotes und Webhosting&nbsp;</p>

<p  >&nbsp;</p>

<p  >Um unser Onlineangebot sicher und effizient bereitstellen zu
können, nehmen wir die Leistungen von einem oder mehreren Webhosting-Anbietern
in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das
Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir
Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz
und Datenbankdienste sowie Sicherheitsleistungen und technische
Wartungsleistungen in Anspruch nehmen.</p>

<p  ><span>&nbsp;</span></p>

<p  >Zu den im Rahmen der Bereitstellung des <span >Hostingangebotes</span>
verarbeiteten Daten können alle die Nutzer unseres Onlineangebotes betreffenden
Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen.
Hierzu gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von
Onlineangeboten an Browser ausliefern zu können, und alle innerhalb unseres
Onlineangebotes oder von Webseiten getätigten Eingaben.</p>

<p  >&nbsp;</p>

<p  >E-Mail-Versand und -Hosting: Die von uns in Anspruch
genommenen Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang
sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der
Empfänger sowie Absender als auch weitere Informationen betreffend den
E-Mailversand (z.B. die beteiligten Provider) sowie die Inhalte der jeweiligen
E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der
Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails
im Internet grundsätzlich nicht verschlüsselt versendet werden. Im Regelfall
werden E-Mails zwar auf dem Transportweg verschlüsselt, aber (sofern kein
sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf
den Servern, von denen sie abgesendet und empfangen werden. Wir können daher
für den Übertragungsweg der E-Mails zwischen dem Absender und dem Empfang auf
unserem Server keine Verantwortung übernehmen.</p>

<p  >&nbsp;</p>

<p  >Erhebung von Zugriffsdaten und Logfiles: Wir selbst (bzw.
unser <span >Webhostinganbieter</span>) erheben Daten zu jedem
Zugriff auf den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles
können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf,
Browsertyp nebst Version, das Betriebssystem des Nutzers, <span >Referrer</span>
URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende
Provider gehören.</p>

<p  >&nbsp;</p>

<p  >Die Serverlogfiles können zum einen zu Zwecken der
Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
(insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre
Stabilität sicherzustellen.</p>

<p  >&nbsp;</p>

<p  >Content-<span >Delivery</span>-Network: Wir
setzen ein „Content-<span >Delivery</span>-Network“ (CDN) ein. Ein
CDN ist ein Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes,
insbesondere große Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe
regional verteilter und über das Internet verbundener Server schneller und
sicherer ausgeliefert werden können.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Inhaltsdaten (z.B. Texteingaben,
Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  ><span>&nbsp;</span></p>

<p  >Zwecke der Verarbeitung: Content <span >Delivery</span>
Network (CDN).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Bewerbungsverfahren</p>

<p  >&nbsp;</p>

<p  >Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die
für deren Beurteilung und Auswahl erforderlichen Daten mitteilen. Welche
Informationen erforderlich sind, ergibt sich aus der Stellenbeschreibung oder
im Fall von Onlineformularen aus den dortigen Angaben.</p>

<p  >&nbsp;</p>

<p  >Grundsätzlich gehören zu den erforderlichen Angaben, die
Informationen zur Person, wie der Name, die Adresse, eine Kontaktmöglichkeit
sowie die Nachweise über die für eine Stelle notwendigen Qualifikationen. Auf
Anfragen teilen wir zusätzlich gerne mit, welche Angaben benötigt werden.</p>

<p  >&nbsp;</p>

<p  >Sofern zur Verfügung gestellt, können uns Bewerber ihre
Bewerbungen mittels eines Onlineformulars übermitteln. Die Daten werden
entsprechend dem Stand der Technik verschlüsselt an uns übertragen.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bewerberdaten (z.B. Angaben zur
Person, Post- und Kontaktadressen, die zur Bewerbung gehörenden Unterlagen und
die darin enthaltenen Informationen, wie z.B. Anschreiben, Lebenslauf,
Zeugnisse sowie weitere im Hinblick auf eine konkrete Stelle oder freiwillig
von Bewerbern mitgeteilte Informationen zu deren Person oder Qualifikation).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Bewerber.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Bewerbungsverfahren (Begründung und
etwaige spätere Durchführung sowie mögliche spätere Beendigung des
Beschäftigungsverhältnisses.).</p>

<p  ><span>&nbsp;</span></p>

<p  >Rechtsgrundlagen: Art. 9 Abs. <span  >1</span> S.
1 <span >lit</span>. b DSGVO (Bewerbungsverfahren als
vorvertragliches bzw. vertragliches Verhältnis) (Soweit im Rahmen des
Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne
des Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten, wie Schwerbehinderteneigenschaft
oder ethnische Herkunft) bei Bewerbern angefragt werden, damit der
Verantwortliche oder die betroffene Person die ihm bzw. ihr aus dem
Arbeitsrecht und dem Recht der sozialen Sicherheit und des Sozialschutzes
erwachsenden Rechte ausüben und seinen bzw. ihren diesbezüglichen Pflichten
nachkommen kann, erfolgt deren Verarbeitung nach Art. 9 Abs. 2 <span
>lit</span>. b. DSGVO, im Fall des Schutzes lebenswichtiger
Interessen der Bewerber oder anderer Personen gem. Art. 9 Abs. 2 <span
>lit</span>. c. DSGVO oder für Zwecke der Gesundheitsvorsorge oder
der Arbeitsmedizin, für die Beurteilung der Arbeitsfähigkeit des Beschäftigten,
für die medizinische Diagnostik, die Versorgung oder Behandlung im Gesundheits-
oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im
Gesundheits- oder Sozialbereich gem. Art. 9 Abs. 2 <span >lit</span>.
h. DSGVO. Im Fall einer auf freiwilliger Einwilligung beruhenden Mitteilung von
besonderen Kategorien von Daten, erfolgt deren Verarbeitung auf Grundlage von
Art. 9 Abs. 2 <span >lit</span>. a. DSGVO.).</p>

<p  >&nbsp;</p>

<p  >Newsletter und Breitenkommunikation</p>

<p  >&nbsp;</p>

<p  >Wir versenden Newsletter, E-Mails und weitere elektronische
Benachrichtigungen (nachfolgend „Newsletter“) nur mit der Einwilligung der
Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung
zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die
Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter
Informationen zu unseren Leistungen und uns.</p>

<p  >&nbsp;</p>

<p  >Um sich zu unseren Newslettern anzumelden, reicht es
grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir können Sie jedoch
bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere
Angaben, sofern diese für die Zwecke des Newsletters erforderlich sind, zu
tätigen.</p>

<p  >&nbsp;</p>

<p  >Double-<span >Opt</span>-In-Verfahren: Die
Anmeldung zu unserem Newsletter erfolgt grundsätzlich in einem sogenannte
Double-<span >Opt</span>-In-Verfahren. D.h., Sie erhalten nach der
Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten
werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden
E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden
protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen
nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des
Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen
Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.</p>

<p  >&nbsp;</p>

<p  >Löschung und Einschränkung der Verarbeitung: Wir können die
ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage unserer
berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals
gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird
auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein
individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur
dauerhaften Beachtung von Widersprüchen behalten wir uns die Speicherung der
E-Mail-Adresse <span  >alleine</span> zu diesem Zweck in einer
Sperrliste (sogenannte „Blacklist“) vor.</p>

<p  >&nbsp;</p>

<p  >Die Protokollierung des Anmeldeverfahrens erfolgt auf
Grundlage unserer berechtigten Interessen zu Zwecken des Nachweises seines
ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von
E-Mails beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen
an einem effizienten und sicheren Versandsystem.</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Der Versand der Newsletter
erfolgt auf Grundlage einer Einwilligung der Empfänger oder, falls eine
Einwilligung nicht erforderlich ist, auf Grundlage unserer berechtigten
Interessen am Direktmarketing, sofern und soweit diese gesetzlich, z.B. im Fall
von Bestandskundenwerbung, erlaubt ist. Soweit wir einen Dienstleister mit dem
Versand von E-Mails beauftragen, geschieht dies auf der Grundlage unserer
berechtigten Interessen. Das Registrierungsverfahren wird auf der Grundlage unserer
berechtigten Interessen aufgezeichnet, um nachzuweisen, dass es in
Übereinstimmung mit dem Gesetz durchgeführt wurde.</p>

<p  >&nbsp;</p>

<p  >Inhalte: Informationen zu uns, unseren Leistungen, Aktionen
und Angeboten.</p>

<p  >&nbsp;</p>

<p  >Erfolgsmessung: Die Newsletter enthalten einen sogenannte
„web-<span >beacon</span>“, d.h., eine pixelgroße Datei, die beim
Öffnen des Newsletters von unserem Server, bzw., sofern wir einen
Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im Rahmen
dieses Abrufs werden zunächst technische Informationen, wie Informationen zum
Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des
Abrufs, erhoben.</p>

<p  >&nbsp;</p>

<p  >Diese Informationen werden zur technischen Verbesserung
unseres Newsletters anhand der technischen Daten oder der Zielgruppen und ihres
Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse
bestimmbar sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet
ebenfalls die Feststellung, ob die Newsletter geöffnet werden, wann sie
geöffnet werden und welche Links geklickt werden. Diese Informationen können
aus technischen Gründen zwar den einzelnen <span >Newsletterempfängern</span>
zugeordnet werden. Es ist jedoch weder unser Bestreben noch, sofern eingesetzt,
<span  >das</span> des Versanddienstleisters, einzelne Nutzer zu
beobachten. Die Auswertungen dienen uns vielmehr dazu, die Lesegewohnheiten
unserer Nutzer zu erkennen und unsere Inhalte an sie anzupassen oder
unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu
versenden.</p>

<p  >&nbsp;</p>

<p  >Die Auswertung des Newsletters und die Erfolgsmessung
erfolgen, vorbehaltlich einer ausdrücklichen Einwilligung der Nutzer, auf
Grundlage unserer berechtigten Interessen zu Zwecken des Einsatzes eines
nutzerfreundlichen sowie sicheren <span >Newslettersystems</span>,
welches sowohl unseren geschäftlichen Interessen <span  >dient,</span>
als auch den Erwartungen der Nutzer entspricht.</p>

<p  ><span>&nbsp;</span></p>

<p  >Ein getrennter Widerruf der Erfolgsmessung ist leider nicht
möglich, in diesem Fall muss das gesamte <span >Newsletterabonnement</span>
gekündigt, bzw. muss ihm widersprochen werden.</p>

<p  >&nbsp;</p>

<p  >Voraussetzung der Inanspruchnahme kostenloser Leistungen:
Die Einwilligungen in den Versand von Mailings kann als Voraussetzung zur
Inanspruchnahme kostenloser Leistungen (z.B. Zugang zu bestimmten Inhalten oder
Teilnahme an bestimmten Aktionen) abhängig gemacht werden. Sofern die Nutzer
die kostenlose Leistung in Anspruch nehmen möchten, ohne sich zum Newsletter
anzumelden, bitten wir Sie um eine Kontaktaufnahme.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen),
Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kommunikationspartner, Nutzer (z.B.
Webseitenbesucher, Nutzer von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Direktmarketing (z.B. per E-Mail
oder postalisch), Vertragliche Leistungen und Service.</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Widerspruchsmöglichkeit (<span >Opt</span>-Out):
Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link
zur Kündigung des Newsletters finden Sie entweder am Ende eines jeden
Newsletters oder können sonst eine der oben angegebenen Kontaktmöglichkeiten,
vorzugswürdig E-Mail, hierzu nutzen.</p>

<p  >&nbsp;</p>

<p  >Gewinnspiele und Wettbewerbe</p>

<p  >&nbsp;</p>

<p  >Wir verarbeiten personenbezogene Daten der Teilnehmer von
Gewinnspielen und Wettbewerben nur unter Einhaltung der einschlägigen
Datenschutzbestimmungen, soweit die Verarbeitung zur Bereitstellung,
Durchführung und Abwicklung des Gewinnspiels vertraglich erforderlich ist, die
Teilnehmer in die Verarbeitung eingewilligt haben oder die Verarbeitung unseren
berechtigten Interessen dient (z.B. an der Sicherheit des Gewinnspiels oder dem
Schutz unserer Interessen vor Missbrauch durch mögliche Erfassung von IP-Adressen
bei Einreichung von Gewinnspielbeiträgen).</p>

<p  >&nbsp;</p>

<p  >Falls im Rahmen der Gewinnspiele Beiträge der Teilnehmer
veröffentlicht werden (z.B. im Rahmen einer Abstimmung oder Präsentation der
Gewinnspielbeiträge bzw. der Gewinner oder der Berichterstattung zum
Gewinnspiel), weisen wir darauf hin, dass die Namen der Teilnehmer in diesem
Zusammenhang ebenfalls veröffentlicht werden können. Die Teilnehmer können dem
jederzeit widersprechen.</p>

<p  >&nbsp;</p>

<p  >Findet das Gewinnspiel innerhalb einer Online-Plattform oder
eines sozialen Netzwerks (z.B. Facebook oder Instagram, nachfolgend bezeichnet
als „Online-Plattform“) statt, gelten zusätzlich die Nutzungs- und
Datenschutzbestimmungen der jeweiligen Plattformen. In diesen Fällen weisen wir
darauf hin, dass wir für die im Rahmen des Gewinnspiels mitgeteilten Angaben
der Teilnehmer verantwortlich sind und Anfragen im Hinblick auf das Gewinnspiel
an uns zu richten sind.</p>

<p  >&nbsp;</p>

<p  >Die Daten der Teilnehmer werden gelöscht, sobald das
Gewinnspiel oder der Wettbewerb beendet sind und die Daten nicht mehr
erforderlich sind, um die Gewinner zu informieren oder weil mit Rückfragen zum
Gewinnspiel zu rechnen ist. Grundsätzlich werden die Daten der Teilnehmer
spätestens 6 Monate nach Ende des Gewinnspiels gelöscht. Daten der Gewinner
können länger einbehalten werden, um z.B. Rückfragen zu den Gewinnen
beantworten oder die Gewinnleistungen erfüllen zu können; in diesem Fall
richtet sich die Aufbewahrungsdauer nach der Art des Gewinns und beträgt z.B.
bei Sachen oder Leistungen bis zu drei Jahre, um z.B. Gewährleistungsfälle
bearbeiten zu können. Ferner können die Daten der Teilnehmer länger gespeichert
werden, z.B. in Form der Berichterstattung zum Gewinnspiel in Online- und
Offline-Medien.</p>

<p  >&nbsp;</p>

<p  >Sofern Daten im Rahmen des Gewinnspiels auch zu anderen
Zwecken erhoben wurden, richten sich deren Verarbeitung und die
Aufbewahrungsdauer nach den Datenschutzhinweisen zu dieser Nutzung (z.B. im
Fall einer Anmeldung zum Newsletter im Rahmen eines Gewinnspiels).</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Gewinnspiel- und Wettbewerbsteilnehmer.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Durchführung von Gewinnspielen und
Wettbewerben.</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Vertragserfüllung und vorvertragliche
Anfragen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
b. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Webanalyse und Optimierung</p>

<p  >&nbsp;</p>

<p  >Die Webanalyse (auch als „Reichweitenmessung“ bezeichnet)
dient der Auswertung der Besucherströme unseres Onlineangebotes und kann
Verhalten, Interessen oder demographische Informationen zu den Besuchern, wie
z.B. das Alter oder das Geschlecht, als pseudonyme Werte umfassen. Mit Hilfe
der Reichweitenanalyse können wir z.B. erkennen, zu welcher Zeit unser Onlineangebot
oder dessen Funktionen oder Inhalte am häufigsten genutzt werden oder zur
Wiederverwendung einladen. Ebenso können wir nachvollziehen, welche Bereiche
der Optimierung bedürfen.</p>

<p  >&nbsp;</p>

<p  >Neben der Webanalyse können wir auch Testverfahren
einsetzen, um z.B. unterschiedliche Versionen unseres Onlineangebotes oder
seiner Bestandteile zu testen und optimieren.</p>

<p  >&nbsp;</p>

<p  >Zu diesen Zwecken können sogenannte Nutzerprofile angelegt
und in einer Datei (sogenannte „Cookie“) gespeichert oder ähnliche Verfahren
mit dem gleichen Zweck genutzt werden. Zu diesen Angaben können z.B.
betrachtete Inhalte, besuchte Webseiten und dort genutzte Elemente und
technische Angaben, wie der verwendete Browser, das verwendete Computersystem
sowie Angaben zu Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer
Standortdaten eingewilligt haben, können je nach Anbieter auch diese verarbeitet
werden.</p>

<p  >&nbsp;</p>

<p  >Es werden ebenfalls die IP-Adressen der Nutzer gespeichert.
Jedoch nutzen wir ein IP-<span >Masking</span>-Verfahren (d.h.,
Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell
werden die im Rahmen von Webanalyse, A/B-<span >Testings</span> und
Optimierung keine Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
eingesetzten Software kennen nicht die tatsächliche Identität der Nutzer,
sondern nur den für Zwecke der jeweiligen Verfahren in deren Profilen
gespeicherten Angaben.</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der
Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Reichweitenmessung (z.B.
Zugriffsstatistiken, Erkennung wiederkehrender Besucher), Tracking (z.B.
interessens-/verhaltensbezogenes <span >Profiling</span>, Nutzung
von Cookies), Besuchsaktionsauswertung, <span >Profiling</span>
(Erstellen von Nutzerprofilen).</p>

<p  >&nbsp;</p>

<p  >Sicherheitsmaßnahmen: IP-<span >Masking</span>
(Pseudonymisierung der IP-Adresse).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Onlinemarketing</p>

<p  >&nbsp;</p>

<p  >Wir verarbeiten personenbezogene Daten zu Zwecken des
Onlinemarketings, worunter insbesondere die Vermarktung von Werbeflächen oder
Darstellung von werbenden und sonstigen Inhalten (zusammenfassend als „Inhalte“
bezeichnet) anhand <span  >potentieller</span> Interessen der Nutzer
sowie die Messung ihrer Effektivität fallen kann.</p>

<p  >&nbsp;</p>

<p  >Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt
und in einer Datei (sogenannte „Cookie“) gespeichert oder ähnliche Verfahren
genutzt, mittels derer die für die Darstellung der vorgenannten Inhalte
relevante Angaben zum Nutzer gespeichert werden. Zu diesen Angaben können z.B.
betrachtete Inhalte, besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
Kommunikationspartner und technische Angaben, wie der verwendete Browser, das
verwendete Computersystem sowie Angaben zu Nutzungszeiten gehören. Sofern
Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben, können auch
diese verarbeitet werden.</p>

<p  >&nbsp;</p>

<p  >Es werden ebenfalls die IP-Adressen der Nutzer gespeichert.
Jedoch nutzen wir zur Verfügung stehende IP-<span >Masking</span>-Verfahren
(d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum Schutz der Nutzer.
Generell werden im Rahmen des Onlinemarketingverfahren keine Klardaten der
Nutzer (wie z.B. E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme.
D.h., wir als auch die Anbieter der Onlinemarketingverfahren kennen nicht <span
 >die tatsächlich Identität</span> der Nutzer, sondern nur die in
deren Profilen gespeicherten Angaben.</p>

<p  >&nbsp;</p>

<p  >Die Angaben in den Profilen werden im Regelfall in den
Cookies oder mittels ähnlicher Verfahren gespeichert. Diese Cookies können
später generell auch auf anderen <span  >Webseiten</span> die
dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken der
Darstellung von Inhalten analysiert als auch mit weiteren Daten ergänzt und auf
dem Server des Onlinemarketingverfahrensanbieters gespeichert werden.</p>

<p  >&nbsp;</p>

<p  >Ausnahmsweise können Klardaten den Profilen zugeordnet
werden. Das ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen
Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen und das Netzwerk
die Profile der Nutzer <span  >im den vorgenannten Angaben</span>
verbindet. Wir bitten darum, zu beachten, dass Nutzer mit den Anbietern
zusätzliche Abreden, z.B. durch Einwilligung im Rahmen der Registrierung,
treffen können.</p>

<p  >&nbsp;</p>

<p  >Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können wir im
Rahmen sogenannter Konversionsmessungen prüfen, welche unserer
Onlinemarketingverfahren zu einer sogenannten Konversion geführt haben, d.h.
z.B., zu einem Vertragsschluss mit uns. Die Konversionsmessung wird <span
 >alleine</span> zur Analyse des Erfolgs unserer Marketingmaßnahmen
verwendet.</p>

<p  ><span>&nbsp;</span></p>

<p  >Solange nicht anders angegeben, bitten wir Sie davon
auszugehen, dass verwendete Cookies für <span >einene</span>
Zeitraum von zwei Jahren gespeichert werden.</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der
Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>

<p  >&nbsp;</p>

<p  >Facebook-Pixel: Mit Hilfe des Facebook-Pixels ist es
Facebook zum einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe
für die Darstellung von Anzeigen (sogenannte „Facebook-Ads“) zu bestimmen.
Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns
geschalteten Facebook-Ads nur solchen Nutzern bei Facebook und innerhalb der
Dienste der mit Facebook kooperierenden Partner (so genanntes „<span
>Audience</span> Network“ https://www.facebook.com/audiencenetwork/
) anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben
oder die bestimmte Merkmale (z.B. Interesse an bestimmten Themen oder
Produkten, die anhand der besuchten Webseiten ersichtlich werden) aufweisen,
die wir an Facebook übermitteln (sogenannte „Custom <span >Audiences</span>“).
Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere
Facebook-Ads dem <span  >potentiellen</span> Interesse der Nutzer
entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können
wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
Marktforschungszwecke nachvollziehen, indem wir sehen, ob Nutzer nach dem Klick
auf eine Facebook-Werbeanzeige auf unsere Webseite weitergeleitet wurden
(sogenannte „Konversionsmessung“).</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte
Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
(z.B. Geräte-Informationen, IP-Adressen), Standortdaten (Daten, die den
Standort des Endgeräts eines Endnutzers angeben).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten), Interessenten.</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Tracking (z.B.
interessens-/verhaltensbezogenes <span >Profiling</span>, Nutzung
von Cookies), <span >Remarketing</span>, Besuchsaktionsauswertung,
Interessenbasiertes und verhaltensbezogenes Marketing, <span >Profiling</span>
(Erstellen von Nutzerprofilen), Konversionsmessung (Messung der Effektivität
von Marketingmaßnahmen), Reichweitenmessung (z.B. Zugriffsstatistiken,
Erkennung wiederkehrender Besucher), Zielgruppenbildung (Bestimmung von für
Marketingzwecke relevanten Zielgruppen oder sonstige Ausgabe von Inhalten), Cross-Device
Tracking (geräteübergreifende Verarbeitung von Nutzerdaten für
Marketingzwecke).</p>

<p  >&nbsp;</p>

<p  >Sicherheitsmaßnahmen: IP-<span >Masking</span>
(Pseudonymisierung der IP-Adresse).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Widerspruchsmöglichkeit (<span >Opt</span>-Out):
Wir verweisen auf die Datenschutzhinweise der jeweiligen Anbieter und die zu
den Anbietern angegebenen Widerspruchsmöglichkeiten (sog. „<span >Opt</span>-Out“).
Sofern keine explizite <span >Opt</span>-Out-Möglichkeit angegeben
wurde, besteht zum einen die Möglichkeit, dass Sie Cookies in den Einstellungen
Ihres Browsers abschalten. Hierdurch können jedoch Funktionen unseres
Onlineangebotes eingeschränkt werden. Wir empfehlen daher zusätzlich die folgenden
<span >Opt</span>-Out-Möglichkeiten, die zusammenfassend auf
jeweilige Gebiete gerichtet angeboten werden:</p>

<p  >&nbsp;</p>

<p  >Europa: <a href="https://www.youronlinechoices.eu"><span>https://www.youronlinechoices.eu</span></a>.</p>

<p  ><span>&nbsp;</span></p>

<p  >Gebietsübergreifend:<span> </span><a
href="http://optout.aboutads.info"><span>http://optout.aboutads.info</span></a>.<span
></span></p>

<p  ><span>&nbsp;</span></p>

<p  >Eingesetzte Dienste und Diensteanbieter:</p>

<p  ><span>&nbsp;</span></p>

<p  >Google Analytics: Onlinemarketing und Webanalyse;
Dienstanbieter: Google <span >Ireland</span> Limited, Gordon House,
Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 <span
>Amphitheatre</span> Parkway, Mountain View, CA 94043, USA;
Website: https://marketingplatform.google.com/intl/de/about/analytics/;
Datenschutzerklärung: https://policies.google.com/privacy; Privacy Shield
(Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active;
Widerspruchsmöglichkeit (<span >Opt</span>-Out): <span
>Opt</span>-Out-Plugin:
http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die
Darstellung von Werbeeinblendungen: <a
href="https://adssettings.google.com/authenticated"><span>https://adssettings.google.com/authenticated</span></a>.</p>

<p  >&nbsp;</p>

<p  >Facebook-Pixel: Facebook-Pixel; Dienstanbieter:
https://www.facebook.com, Facebook <span >Ireland</span> Ltd., 4
Grand <span >Canal</span> Square, Grand <span >Canal</span>
Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo
Park, CA 94025, USA; Website: https://www.facebook.com; Datenschutzerklärung:
https://www.facebook.com/about/privacy; Privacy Shield (Gewährleistung
Datenschutzniveau bei Verarbeitung von Daten in den USA):
https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active;
Widerspruchsmöglichkeit (<span >Opt</span>-Out): <a
href="https://www.facebook.com/settings?tab=ads"><span>https://www.facebook.com/settings?tab=ads</span></a>.</p>

<p  >&nbsp;</p>

<p  ><span >Affiliate</span>-Programme und <span
>Affiliate</span>-Links</p>

<p  >&nbsp;</p>

<p  >In unser Onlineangebot binden wir sogenannte <span
>Affiliate</span>-Links oder andere Verweise (zu denen z.B.
Rabatt-Codes gehören können) auf die Angebote und Leistungen von Drittanbietern
ein (zusammenfassend bezeichnet als „<span >Affiliate</span>-Links“).
Wenn Nutzer den <span >Affiliate</span>-Links folgen, bzw.
anschließend die Angebote wahrnehmen, können wir von diesen Drittanbietern eine
Provision oder sonstige Vorteile erhalten (zusammenfassend bezeichnet als
„Provision“).</p>

<p  ><span>&nbsp;</span></p>

<p  >Um nachverfolgen zu können, ob die Nutzer die Angebote eines
von uns eingesetzten <span >Affiliate</span>-Links wahrgenommen
haben, ist es notwendig, dass die jeweiligen Drittanbieter erfahren, dass die
Nutzer einem innerhalb unseres Onlineangebotes eingesetzten <span >Affiliate</span>-Link
gefolgt sind. Die Zuordnung der <span >Affiliate</span>-Links zu
den jeweiligen Geschäftsabschlüssen oder zu sonstigen Aktionen (z.B. Käufen)
dient <span  >alleine</span> dem Zweck der Provisionsabrechnung und
wird aufgehoben, sobald sie für den Zweck nicht mehr erforderlich ist.</p>

<p  >&nbsp;</p>

<p  >Für die Zwecke der vorgenannten Zuordnung der <span
>Affiliate</span>-Links können die <span >Affiliate</span>-Links
um bestimmte Werte ergänzt werden, die ein Bestandteil des Links sind oder
anderweitig, z.B. in einem Cookie, gespeichert werden können. Zu den Werten
können insbesondere die Ausgangswebseite (<span >Referrer</span>),
der Zeitpunkt, eine Online-Kennung der Betreiber der Webseite, auf der sich der
<span >Affiliate</span>-Link befand, eine Online-Kennung des
jeweiligen Angebotes, die Art des verwendeten Links, die Art des Angebotes und
eine Online-Kennung des Nutzers gehören.</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
der Verarbeitung von Daten die Einwilligung. Ferner kann deren Einsatz ein
Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der
Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der
Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Vertragsdaten (z.B.
Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte
Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
(z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: <span >Affiliate</span>-Nachverfolgung.</p>

<p  ><span>&nbsp;</span></p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO),
Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. <span  >1</span>
S. 1 <span >lit</span>. b. DSGVO), Berechtigte Interessen (Art. 6
Abs. <span  >1</span> S. 1 <span >lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Bewertungsplattformen</p>

<p  >&nbsp;</p>

<p  >Wir nehmen an Bewertungsverfahren teil, um unsere Leistungen
zu evaluieren, zu optimieren und zu bewerben. Wenn Nutzer uns über die
beteiligten Bewertungsplattformen oder -verfahren bewerten oder anderweitig
Feedback geben, gelten zusätzlich die Allgemeinen Geschäfts- oder
Nutzungsbedingungen und die Datenschutzhinweise der Anbieter. Im Regelfall
setzt die Bewertung zudem eine Registrierung bei den jeweiligen Anbietern
voraus.</p>

<p  >Um sicherzustellen, dass die bewertenden Personen
tatsächlich unsere Leistungen in Anspruch genommen haben, übermitteln wir mit
Einwilligung der Kunden die hierzu erforderlichen Daten im Hinblick auf den
Kunden und die in Anspruch genommene Leistung an die jeweilige
Bewertungsplattform (<span  >einschließlich Name</span>,
E-Mail-Adresse und Bestellnummer bzw. Artikelnummer). Diese Daten werden <span
 >alleine</span> zur Verifizierung der Authentizität des Nutzers
verwendet.</p>

<p  ><span>&nbsp;</span></p>

<p  >Bewertungs-Widget: Wir binden in unser Onlineangebot
sogenannte „Bewertungs-Widgets“ ein. Ein Widget ist ein in unser Onlineangebot
eingebundenes Funktions- und Inhaltselement, das veränderliche Informationen
anzeigt. Es kann z.B. in Form eines Siegels oder vergleichbaren Elements, zum
Teil auch „Badge“ genannt, dargestellt werden. Dabei wird der entsprechende
Inhalt des Widgets zwar innerhalb unseres Onlineangebotes dargestellt, er wird
aber in diesem Moment von den Servern des jeweiligen Widgets-Anbieters
abgerufen. Nur so kann immer der aktuelle Inhalt gezeigt werden, vor allem die
jeweils aktuelle Bewertung. Dafür muss eine <span  >Datenverbindung</span>
von der innerhalb unseres Onlineangebotes aufgerufenen Webseite zu dem Server
des Widgets-Anbieters aufgebaut werden und der Widgets-Anbieter erhält gewisse
technische Daten (Zugriffsdaten, inklusive IP-Adresse), die nötig sind, damit
der Inhalt des Widgets an den Browser des Nutzers ausgeliefert werden kann.</p>

<p  >&nbsp;</p>

<p  >Des Weiteren erhält der Widgets-Anbieter Informationen
darüber, dass Nutzer unser Onlineangebot besucht haben. Diese Informationen
können in einem Cookie gespeichert und von dem Widgets-Anbieter verwendet
werden, um zu erkennen, welche Onlineangebote, die am dem Bewertungsverfahren
teilnehmen, von dem Nutzer besucht worden sind. Die Informationen können in
einem Nutzerprofil gespeichert und für Werbe- oder Marktforschungszwecke
verwendet werden.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Vertragsdaten (z.B.
Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B. besuchte
Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
(z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kunden, Nutzer (z.B. Webseitenbesucher,
Nutzer von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Feedback (z.B. Sammeln von Feedback
via Online-Formular), Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
wiederkehrender Besucher), Besuchsaktionsauswertung, Interessenbasiertes und
verhaltensbezogenes Marketing, <span >Profiling</span> (Erstellen
von Nutzerprofilen).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO), Berechtigte
Interessen (Art. 6 Abs. <span  >1</span> S. 1 <span >lit</span>.
f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Präsenzen in sozialen Netzwerken</p>

<p  >&nbsp;</p>

<p  >Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke,
um mit den dort aktiven Nutzern zu kommunizieren oder um dort Informationen
über uns anzubieten.</p>

<p  >&nbsp;</p>

<p  >Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb
des Raumes der Europäischen Union verarbeitet werden können. Hierdurch können
sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter, die unter dem
Privacy-Shield zertifiziert sind oder vergleichbare Garantien eines sicheren
Datenschutzniveaus bieten, weisen wir darauf hin, dass sie sich damit
verpflichten, die Datenschutzstandards der EU einzuhalten.</p>

<p  ><span>&nbsp;</span></p>

<p  >Ferner werden die Daten der Nutzer innerhalb sozialer
Netzwerke im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So
können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen
der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum
verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Netzwerke
zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen
Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in
denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden.
Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern
verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder
der jeweiligen Plattformen sind und bei diesen eingeloggt sind).</p>

<p  >&nbsp;</p>

<p  >Für eine detaillierte Darstellung der jeweiligen
Verarbeitungsformen und der Widerspruchsmöglichkeiten (<span >Opt</span>-Out)
verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der
jeweiligen Netzwerke.</p>

<p  >&nbsp;</p>

<p  >Auch im Fall von Auskunftsanfragen und der Geltendmachung
von Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei
den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen
ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können
Sie sich an uns wenden.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten,
Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation,
Tracking (z.B. interessens-/verhaltensbezogenes <span >Profiling</span>,
Nutzung von Cookies), <span >Remarketing</span>, Reichweitenmessung
(z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Eingesetzte Dienste und Diensteanbieter:</p>

<p  >&nbsp;</p>

<p  ><span  >Instagram :</span> Soziales Netzwerk;
Dienstanbieter: Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA;
Website: https://www.instagram.com; Datenschutzerklärung: <a
href="http://instagram.com/about/legal/privacy"><span>http://instagram.com/about/legal/privacy</span></a>.</p>

<p  >&nbsp;</p>

<p  >Facebook: Soziales Netzwerk; Dienstanbieter: Facebook <span
>Ireland</span> Ltd., 4 Grand <span >Canal</span>
Square, Grand <span >Canal</span> Harbour, Dublin 2, Irland,
Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:
https://www.facebook.com; Datenschutzerklärung:
https://www.facebook.com/about/privacy; Privacy Shield (Gewährleistung
Datenschutzniveau bei Verarbeitung von Daten in den USA):
https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active;
Widerspruchsmöglichkeit (<span >Opt</span>-Out): Einstellungen für
Werbeanzeigen: https://www.facebook.com/settings?tab=ads; Zusätzliche Hinweise
zum Datenschutz: Vereinbarung über gemeinsame Verarbeitung personenbezogener
Daten auf Facebook-Seiten:
https://www.facebook.com/legal/terms/page_controller_addendum, Datenschutzhinweise
für Facebook-Seiten: <a
href="https://www.facebook.com/legal/terms/information_about_page_insights_data"><span
>https://www.facebook.com/legal/terms/information_about_page_insights_data</span></a>.</p>

<p  ><span>&nbsp;</span></p>

<p  >LinkedIn: Soziales Netzwerk; Dienstanbieter: LinkedIn <span
>Ireland</span> Unlimited Company, Wilton Place, Dublin 2, Irland;
Website: https://www.linkedin.com; Datenschutzerklärung:
https://www.linkedin.com/legal/privacy-policy; Privacy Shield (Gewährleistung
Datenschutzniveau bei Verarbeitung von Daten in den USA): https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active;
Widerspruchsmöglichkeit (<span >Opt</span>-Out): <a
href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"><span
>https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</span></a>.</p>

<p  >&nbsp;</p>

<p  >Pinterest: Soziales Netzwerk; Dienstanbieter: Pinterest
Inc., 635 High Street, Palo Alto, CA, 94301, <span  >USA,;</span>
Website: https://www.pinterest.com; Datenschutzerklärung:
https://about.pinterest.com/de/privacy-policy; Widerspruchsmöglichkeit (<span
>Opt</span>-Out): <a
href="https://about.pinterest.com/de/privacy-policy"><span>https://about.pinterest.com/de/privacy-policy</span></a>.</p>

<p  >&nbsp;</p>

<p  >Twitter: Soziales Netzwerk; Dienstanbieter: Twitter Inc.,
1355 Market Street, Suite 900, San Francisco, CA 94103, USA;
Datenschutzerklärung: https://twitter.com/de/privacy, (Einstellungen)
https://twitter.com/personalization; Privacy Shield (Gewährleistung
Datenschutzniveau bei Verarbeitung von Daten in den USA): <a
href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"><span
>https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</span></a>.</p>

<p  >&nbsp;</p>

<p  >YouTube: Soziales Netzwerk; Dienstanbieter: Google <span
>Ireland</span> Limited, Gordon House, Barrow Street, Dublin 4,
Irland, Mutterunternehmen: Google LLC, 1600 <span >Amphitheatre</span>
Parkway, Mountain View, CA 94043, USA; Datenschutzerklärung:
https://policies.google.com/privacy; Privacy Shield (Gewährleistung
Datenschutzniveau bei Verarbeitung von Daten in den USA):
https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active;
Widerspruchsmöglichkeit (<span >Opt</span>-Out): <a
href="https://adssettings.google.com/authenticated"><span>https://adssettings.google.com/authenticated</span></a>.</p>

<p  >&nbsp;</p>

<p  >Plugins und eingebettete Funktionen sowie Inhalte
(Überschrift 2)</p>

<p  >&nbsp;</p>

<p  >Wir binden in unser Onlineangebot Funktions- und
Inhaltselemente ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend
bezeichnet als „Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel
um Grafiken, Videos oder <span >Social</span>-Media-Schaltflächen
sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als „Inhalte”).</p>

<p  >&nbsp;</p>

<p  >Die Einbindung setzt immer voraus, dass die Drittanbieter
dieser Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse
ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir
bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter
können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons“
bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
„Pixel-Tags“ können Informationen, wie der Besucherverkehr auf den Seiten
dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können
ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem
technische Informationen zum Browser und zum Betriebssystem, zu verweisenden
Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres
Onlineangebotes enthalten als auch mit solchen Informationen aus anderen
Quellen verbunden werden.</p>

<p  >&nbsp;</p>

<p  >Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der
Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte
Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
(z.B. Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer
von Onlinediensten).</p>

<p  ><span>&nbsp;</span></p>

<p  >Zwecke der Verarbeitung: Bereitstellung unseres
Onlineangebotes und Nutzerfreundlichkeit, Vertragliche Leistungen und Service.</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Eingesetzte Dienste und Diensteanbieter:</p>

<p  >&nbsp;</p>

<p  >OpenStreetMap</p>

<p  >&nbsp;</p>

<p  >Google Maps: Wir binden die Landkarten des Dienstes “Google
Maps” des Anbieters Google ein. Zu den verarbeiteten Daten können insbesondere
IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren
Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte
vollzogen), erhoben werden. Dienstanbieter: Google <span >Ireland</span>
Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
Google LLC, 1600 <span >Amphitheatre</span> Parkway, Mountain View,
CA 94043, USA; Website: https://maps.google.de; Datenschutzerklärung:
https://policies.google.com/privacy; Privacy Shield (Gewährleistung
Datenschutzniveau bei Verarbeitung von Daten in den USA):
https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active;
Widerspruchsmöglichkeit (<span >Opt</span>-Out): <span
>Opt</span>-Out-Plugin:
http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die
Darstellung von Werbeeinblendungen: <a
href="https://adssettings.google.com/authenticated"><span>https://adssettings.google.com/authenticated</span></a>.</p>

<p  >&nbsp;</p>

<p  >Planung, Organisation und Hilfswerkzeuge (Überschrift 2)</p>

<p  >&nbsp;</p>

<p  >Wir setzen Dienstleistungen, Plattformen und Software
anderer Anbieter (nachfolgend bezeichnet als „Drittanbieter”) zu Zwecken der
Organisation, Verwaltung, Planung sowie Erbringung unserer Leistungen ein. Bei
der Auswahl der Drittanbieter und ihrer Leistungen beachten wir die
gesetzlichen Vorgaben.</p>

<p  >&nbsp;</p>

<p  >In diesem Rahmen können personenbezogenen Daten verarbeitet
und auf den Servern der Drittanbieter gespeichert werden. Hiervon können
diverse Daten betroffen sein, die wir entsprechend dieser Datenschutzerklärung
verarbeiten. Zu diesen Daten können insbesondere Stammdaten und Kontaktdaten
der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren
Inhalte gehören.</p>

<p  >&nbsp;</p>

<p  >Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts-
oder anderen Beziehungen mit uns auf die Drittanbieter bzw. deren Software oder
Plattformen verwiesen werden, können die Drittanbieter Nutzungsdaten und
Metadaten zu Sicherheitszwecken, zur Serviceoptimierung oder zu
Marketingzwecken verarbeiten. Wir bitten daher darum, die Datenschutzhinweise
der jeweiligen Drittanbieter zu beachten.</p>

<p  ><span>&nbsp;</span></p>

<p  >Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
Einwilligung in den Einsatz der Drittanbieter bitten, ist die Rechtsgrundlage
der Verarbeitung von Daten die Einwilligung. Ferner kann deren Einsatz ein
Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der
Drittanbieter in diesem Rahmen vereinbart wurde. Ansonsten werden die Daten der
Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen)
verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>

<p  >&nbsp;</p>

<p  >Verarbeitete Datenarten: Bestandsdaten (z.B. Namen,
Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten,
Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
Geräte-Informationen, IP-Adressen).</p>

<p  >&nbsp;</p>

<p  >Betroffene Personen: Kommunikationspartner, Nutzer (z.B.
Webseitenbesucher, Nutzer von Onlinediensten).</p>

<p  >&nbsp;</p>

<p  >Rechtsgrundlagen: Einwilligung (Art. 6 Abs. <span
 >1</span> S. 1 <span >lit</span>. a DSGVO),
Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. <span  >1</span>
S. 1 <span >lit</span>. b. DSGVO), Berechtigte Interessen (Art. 6
Abs. <span  >1</span> S. 1 <span >lit</span>. f. DSGVO).</p>

<p  >&nbsp;</p>

<p  >Löschung von Daten</p>

<p  >&nbsp;</p>

<p  >Die von uns verarbeiteten Daten werden nach Maßgabe der
gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B.,
wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den
Zweck nicht erforderlich sind).</p>

<p  >&nbsp;</p>

<p  >Sofern die Daten nicht gelöscht werden, weil sie für andere
und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung auf
diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere
Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur
Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.</p>

<p  >&nbsp;</p>

<p  >Weitere Hinweise zu der Löschung von personenbezogenen Daten
können ferner im Rahmen der einzelnen Datenschutzhinweise dieser
Datenschutzerklärung erfolgen.</p>

<p  >&nbsp;</p>

<p  >Änderung und Aktualisierung der Datenschutzerklärung</p>

<p  >&nbsp;</p>

<p  >Wir bitten Sie, sich regelmäßig über den Inhalt unserer
Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an,
sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies
erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine
Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
individuelle Benachrichtigung erforderlich wird.</p>

<p  ><span>&nbsp;</span></p>

<p  >Sofern wir in dieser Datenschutzerklärung Adressen und
Kontaktinformationen von Unternehmen und Organisationen angeben, bitten wir zu
beachten, dass die Adressen sich über die Zeit ändern können und bitten die
Angaben vor Kontaktaufnahme zu prüfen.</p>

<p  >&nbsp;</p>

<p  >Rechte der betroffenen Personen</p>

<p  >&nbsp;</p>

<p  >Ihnen stehen als Betroffene nach der DSGVO verschiedene
Rechte zu, die sich insbesondere aus Art. 15 bis 18 und 21 DSGVO ergeben:</p>

<p  >&nbsp;</p>

<p  >Widerspruchsrecht: Sie haben das Recht, aus Gründen, die
sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 <span
>lit</span>. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
gilt auch für ein auf diese Bestimmungen gestütztes <span >Profiling</span>.
Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen
die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
derartiger Werbung einzulegen; dies gilt auch für das <span >Profiling</span>,
soweit es mit solcher Direktwerbung in Verbindung steht.</p>

<p  >&nbsp;</p>

<p  >Widerrufsrecht bei Einwilligungen: Sie haben das Recht,
erteilte Einwilligungen jederzeit zu widerrufen.</p>

<p  >&nbsp;</p>

<p  >Auskunftsrecht: Sie haben das Recht, eine Bestätigung
darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft
über diese Daten sowie auf weitere Informationen und Kopie der Daten
entsprechend den gesetzlichen Vorgaben.</p>

<p  >&nbsp;</p>

<p  >Recht auf Berichtigung: Sie haben entsprechend den
gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden
Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
verlangen.</p>

<p  ><span>&nbsp;</span></p>

<p  >Recht auf Löschung und Einschränkung der Verarbeitung: Sie
haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie
betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe
der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu
verlangen.</p>

<p  >&nbsp;</p>

<p  >Recht auf Datenübertragbarkeit: Sie haben das Recht, Sie
betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der
gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren
Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu
fordern.</p>

<p  >&nbsp;</p>

<p  >Beschwerde bei Aufsichtsbehörde: Sie haben ferner nach
Maßgabe der gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde,
insbesondere in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres
Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht
sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
die DSGVO verstößt.</p>

<p  >&nbsp;</p>

<p  >Begriffsdefinitionen&nbsp;</p>

<p  >&nbsp;</p>

<p  >In diesem Abschnitt erhalten Sie eine Übersicht über die in
dieser Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen
sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch
sortiert.</p>

<p  >&nbsp;</p>

<p  ><span >Affiliate</span>-Nachverfolgung: Im
Rahmen der <span >Affiliate</span>-Nachverfolgung werden Links, mit
deren Hilfe die verlinkenden Webseiten Nutzer zu Webseiten mit Produkt- oder
sonstigen Angeboten verweisen, protokolliert. Die Betreiber der jeweils
verlinkenden Webseiten können eine Provision erhalten, wenn Nutzer diesen
sogenannten <span >Affiliate</span>-Links folgen und anschließend
die Angebote wahrnehmen (z.B. Waren kaufen oder Dienstleistungen in Anspruch
nehmen). Hierzu ist es erforderlich, dass die Anbieter nachverfolgen können, ob
Nutzer, die sich für bestimmte Angebote interessieren, diese anschließend auf
die Veranlassung der <span >Affiliate</span>-Links wahrnehmen.
Daher ist es für die Funktionsfähigkeit von <span >Affiliate</span>-Links
erforderlich, dass sie um bestimmte Werte ergänzt werden, die ein Bestandteil
des Links werden oder anderweitig, z.B. in einem Cookie, gespeichert werden. Zu
den Werten gehören insbesondere die Ausgangswebseite (<span >Referrer</span>),
der Zeitpunkt, eine Online-Kennung der Betreiber der Webseite, auf der sich der
<span >Affiliate</span>-Link befand, eine Online-Kennung des
jeweiligen Angebotes, eine Online-Kennung des Nutzers als auch
nachverfolgungsspezifische Werte, wie, z.B. Werbemittel-ID, Partner-ID und
Kategorisierungen.</p>

<p  >&nbsp;</p>

<p  >Besuchsaktionsauswertung: „Besuchsaktionsauswertung“
(englisch „<span >Conversion</span> Tracking“) bezeichnet ein
Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden
kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer innerhalb
der Webseiten, auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann
erneut auf der Zielwebseite abgerufen. Beispielsweise können wir so
nachvollziehen, ob die von uns auf anderen Webseiten geschalteten Anzeigen
erfolgreich waren).</p>

<p  >&nbsp;</p>

<p  >Content <span >Delivery</span> Network (CDN):
Ein „Content <span >Delivery</span> Network“ (CDN) ist ein Dienst,
mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
Mediendateien, wie Grafiken oder Programm-Skripte mit Hilfe regional verteilter
und über das Internet verbundener Server, schneller und sicherer ausgeliefert
werden können.</p>

<p  >&nbsp;</p>

<p  >Cross-Device Tracking: Das Cross-Device Tracking ist eine
Form des Trackings, bei der Verhaltens- und Interessensinformationen der Nutzer
geräteübergreifend in sogenannten Profilen erfasst werden, indem den Nutzern
eine Onlinekennung zugeordnet wird. Hierdurch können die Nutzerinformationen
unabhängig von verwendeten Browsern oder Geräten (z.B. Mobiltelefonen oder
Desktopcomputern) im Regelfall für Marketingzwecke analysiert werden. Die
Onlinekennung ist bei den meisten Anbietern nicht mit Klardaten, wie Namen,
Postadressen oder E-Mail-Adressen, verknüpft.</p>

<p  >&nbsp;</p>

<p  >IP-<span >Masking</span>: Als „IP-<span
>Masking</span>” wird eine Methode bezeichnet, bei der das letzte
Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse, gelöscht wird, damit
die IP-Adresse nicht mehr der eindeutigen Identifizierung einer Person dienen
kann. Daher ist das IP-<span >Masking</span> ein Mittel zur
Pseudonymisierung von Verarbeitungsverfahren, insbesondere im Onlinemarketing.</p>

<p  >&nbsp;</p>

<p  >Interessenbasiertes und verhaltensbezogenes Marketing: Von
interessens- und/oder verhaltensbezogenem Marketing spricht man, wenn <span
 >potentielle</span> Interessen von Nutzern an Anzeigen und sonstigen
Inhalten möglichst genau vorbestimmt werden. Dies geschieht anhand von Angaben
zu deren Vorverhalten (z.B. Aufsuchen von bestimmten Webseiten und Verweilen
auf diesen, Kaufverhaltens oder Interaktion mit anderen Nutzern), die in einem
sogenannten Profil gespeichert werden. Zu diesen Zwecken werden im Regelfall
Cookies eingesetzt.</p>

<p  >&nbsp;</p>

<p  >Konversionsmessung: Die Konversionsmessung ist ein
Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden
kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer innerhalb
der Webseiten, auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann
erneut auf der Zielwebseite abgerufen. Beispielsweise können wir so
nachvollziehen, ob die von uns auf anderen Webseiten geschalteten Anzeigen
erfolgreich waren.</p>

<p  >&nbsp;</p>

<p  >Personenbezogene Daten: „Personenbezogene Daten“ sind alle
Informationen, die sich auf eine identifizierte oder identifizierbare
natürliche Person (im Folgenden „betroffene Person“) beziehen; als
identifizierbar wird eine natürliche Person angesehen, die direkt oder
indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu
einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder
zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die
Ausdruck der physischen, physiologischen, genetischen, psychischen,
wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person
sind.</p>

<p  ><span>&nbsp;</span></p>

<p  ><span >Profiling</span>: Als „<span
>Profiling</span>“ wird jede Art der automatisierten Verarbeitung
personenbezogener Daten bezeichnet, die darin besteht, dass diese
personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die
sich auf eine natürliche Person beziehen (je nach Art des <span >Profilings</span>
gehören dazu Informationen betreffend das Alter, das Geschlecht, Standortdaten
und Bewegungsdaten, Interaktion mit Webseiten und deren Inhalten,
Einkaufsverhalten, soziale Interaktionen mit anderen Menschen) zu analysieren,
zu bewerten oder, um sie vorherzusagen (z.B. die Interessen an bestimmten
Inhalten oder Produkten, das Klickverhalten auf einer Webseite oder den
Aufenthaltsort). Zu Zwecken des <span >Profilings</span> werden
häufig Cookies und Web-Beacons eingesetzt.</p>

<p  >&nbsp;</p>

<p  >Reichweitenmessung: Die Reichweitenmessung (auch als Web
Analytics bezeichnet) dient der Auswertung der Besucherströme eines
Onlineangebotes und kann das Verhalten oder Interessen der Besucher an
bestimmten Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit Hilfe
der Reichweitenanalyse können Webseiteninhaber z.B. erkennen, zu welcher Zeit
Besucher ihre Webseite besuchen und für welche Inhalte sie sich interessieren.
Dadurch können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse ihrer
Besucher anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme
Cookies und Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen und
so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.</p>

<p  >&nbsp;</p>

<p  ><span >Remarketing</span>: Vom „<span
>Remarketing</span>“ bzw. „<span >Retargeting</span>“
spricht man, wenn z.B. zu Werbezwecken vermerkt wird, für welche Produkte sich
ein Nutzer auf einer Webseite interessiert hat, um den Nutzer auf anderen
Webseiten an diese Produkte, z.B. in Werbeanzeigen, zu erinnern.</p>

<p  >&nbsp;</p>

<p  >Tracking: Vom „Tracking“ spricht man, wenn das Verhalten von
Nutzern über mehrere Onlineangebote hinweg nachvollzogen werden kann. Im
Regelfall werden im Hinblick auf die genutzten Onlineangebote Verhaltens- und
Interessensinformationen in Cookies oder auf Servern der Anbieter der
Trackingtechnologien gespeichert (sogenanntes <span >Profiling</span>).
Diese Informationen können anschließend z.B. eingesetzt werden, um den Nutzern
Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.</p>

<p  >&nbsp;</p>

<p  >Verantwortlicher: Als „Verantwortlicher“ wird die natürliche
oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder
gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
personenbezogenen Daten entscheidet, bezeichnet.</p>

<p  >&nbsp;</p>

<p  >Verarbeitung: „Verarbeitung“ ist jeder mit oder ohne Hilfe
automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst
praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das
Speichern, das Übermitteln oder das Löschen.</p>

<p  >&nbsp;</p>

<p  >Zielgruppenbildung: Von Zielgruppenbildung (bzw. „Custom <span
>Audiences</span>“) spricht man, wenn Zielgruppen für Werbezwecke,
z.B. Einblendung von Werbeanzeigen, bestimmt werden. So kann z.B. anhand des
Interesses eines Nutzers an bestimmten Produkten oder Themen im Internet
geschlussfolgert werden, dass dieser Nutzer sich für Werbeanzeigen für ähnliche
Produkte oder den Onlineshop, in dem er die Produkte betrachtet hat,
interessiert. Von „Lookalike <span >Audiences</span>“ (bzw.
ähnlichen Zielgruppen) spricht man wiederum, wenn die als geeignet
eingeschätzten Inhalte Nutzern angezeigt werden, deren Profile bzw. Interessen
mutmaßlich den Nutzern, zu denen die Profile gebildet wurden, entsprechen. Zu
Zwecken der Bildung von Custom <span >Audiences</span> und
Lookalike <span >Audiences</span> werden im Regelfall Cookies und
Web-Beacons eingesetzt.</p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Datenschutz;