function Agb( { isMobile } ) {
    return (
        <div className="p-6">
            {isMobile ? (<div className="flex items-center justify-center p-4">
                <img
            src="https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogomobile.png"
            alt="Logo"
            className="h-auto"
            style={{ width: '40vw' }}
            />
            </div>) : (<div></div>)}
            <div className={`relative w-full h-full ${isMobile ? 'pr-1 pl-1 pt-4 pb-4'  : 'p-6' } bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                <div className="text-black font-roboto">
                    <p className="text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-roboto font-normal text-black leading-16 text-left pl-8 pr-8">
                    Allgemeine Nutzungs- und Geschäftsbedingungen<br /><br />
                    </p>

                    <div className="text-md lg:text-2xl xl:text-3xl 2xl:text-4xl font-roboto font-normal text-black leading-16 text-left pl-8 pr-8">
                    <p>Möchtest du ein Teil von Walu werden, musst du unsere Geschäftsbedingungen beachten.</p>
                    <p ><span >&nbsp;</span></p>
                  
                    <p  ><span
>1. Geltungsbereich</span></p>

<p  ><span
>Nachfolgende Allgemeine Nutzungs- und
Geschäftsbedingungen („AGB“) gelten für die Nutzung der mobilen App namens
„Walu“ („Betreiber“), die auf den Plattformen Android und iOS verfügbar ist,
durch Verbraucher („Nutzer“) und Unternehmer („Nutzer“).</span></p>

<p  ><span
>Verbraucher ist jede natürliche Person, die ein
Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen
noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.</span></p>

<p  ><span
>Unternehmer ist eine natürliche oder juristische
Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines
Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen
Tätigkeit handelt.</span></p>

<p  ><span
>Ohne erneuten Hinweis, gelten diese AGB gegenüber
Unternehmern auch für künftige Geschäftsbeziehungen. Entgegenstehenden oder
ergänzenden Allgemeinen Geschäftsbedingungen des Unternehmers, wird hiermit
widersprochen. Sie erlangen keine Geltung und werden nur bei ausdrücklicher
Zustimmung Vertragsbestandteil.</span></p>

<p  ><span
>2. Betreiber und Vertragspartner</span></p>

<p  ><span
>Betreiber und Vertragspartei mit den Anbietern ist die
Laatz und Zlenko GbR, Obergasse 29, 76877 Offenbach, Deutschland.</span></p>

<p  ><span
>3. Nutzungsberechtigung</span></p>

<p  ><span
>Nutzer der Plattform können sowohl Anbieter von
Dienstleistungen, als auch Personen sein, die eine bestimmte Dienstleistung
suchen.</span></p>

<p  ><span
>Jeder Nutzer kann ein individuelles Nutzerkonto
erstellen.</span></p>

<p  ><span
>Juristische Personen oder Personengesellschaften
dürfen Nutzerkonten ausschließlich durch vertretungsberechtigte Personen
eröffnen.</span></p>

<p  ><span
>Jeder Nutzer darf nur ein Nutzerkonto unterhalten.</span></p>

<p  ><span
>Der Betreiber behält sich die Löschung doppelter
oder ohne Vertretungsberechtigung eröffneter Nutzerkonten vor.</span></p>

<p  ><span
>4. Vertragsschluss und Registrierung</span></p>

<p  ><span
>Durch die Registrierung auf der Plattform kommt
zwischen Nutzer und Betreiber ein Nutzungsvertrag zustande.</span></p>

<p  ><span
>Die Registrierung erfolgt durch die Eingabe der für
die Registrierung jeweils notwendigen Daten in die Eingabemaske und die
anschließende Bestätigung des Nutzungsvertrages. Die Bestätigung steht unter
der auflösenden Bedingung der Ablehnung durch den Betreiber. Lehnt der
Betreiber den Abschluss des Nutzungsvertrages innerhalb von fünf Tagen ab, dann
kommt der Nutzungsvertrag nicht zustande. Der Abschluss des Nutzungsvertrages
wird dem Nutzer per E-Mail oder durch Freischaltung des Nutzerkontos bestätigt.</span></p>

<p  ><span
>5. Nutzungsverhältnisse</span></p>

<p  ><span
>Der Nutzer kann zwischen einem von drei
kostenpflichtigen Nutzungsverhältnissen und einem kostenfreiem
Nutzungsverhältnis wählen.</span></p>

<p  ><span
>Die kostenpflichtigen Nutzungsverhältnisse berechtigen
den Nutzer zum Anbieten von kostenfreien oder kostenpflichtigen
Dienstleistungen. Der Betreiber behält sich vor, den kostenpflichtigen
Nutzungsverhältnissen („Walu-Paketen“) zusätzliche Funktionen hinzuzufügen oder
diese auch zu entfernen.</span></p>

<p  ><span
>Das kostenfreie Nutzungsverhältnis berechtigt zum
durchsuchen der Plattform des Betreibers nach angebotenen Dienstleistungen.</span></p>

<p  ><span
>Alle Nutzungsverhältnisse berechtigen zur
Interaktion zwischen den Nutzern.</span></p>

<p  ><span
>6. Vertragssprache, Vertragstextspeicherung</span></p>

<p  ><span
>Die Vertragssprache ist Deutsch. Der Vertragstext
wird, sofern es für die Vertragsabwicklung notwendig ist, gespeichert.</span></p>

<p  ><span
>Ein weiterer Zugang zum Vertragstext (bspw. über das
Internet) ist aus Sicherheitsgründen nicht vorgesehen.</span></p>

<p  ><span
>7. Laufzeit, Kündigung, Kosten</span></p>

<p  ><span
>Die Walu-Mitgliedschaft beginnt unter Umständen mit
einem kostenlosen Probezeitraum, dessen Dauer während der Registrierung angegeben
wird.</span></p>

<p  ><span
>Der Betreiber kann die Berechtigung für einen
kostenlosen Probezeitraum nach eigenem Ermessen bewerten und zur Unterbindung
von Missbrauch gegebenenfalls die Verfügbarkeit oder Dauer des kostenlosen
Probezeitraums einschränken. Sollte der Betreiber feststellen, dass keine
Berechtigung für den kostenlosen Probezeitraum vorliegt, behält sich der Betreiber
das Recht vor, diesen zu widerrufen und das erstellte Konto stillzulegen.<br />
Sofern die Mitgliedschaft nicht vor Ablauf des kostenlosen Probezeitraums
gekündigt wird, wird der Betreiber die angegebene Zahlungsart am Ende des
kostenlosen Probezeitraums mit der Mitgliedsgebühr für den nächsten
Abrechnungszeitraum belasten.</span></p>

<p  ><span
>Der Nutzungsvertrag wird auf unbestimmte Zeit
geschlossen und läuft automatisch bis zur Kündigung.</span></p>

<p  ><span
>Solange der Nutzer seine Mitgliedschaft nicht vor
dem Rechnungsdatum kündigt, erklärt er sich damit einverstanden, dass die
Mitgliedsgebühr für den jeweils darauffolgenden Abrechnungszeitraum von der
Zahlungsart abgebucht werden darf.</span></p>

<p  ><span
>Walu Mitglieder haben die Möglichkeit, jederzeit zu
kündigen. Für Nutzer besteht bis zum Ende des Abrechnungszeitraums weiterhin
die Möglichkeit, Ihr Nutzerkonto, mit denen im gekündigten Abonnement
enthaltenen Tools, zu verwenden.</span></p>

<p  ><span
>Davon unberührt bleibt das Recht auf
außerordentliche Kündigung aus wichtigem Grund.</span></p>

<p  ><span
>Ist das Nutzungsverhältnis gekündigt, wird das
Nutzerkonto zum Ende des Nutzungsverhältnisses eingeschränkt. Der Nutzer
verliert den Zugang zu allen kostenpflichtigen Inhalten des vorherigen Abonnements.</span></p>

<p  ><span
>Für die kostenpflichtigen Nutzungsverhältnisse ist, je
nach Paket, monatlich ein Betrag von 9,99 Euro („Basic-Paket“), 24,99 Euro
(„Unlimited-Paket“) oder 99,99 Euro („Business-Paket“) inklusive Umsatzsteuer
zur Zahlung fällig. Dieser Betrag wird monatlich von der vom Nutzer angegebenen
Zahlungsart abgebucht.</span></p>

<p  ><span
>Nutzer stellen uns frei, jegliche mit Ihrem Konto
verbundene Zahlungsart zu belasten, falls Ihre primäre Zahlungsart abgelehnt
wird oder uns nicht länger zur Bezahlung Ihrer Mitgliedschaftsgebühr zur
Verfügung steht. Für offene Beträge bleibt der Nutzer verantwortlich. Sollte
eine Zahlung nicht erfolgreich abgewickelt werden können, weil die jeweilige
Zahlungsart abgelaufen ist, nicht ausreichend Guthaben aufweist oder aus einem
anderen Grund scheitert, und der Nutzer sein Konto nicht kündigt, kann der
Zugang zu unserem Dienst solange gesperrt werden, bis wir eine gültige
Zahlungsart erfolgreich belastet haben. Bei gewissen Zahlungsarten kann der
Aussteller der Zahlungsart eine bestimmte Gebühr, wie zum Beispiel eine
Auslandstransaktionsgebühr oder andere Gebühren für die Zahlungsabwicklung,
verlangen. Die vor Ort anfallenden Steuern können je nach Zahlungsart
variieren.</span></p>

<p  ><span
>Ihnen stehen möglicherweise je nach System (Android
oder iOS) folgende Zahlungsarten zur Verfügung:</span></p>

<p  ><span
>Kreditkarte</span></p>

<p  ><span
>Mit Abgabe der Bestellung geben Sie Ihre
Kreditkartendaten an. Nach Ihrer Legitimation als rechtmäßiger Karteninhaber
wird die Zahlungstransaktion automatisch durchgeführt und Ihre Karte belastet.</span></p>

<p  ><span
>PayPal</span></p>

<p  ><span
>Während der Bestellung werden Sie auf die
Online-Präsenz des Zahlungsanbieters PayPal weitergeleitet. Dort werden ihre
Zahlungsdaten angegeben und PayPal wird die Anweisung zur Durchführung der
Zahlung erteilt. Voraussetzung zur Nutzung ist, dass Sie bei PayPal registriert
sind oder sich erstmalig registrieren. Sobald die Bestellung verbindlich in
unserem Shop abgeschlossen wurde, fordern wir PayPal zur Durchführung der
Zahlung auf.</span></p>

<p  ><span
>Kreditkarte über PayPal</span></p>

<p  ><span
>Für die Zahlungsart „Kreditkarte via PayPal“, müssen
Sie nicht bei PayPal registriert sein. Nach Bestätigung der Zahlungsanweisung
und Prüfung Ihrer Legitimation als Kreditkarteninhaber, wird die Zahlung von
Ihrem Kreditkartenunternehmen auf Aufforderung von PayPal durchgeführt.</span></p>

<p  ><span
>Lastschrift via PayPal</span></p>

<p  ><span
>Für die Zahlungsart „Lastschrift via PayPal“, müssen
Sie nicht bei PayPal registriert sein. Mit der Anweisung zur Zahlung des
Rechnungsbetrages, erteilen Sie PayPal ein Lastschriftmandat. PayPal wird Sie
über das Datum (Prenotification) der Kontobelastung separat informieren. Nach
Bestätigung der Zahlungsanweisung, fordert PayPal seine Bank zur Durchführung
der Zahlung und Belastung Ihres Kontos auf.</span></p>

<p  ><span
>8. Widerrufsrecht</span></p>

<p  ><span
>Verbrauchern steht das gesetzliche Widerrufsrecht,
wie in der Widerrufsbelehrung beschrieben, zu.</span></p>

<p  ><span
>Unternehmern wird kein freiwilliges Widerrufsrecht
eingeräumt.</span></p>

<p  ><span
>9. Verträge zwischen Nutzern</span></p>

<p  ><span
>Der Betreiber fungiert ausschließlich als Vermittler
zwischen den Nutzern.</span></p>

<p  ><span
>Kommt zwischen den Nutzern ein entgeltliches oder
unentgeltliches Vertragsverhältnis zustande, ist der Betreiber hieran weder
beteiligt noch berechtigt oder verpflichtet. Die Abwicklung des
Vertragsverhältnisses findet ausschließlich im Verhältnis der Nutzer
untereinander statt. Der Betreiber erhält mit Ausnahme der monatlichen Gebühren
keine Provisionen, Kick-Backs oder sonstige Leistungen der Nutzer.</span></p>

<p  ><span
>Eine Haftung wird diesbezüglich ausgeschlossen.</span></p>

<p  ><span
>10. Leistung des Betreibers, Leistungsänderungen</span></p>

<p  ><span
>Der Betreiber stellt eine Plattform zur Verfügung,
auf der die Nutzer mittels der bereitgestellten Software unterschiedliche
Nutzerprofile anlegen können.</span></p>

<p  ><span
>Der Betreiber stellt darüber hinaus die technische
Plattform zur Interaktion zwischen den Nutzern zur Verfügung.</span></p>

<p  ><span
>Ferner können die Nutzer in Abhängigkeit ihres
Nutzerzuganges beispielsweise Beiträge (Fotos, Videos) hochladen, Kurse (kostenpflichtig)
erstellen und veröffentlichen, Kursmanager ernennen, Kursanzeigen anfragen, Likes
sammeln oder erteilen, Kommentare erstellen und Beiträge liken.</span></p>

<p  ><span
>Der Betreiber haftet nicht für die dauerhafte
Verfügbarkeit seiner Internetpräsenz und der Angebote des Nutzers. Dies gilt
insbesondere dann, wenn die Verfügbarkeit durch einen nicht im Einflussbereich
des Betreibers liegenden Grund gestört ist. Beispielhaft sind dies höhere
Gewalt, Stromausfall, Serverausfall etc.</span></p>

<p  ><span
>Ausfälle des Portals bedingt durch Wartungsarbeiten
an der Internetpräsenz, den genutzten Servern (Drittservern oder Servern der
Webspacebetreiber) oder vergleichbaren Sachverhalten, berechtigen nicht zur
Geltendmachung von Schadensersatzansprüchen.</span></p>

<p  ><span
>Der Betreiber behält sich unter Berücksichtigung der
Interessen der Nutzer vor, die Leistungen seines Portals zu erweitern,
einzuschränken oder ganz oder teilweise zu beenden. Dies gilt insbesondere auch
dann, wenn der Betreiber aufgrund Gesetz, einer rechtskräftigen Entscheidung
oder zur Erhaltung der Sicherheit der Plattform zu einer Änderung verpflichtet
ist.</span></p>

<p  ><span
>Änderungen, die rein technischer Natur sind oder
lediglich vorteilhaft für den Nutzer sind oder nur unwesentlichem Einfluss
haben, sind keine Leistungsänderungen.</span></p>

<p  ><span
>11. Rechte des Betreibers</span></p>

<p  ><span
>Der Betreiber ist berechtigt die Nutzung des Portals
ganz oder teilweise einzuschränken oder Inhalte ganz oder teilweise zu löschen,
sofern der dringende Verdacht der unrechtmäßigen Nutzung oder eines Verstoßes
gegen diese AGB besteht. Der Nutzer bleibt zur Zahlung der vereinbarten
Gebühren verpflichtet. Die Sperrung bleibt aufrechterhalten, bis das
Fehlverhalten abgestellt ist oder festgestellt wird, dass tatsächlich kein
Fehlverhalten vorlag.</span></p>

<p  ><span
>Der Betreiber behält sich vor die monatlichen
Gebühren angemessen zu erhöhen. Dies gilt insbesondere für den Fall der
Erhöhung der gesetzlichen Umsatzsteuer oder von anderen Steuern und Abgaben oder
bei einer Erhöhung der Betriebs- und Unterhaltskosten bzw. der Erweiterung oder
Optimierung des Leistungsinhaltes.</span></p>

<p  ><span
>12. Pflichten des Nutzers</span></p>

<p  ><span
>Der Nutzer verpflichtet sich, sein Nutzerprofil und
alle seine Angebote, Bilder, Galerien etc. entsprechend der geltenden Gesetze
und Rechtsprechung zu gestalten. Ferner Sind Rechte Dritter zu wahren. Dies
gilt insbesondere für die Nutzung von Bildern, Dateien oder die Verlinkung auf
andere Webseiten. Der Nutzer trägt die Verantwortung für die Veröffentlichung
von Bildern und Dateien und die Verbreitung von Links und alle unter seinem
Nutzerprofil entfalteten Aktivitäten.</span></p>

<p  ><span
>Der Nutzer ist zur Geheimhaltung der Zugangsdaten
verpflichtet und nicht zur Weitergabe berechtigt. Sofern Anhaltspunkte der
missbräuchlichen Nutzung bestehen, ist der Nutzer zur umgehenden Meldung an den
Betreiber verpflichtet.</span></p>

<p  ><span
>Der Nutzer ist darüber hinaus dafür verantwortlich,
dass in der Veröffentlichung ein den gesetzlichen Voraussetzungen
entsprechendes vollständiges Impressum dargestellt ist. Ferner ist der Nutzer
dafür verantwortlich, dass er seinen Informationspflichten gegenüber
Verbrauchern nachkommt, sofern er hierzu gesetzlich verpflichtet ist.</span></p>

<p  ><span
>Der Nutzer hat zu jedem Zeitpunkt die Verantwortung,
die ihm zur Kenntnis gelangten Daten anderer Nutzer entsprechend der geltenden
Datenschutzgesetze zu schützen. Der Nutzer garantiert, dass Daten anderer Nutzer
nur entsprechend der geltenden Datenschutzgesetze erhoben, gespeichert und
verarbeitet werden. Der Nutzer sorgt für eine den Datenschutzgesetzen
entsprechende Löschung oder Vernichtung der erhobenen, gespeicherten oder
verarbeiteten Daten.</span></p>

<p  ><span
>Verboten ist die Veröffentlichung urheberrechtlich
geschützter Inhalte, wenn keine Berechtigung zur Nutzung vorliegt, falsche
Tatsachenbehauptungen, von Beiträgen, die darauf abzielen, andere Nutzer
persönlich anzugreifen oder zu diffamieren, rassistische, fremdenfeindliche,
diskriminierende oder beleidigende Inhalte, Jugend gefährdende Inhalte oder
Gewalt verherrlichende Inhalte, von Links auf Seiten, die nicht den
gesetzlichen Bestimmungen oder diesen AGB entsprechen.</span></p>

<p  ><span
>&nbsp;</span></p>

<p  ><span
>Sofern der Betreiber aufgrund der vom Nutzer
veröffentlichten Inhalte durch Dritte wegen eines Verstoßes oder einer
schädigenden Handlung oder einem Verstoß gegen die Nutzerpflichten in Anspruch
genommen wird, stellt der Nutzer den Betreiber von allen Kosten und Forderungen
frei. Dies gilt insbesondere auch für die Kosten einer zweckentsprechenden
Rechtsverfolgung.</span></p>

<p  ><span
>13. Haftung</span></p>

<p  ><span
>Für Ansprüche aufgrund von Schäden, die durch uns,
unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden, haften
wir stets unbeschränkt bei Verletzung des Lebens, des Körpers oder der
Gesundheit, bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung, bei
Garantieversprechen, soweit vereinbart, oder soweit der Anwendungsbereich des
Produkthaftungsgesetzes eröffnet ist.</span></p>

<p  ><span
>Bei Verletzung wesentlicher Vertragspflichten, deren
Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst
ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen
darf, (Kardinalpflichten) durch leichte Fahrlässigkeit von uns, unseren
gesetzlichen Vertretern oder Erfüllungsgehilfen ist die Haftung der Höhe nach
auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen
Entstehung typischerweise gerechnet werden muss.</span></p>

<p  ><span
>Im Übrigen sind Ansprüche auf Schadensersatz
ausgeschlossen.</span></p>

<p  ><span
>14. Änderung der AGB</span></p>

<p  ><span
>Der Betreiber behält sich vor, diese AGB jederzeit
ohne Angabe von Gründen zu ändern, es sei denn, dies ist für den Nutzer nicht
zumutbar.</span></p>

<p  ><span
>Der Betreiber wird den Nutzer über Änderungen der
AGB rechtzeitig in Textform benachrichtigen.</span></p>

<p  ><span
>Widerspricht der Nutzer der Geltung der neuen AGB
nicht innerhalb einer Frist von vier Wochen nach der Benachrichtigung, gelten
die geänderten AGB als vom Nutzer angenommen.</span></p>

<p  ><span
>Der Betreiber wird den Nutzer in der
Benachrichtigung auf sein Widerspruchsrecht und die Bedeutung der
Widerspruchsfrist hinweisen. Widerspricht der Nutzer den Änderungen innerhalb
der vorgenannten Frist, so besteht das Vertragsverhältnis zu den ursprünglichen
Bedingungen fort oder kann vom Betreiber aufgelöst werden.</span></p>

<p  ><span
>15. Rechtsnachfolge</span></p>

<p  ><span
>Der Betreiber ist berechtigt, seine Rechte und
Pflichten aus dem Nutzungsverhältnis ganz oder teilweise auf einen Dritten zu
übertragen. Der Betreiber wird den Nutzer hierüber rechtzeitig, spätestens
jedoch mit einer Frist von vier Wochen vor der Vertragsübernahme in Textform
benachrichtigen. Sofern der Nutzer das Vertragsverhältnis nicht mit dem Dritten
fortsetzen möchte, kann er den Vertrag innerhalb von drei Wochen nach Zugang
der Mitteilung des Betreibers mit einer Frist von sieben Tagen kündigen.</span></p>

<p  ><span
>16. Streitbeilegung</span></p>

<p  ><span
>Die Europäische Kommission stellt eine Plattform zur
Online-Streitbeilegung (OS) bereit, die Sie hier finden
https://ec.europa.eu/consumers/odr/.</span></p>

<p  ><span
>Zur Teilnahme an einem Streitbeilegungsverfahren vor
einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht
bereit.</span></p>

<p  ><span
>17. Schlussbestimmungen</span></p>

<p  ><span
>Sind Sie Unternehmer, dann gilt deutsches Recht
unter Ausschluss des UN-Kaufrechts.</span></p>

<p  ><span
>Sind Sie Kaufmann im Sinne des Handelsgesetzbuches,
juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
Sondervermögen, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus
Vertragsverhältnissen zwischen uns und Ihnen unser Geschäftssitz.</span></p>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default Agb;