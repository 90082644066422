function LernenPräsi() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was wirst du in der Online-Präsentation lernen?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/webinar2.jpg"
                    alt="Online-Präsentation"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    <b>Inhalte</b> der Online-Präsentation sind:<br/><br/>

                    <ul class="list-decimal pl-8">
<li>Welche vier Prinzipien helfen mir, <b>mehr Leute mit meinen Kursen</b> zu <b>erreichen</b>? 🌐</li><br/>
<li>Wie kann ich <b>mehr Geld für meinen Kurs verlangen</b>, ohne, dass die Anzahl an Kursbesuchern abnimmt? 💰</li><br/>
<li>Wie bringe ich die jetzige <b>Qualität</b> meines Kurses noch <b>besser zum Vorschein</b>? 🌟</li><br/>
<li>Was ist die <b>Walu App</b> und wie kann diese mir helfen, meine <b>Ziele zu erreichen</b>? 🚀</li><br/>
</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LernenPräsi;