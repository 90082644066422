function WasSprinterPaket() {
    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Was ist im Walu Sprinter Paket enthalten?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/bonus1.jpg"
                    alt="Bonusse"
                    class="object-cover w-full h-[35rem] rounded-lg"
                />
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    Das <b>Sprinter Paket</b> besteht aus:<br/><br/>

<ul class="list-decimal pl-8">
<li>Einer Checkliste, die dir hilft, den <b>besten Preis</b> für deinen Kurs festzusetzen. 📋</li><br/>
<li>Einem Tutorial, das dir sagt, wie du die <b>Qualität</b> deines Kurses mehr in die Öffentlichkeit bringst. 🌐</li><br/>
<li>Einem Guide, wie du deinen Kurs <b>besser bewirbst</b>. 📣</li><br/>
<li>Einem Leitfaden, der dir dabei hilft, die <b>Mundpropaganda</b> deines Kurses anzukurbeln. 🗣️</li><br/>
</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WasSprinterPaket;