import '../App.css';
import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function TopNavBarMobile() {
    const location = useLocation();

    const isSelected = (path) => location.pathname === path;

    const [showPanel, setShowPanel] = useState(false);

    const togglePanel = () => {
      setShowPanel(!showPanel);
    };

    const closePanel = () => {
      setShowPanel(false);
    };

    return (
      <div className="pt-5 pb-5 pl-1 pr-1 flex justify-center">
        <FontAwesomeIcon icon={faBars} className={`mr-auto text-gray-700 text-2xl pt-2 pl-2 pr-2`} onClick={togglePanel} />

        {showPanel && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-50" onClick={closePanel}></div>
      )}

      <div
        className={`fixed inset-y-0 left-0 bg-white w-64 shadow-lg overflow-y-auto ${
          showPanel ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 transition-transform duration-300 ease-in-out z-50`}
      >
        <ul className="p-4">
          <li className="py-4">
          <Link onClick={closePanel} to="/"><span className="text-xl">Home</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/search"><span className="text-xl">Kurs suchen</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/presentation"><span className="text-xl">Online-Präsentation</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/contact"><span className="text-xl">Kontakt</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/signup"><span className="text-xl">Anbieter werden</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/pakete"><span className="text-xl">Walu Pakete</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/bonus"><span className="text-xl">Bonusse Allgemein</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/bonus1"><span className="text-xl">Sprinter Paket</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/bonus2"><span className="text-xl">Geld-zurück-Garantie</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/bonus3"><span className="text-xl">Plus Pakete</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/deleteaccount"><span className="text-xl">Walu-Account löschen</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/about"><span className="text-xl">Über uns</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/agb"><span className="text-xl">AGB</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/imprint"><span className="text-xl">Impressum</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/privacy"><span className="text-xl">Datenschutz</span></Link><br />
          </li>
          <li className="py-4">
          <Link onClick={closePanel} to="/nutzungsvertrag"><span className="text-xl">Nutzungsvertrag</span></Link><br />
          </li>
        </ul>
      </div>

        <div className="mr-auto flex items-center space-x-3 overflow-x-auto no-scrollbar" style={{ scrollbarWidth: 'none' }}>
          <Link
            to="/presentation"
          >
            <div
             className={`border-2 font-medium text-md leading-5 font-roboto px-3 py-1 rounded-lg ${isSelected('/presentation') ? 'font-bold border-blue-500 text-blue-500' : 'border-gray-400 text-gray-700'}`}
             style={{ lineHeight: '1.5rem', whiteSpace: 'nowrap' }}
            >Präsentation</div>
          </Link>
          <Link
            to="/signup"
      
          >
            <div
            className={`bg-blue-500 text-white text-md leading-5 font-roboto px-3 py-1.5 rounded-lg ${isSelected('/signup') ? 'font-bold' : 'font-medium'}`}
            style={{ lineHeight: '1.5rem', whiteSpace: 'nowrap' }}
            >
            Anbieter werden
            </div>
          </Link>

        </div>
      </div>
    );
}


export default TopNavBarMobile;