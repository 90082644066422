import './App.css';
import React, { useState, useEffect } from 'react'
import {BrowserRouter, Route, Routes, Link} from "react-router-dom";
import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons/youtube'
import 'react-social-icons/x'
import 'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/linkedin'
import TopNavBar from './components/TopNavBar'
import TopNavBarMobile from './components/TopNavBarMobile'
import Home from './components/Home'
import HomeMobile from './components/HomeMobile'
import KursSuchen from './components/KursSuchen'
import Webinar from './components/Webinar'
import Kontakt from './components/Kontakt'
import Datenschutz from './components/Datenschutz'
import Agb from './components/Agb'
import Impressum from './components/Impressum';
import About from './components/About';
import NewPassword from './components/NewPassword';
import Share from './components/Share';
import ShareMobile from './components/ShareMobile';
import ScrollToTop from './components/ScrollToTop';
import KursSuchenMobile from './components/KursSuchenMobile';
import KontaktMobile from './components/KontaktMobile';
import NewPasswordMobile from './components/NewPasswordMobile';
import Formular from './components/Formular';
import WebinarMobile from './components/WebinarMobile';
import FormularMobile from './components/FormularMobile';
import OrderAdmin from './components/OrderAdmin';
import Bonus from './components/Bonus';
import Bonus1 from './components/Bonus1';
import BonusMobile from './components/BonusMobile';
import Bonus1Mobile from './components/Bonus1Mobile';
import WebinarAdmin from './components/WebinarAdmin';
import Pakete from './components/Pakete';
import Bonus4 from './components/Bonus4';
import Bonus3 from './components/Bonus3';
import PaketeMobile from './components/PaketeMobile';
import Bonus3Mobile from './components/Bonus3Mobile';
import Bonus4Mobile from './components/Bonus4Mobile';
import Nutzungsvertrag from './components/Nutzungsvertrag';
import DeleteAccount from './components/DeleteAccount';
import DeleteAccountMobile from './components/DeleteAccountMobile';

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  const backgroundImageUrl = 'https://walu-media.s3.eu-central-1.amazonaws.com/public/websitebg.png';
  const logoImageUrl = 'https://walu-media.s3.eu-central-1.amazonaws.com/public/websitelogo.png';

  return (
    <BrowserRouter>
    <ScrollToTop />
      <div className="bg-no-repeat bg-center bg-top flex flex-col min-h-screen"
      style={!isMobile ? { 
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: '100% auto',
      } : {}}>
        <div className="App">
          {isMobile ? (
            <TopNavBarMobile />
          ) : (
            <div>
              <TopNavBar />
              <Link to="/">
              <img
                src={logoImageUrl}
                alt="Logo"
                className="h-auto"
                style={{ 
                  maxWidth: '30vw',
                  marginLeft: '-4vw',
                  paddingLeft: '8%',
                }}
              />  
              </Link>
            </div>
          )}
          
          <div className="main-content flex-grow">
            <Routes>
              <Route path="/" element={isMobile ? (<HomeMobile></HomeMobile>) : (<Home></Home>)} />
              <Route path="/search" element={isMobile ? (<KursSuchenMobile></KursSuchenMobile>) : (<KursSuchen></KursSuchen>)} />
              <Route path="/presentation" element={isMobile ? (<WebinarMobile></WebinarMobile>) : (<Webinar></Webinar>)} />
              <Route path="/contact" element={isMobile ? (<KontaktMobile></KontaktMobile>) : (<Kontakt></Kontakt>)} />
              <Route path="/signup" element={isMobile ? (<FormularMobile></FormularMobile>) : (<Formular></Formular>)} />
              <Route path="/deleteaccount" element={isMobile ? (<DeleteAccountMobile></DeleteAccountMobile>) : (<DeleteAccount></DeleteAccount>)} />
              <Route path="/privacy" element={<Datenschutz isMobile={isMobile}></Datenschutz>} />
              <Route path="/agb" element={<Agb isMobile={isMobile}></Agb>} />
              <Route path="/imprint" element={<Impressum isMobile={isMobile}></Impressum>} />
              <Route path="/about" element={<About isMobile={isMobile}></About>} />
              <Route path="/bonus" element={isMobile ? (<BonusMobile></BonusMobile>) : (<Bonus></Bonus>)} />
              <Route path="/bonus1" element={isMobile ? (<Bonus1Mobile></Bonus1Mobile>) : (<Bonus1></Bonus1>)} />
              <Route path="/bonus2" element={isMobile ? (<Bonus3Mobile></Bonus3Mobile>) : (<Bonus3></Bonus3>)} />
              <Route path="/bonus3" element={isMobile ? (<Bonus4Mobile></Bonus4Mobile>) : (<Bonus4></Bonus4>)} />
              <Route path="/newpassword/:token" element={isMobile ? (<NewPasswordMobile></NewPasswordMobile>) : (<NewPassword></NewPassword>)} />
              <Route path="/order/admin" element={isMobile ? (<ShareMobile></ShareMobile>) : (<OrderAdmin></OrderAdmin>)} />
              <Route path="/presentation/admin" element={isMobile ? (<ShareMobile></ShareMobile>) : (<WebinarAdmin></WebinarAdmin>)} />
              <Route path="/pakete" element={isMobile ? (<PaketeMobile></PaketeMobile>) : (<Pakete></Pakete>)} />
              <Route path="/nutzungsvertrag" element={<Nutzungsvertrag isMobile={isMobile}></Nutzungsvertrag>} />
              <Route path="/*" element={isMobile ? (<ShareMobile></ShareMobile>) : (<Share></Share>)} />
            </Routes>
          </div>
        </div>
      </div>

      <footer>
          {isMobile ? (
           <div>
           <div className="container mx-auto flex flex-wrap justify-center">
             <div className="text-center mb-4">
               <div className="grid grid-cols-3 gap-4">
                 <Link to="/about">
                  <div
                  className="text-1xl leading-5 text-gray-900 tracking-tight"
                  >Über uns</div>
                 </Link>
                 <Link to="/contact">
                  <div
                  className="text-1xl leading-5 text-gray-900 tracking-tight"
                  >Kontakt</div>
                 </Link>
                 <Link to="/search">
                  <div
                  className="text-1xl leading-5 text-gray-900 tracking-tight"
                  >Kurs suchen</div>
                 </Link>
                 <Link to="/agb">
                  <div
                  className="text-1xl leading-5 text-gray-900 tracking-tight"
                  >AGB</div>
                 </Link>
                 <Link to="/imprint">
                  <div
                  className="text-1xl leading-5 text-gray-900 tracking-tight"
                  >Impressum</div>
                 </Link>
                 <Link to="/privacy">
                  <div
                  className="text-1xl leading-5 text-gray-900 tracking-tight"
                  >Datenschutz</div>
                 </Link>
               </div>
             </div>
           </div>
           <div className="text-center mb-4 mt-4  mx-auto space-x-4">
            <SocialIcon url="https://youtube.com/@waluapp?si=PqW10-9Jw-dk8erC"/>
            <SocialIcon url="https://www.x.com/WaluApp "/>
            <SocialIcon url="https://www.facebook.com/TeamWalu"/>
            <SocialIcon url="https://www.instagram.com/waluapp/"/>
            <SocialIcon url="https://www.linkedin.com/company/walu/"/>
           </div>
         </div>
          ) : (
            <div>
              <div className="w-full grid grid-cols-5 gap-4 py-4 border-gray-400 border-t border-b">
                <div className="col-span-1">
                  <div className="text-center">
                    <span className="font-semibold">Walu</span><br />
                    <Link to="/about"><span>Über uns</span></Link><br />
                    <Link to="/presentation"><span>Online-Präsentation</span></Link><br />
                    <Link to="/pakete"><span>Walu Pakete</span></Link><br />
                    <Link to="/deleteaccount"><span>Walu-Account löschen</span></Link>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="text-center">
                    <span className="font-semibold">Nutzung</span><br />
                    <Link to="/signup"><span>Anbieter werden</span></Link><br />
                    <Link to="/search"><span>Kurs suchen</span></Link>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="text-center">
                    <span className="font-semibold">Bonusse</span><br />
                    <Link to="/bonus"><span>Allgemein</span></Link><br />
                    <Link to="/bonus1"><span>Sprinter Paket</span></Link><br />
                    <Link to="/bonus2"><span>Geld-zurück-Garantie</span></Link><br />
                    <Link to="/bonus3"><span>Plus Paket</span></Link><br />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="text-center">
                    <span className="font-semibold">Support</span><br />
                    <Link to="/contact"><span>Kontakt</span></Link><br />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="text-center">
                    <span className="font-semibold">Rechtliches</span><br />
                    <Link to="/agb"><span>AGB</span></Link><br />
                    <Link to="/privacy"><span>Datenschutz</span></Link><br />
                    <Link to="/imprint"><span>Impressum</span></Link><br />
                    <Link to="/nutzungsvertrag"><span>Nutzungsvertrag</span></Link>
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center py-4">
                <div className="flex items-center">
                  <div className="pl-2 pr-4">
                    <img
                    src="https://walu-media.s3.eu-central-1.amazonaws.com/public/walulogocopyright.png"
                    alt="Logo"
                    style={{ maxWidth: '8rem', height: 'auto' }}/>
                  </div>
                  <span className="text-sm ml-2">© 2023<br/> mywalu.com</span>
                </div>

                <div className="flex items-center mx-auto space-x-4">
                  <SocialIcon url="https://youtube.com/@waluapp?si=PqW10-9Jw-dk8erC"/>
                  <SocialIcon url="https://www.x.com/WaluApp "/>
                  <SocialIcon url="https://www.facebook.com/TeamWalu"/>
                  <SocialIcon url="https://www.instagram.com/waluapp/"/>
                  <SocialIcon url="https://www.linkedin.com/company/walu/"/>
                </div>
              </div>
            </div>
         
          )}
        </footer>
    </BrowserRouter>
  );
}

export default App;
