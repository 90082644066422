function WieKursanbieterOnPage() {
    const videoUrl1 = "https://www.youtube.com/embed/qeNTnR99wYg?si=WV9cRljzaDssko38"
    
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling animation
        });
      };

    return (
        <div>
            <div className="text-4xl pt-4 leading-9 font-roboto font-bold text-black pb-8 text-left px-24">
            Wie werde ich Walu Kursanbieter?
            </div>
            <div className="flex flex-grow items-center px-24 pb-8">
                <div className="w-1/2 aspect-w-16 aspect-h-8 pr-8">
                <iframe
                    className="w-full h-[35rem] rounded-lg border border-gray-500"
                    src={videoUrl1}
                    title="YouTube video player"
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                ></iframe>
                </div>

                <div class="w-1/2 text-2xl font-roboto text-black text-left pl-8 pt-8 pb-8">
                    <div className={`relative w-full h-full p-6 bg-white shadow-lg rounded-lg border border-gray-500`} style={{ wordBreak: 'break-word' }}>
                    
                    <ul class="list-decimal pl-8">
    <li>Scrolle nach oben oder rufe <button onClick={scrollToTop}><span className="text-blue-600">den Link</span></button> auf. 🌐</li><br/>
    <li>Fülle die "<b>Allgemeinen Informationen</b>" zu dir als Anbieter aus. 📝</li><br/>
    <li>Fülle aus, wie du <b>Walu später nutzen</b> wirst. (Paketkosten fallen erst ab dem 01.04.24 an) 💼</li><br/>
    <li>Bestätige, dass du <b>Walu-Anbieter</b> werden möchtest. (Es fallen einmalig 15€ Anmeldegebühren an) ✅</li><br/>
    </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WieKursanbieterOnPage;